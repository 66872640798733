import dialogSize from '@/lib/calendesk-js-library/components/dialogs/dialogSize'

export default class Dialog {
  public open: boolean;
  public type: number;
  public size: number;
  public displayFooter: boolean;
  public title: string | null;
  public justifyTitle: string;
  public data: Record<string, any>;

  constructor (open = false, type = 0, title: null | string = '', size = dialogSize.SMALL, displayFooter = false, justifyTitle = 'center', data: Record<string, any> = {}) {
    this.open = open
    this.type = type
    this.size = size
    this.displayFooter = displayFooter
    this.title = title
    this.justifyTitle = justifyTitle
    this.data = data
  }
}
