import store from "@/store";

export default function (value, currency = null, noCents = false) {
  if (isNaN(value)) {
    return "";
  }

  if (!currency) {
    currency = store.state.setup.configuration.currency;
  }

  const data = {
    style: "currency",
    currency,
  };

  if (noCents) {
    data.minimumFractionDigits = 0;
    data.maximumFractionDigits = 0;
  }

  if (value === 0) {
    return value.toLocaleString(store.state.setup.configuration.language, data);
  }

  value /= 100;
  return value.toLocaleString(store.state.setup.configuration.language, data);
}
