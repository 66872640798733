import { GetterTree } from 'vuex'
import { SetupState } from './types'
import { RootState } from '../../types'
import Configuration from '@/calendesk/models/DTO/Response/Configuration'
import AdminConfiguration from '@/calendesk/models/DTO/Response/AdminConfiguration'
import { Moment } from 'moment'
import TenantPlan from '@/calendesk/models/DTO/Response/TenantPlan'

export const getters: GetterTree<SetupState, RootState> = {
  getConfiguration (state): object {
    return state.configuration
  },
  getRegulations (state): string | null {
    return state.regulations
  },
  getPrivacyPolicy (state): string | null {
    return state.privacyPolicy
  },
  getAppConfiguration (state): Configuration | null {
    return state.appConfiguration
  },
  getAdminConfiguration (state): AdminConfiguration | null {
    return state.adminConfiguration
  },
  getTranslations (state): object | null {
    return state.translations
  },
  isPreloading (state): boolean {
    return state.preloader
  },
  getVersionCheckedAt (state): Moment | null {
    return state.versionCheckedAt
  },
  getCurrentTenantPlanSlug (state): string | null {
    return state.currentTenantPlanSlug
  },
  getCurrentTenantPlan (state): TenantPlan | null {
    return state.currentTenantPlan
  },
  getReferrer (state): string {
    return state.referrer
  },
  getTenant (state): string | null {
    return state.tenant
  }
}
