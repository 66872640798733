import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";

const getDefaultState = () => ({
  instance: {
    open: false,
    type: 0,
    size: dialogSize.SMALL,
    title: null,
    data: {},
    rounded: true,
    ctaButtonTitle: null,
    ctaButtonIcon: null,
    ctaButtonAction: null,
    ctaButtonColor: "secondary",
    showCloseDialogFooter: false,
    persistent: false,
  },
});

const state = getDefaultState();
const getters = {
  getInstance: (state) => state.instance,
};
const actions = {
  open({ commit }, dialog) {
    commit("SET_DIALOG", {
      open: true,
      size: dialogSize.SMALL,
      title: null,
      data: {},
      rounded: true,
      ctaButtonTitle: null,
      ctaButtonIcon: null,
      ctaButtonAction: null,
      ctaButtonColor: "secondary",
      showCloseDialogFooter: false,
      persistent: false,
      ...dialog,
    });
  },
  close({ commit }, data) {
    commit("SET_DIALOG", {
      open: false,
      type: 0,
      title: null,
      rounded: true,
      ctaButtonTitle: null,
      ctaButtonIcon: null,
      ctaButtonAction: null,
      ctaButtonColor: "secondary",
      showCloseDialogFooter: false,
      persistent: false,
      data,
    });
  },
};
const mutations = {
  SET_DIALOG(state, dialog) {
    state.instance = dialog;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
