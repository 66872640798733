




























import mixins from 'vue-typed-mixins'
import DraftActions from '@/builder/mixins/DraftActions'
import CColorPicker from '@/lib/calendesk-js-library/components/CColorPicker.vue'
import { DraftActionsType } from '@/calendesk/models/DraftActionsType'
import { DraftConfigurationType } from '@/calendesk/models/BuilderTypes'

export default mixins(DraftActions).extend({
  name: 'Style',
  components: {
    CColorPicker
  },
  data () {
    return {
      tools: [
        DraftConfigurationType.COLOR,
        DraftConfigurationType.CHECKBOX
      ],
      acceptedVariables: [
        'wb_color_primary__color__',
        'wb_color_bg__color__',
        'wb_color_text__color__',
        'wb_color_bg_2__color__',
        'wb_color_text_2__color__',
        'wb_dark_mode__checkbox__'
      ],
      mode: DraftActionsType.CONFIGURATION,
      draftConfigurationType: DraftConfigurationType
    }
  }
})
