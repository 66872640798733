export default {
  NONE: 0,
  LOGO: 1,
  GALLERY: 2,
  BACKGROUND_HOME_TOP: 3,
  BACKGROUND_EMPLOYEES: 4,
  BACKGROUND_PRIVACY_POLICY: 5,
  BACKGROUND_REGULATIONS: 6,
  BACKGROUND_SERVICES: 7,
  MOBILE_HOME_IMAGE: 8,
  MOBILE_SERVICES_IMAGE: 9,
  MOBILE_ABOUT_US_IMAGE: 10,
  BACKGROUND_NOT_FOUND: 11,
};
