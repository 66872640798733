import Snackbar from '@/calendesk/models/Snackbar'

export class RootState {
  isSending: boolean
  snackbar: Snackbar
  [x: string]: any;

  constructor (snackbar = new Snackbar(), isSending = false) {
    this.snackbar = snackbar
    this.isSending = isSending
  }
}
