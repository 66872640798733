/*
base imports
 */
import 'reflect-metadata'
import Vue from 'vue'
import App from './App.vue'
/*
lib imports
 */

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import VueGtm from 'vue-gtm'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import beforeGuard from './router/guards/beforeGuard'
import afterGuard from './router/guards/afterGuard'
import moment from 'moment-timezone'
import VueMoment from 'vue-moment'
import mixpanel from 'mixpanel-browser'

/*
calendesk styles
 */
import './styles/index.scss'

/*
prototypes
 */
import { config } from './lib/calendesk-js-library/prototypes/config'
import { trans } from './lib/calendesk-js-library/prototypes/trans'
import './lib/calendesk-js-library/prototypes/helpers'
import money from '@/lib/calendesk-js-library/filters/money'
import duration from '@/lib/calendesk-js-library/filters/duration'
import truncate from '@/lib/calendesk-js-library/filters/truncate'
import stripHtml from '@/lib/calendesk-js-library/filters/stripHtml'
import capitalize from '@/lib/calendesk-js-library/filters/capitalize'

// Version
window.calendesk = {
  version: process.env.VUE_APP_APP_VERSION,
  env: process.env.NODE_ENV
}

Vue.use(VueMoment, { moment })

if (process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    apiKey: '6cd5a854e70b45a2db2a55290278d970',
    plugins: [new BugsnagPluginVue()],
    appVersion: window.calendesk.version,
    releaseStage: process.env.NODE_ENV
  })

  Bugsnag.getPlugin('vue')?.installVueErrorHandler(Vue)

  Vue.use(VueGtm, {
    id: 'GTM-T698JSF', // or ['GTM-xxxxxx', 'GTM-yyyyyy'], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    trackOnNextTick: false // Whether or not call trackView in Vue.nextTick
  })

  mixpanel.init('679c51a1134e360424cdc41cdfc90f31', {
    debug: false
  })
}

Vue.filter('money', money)
Vue.filter('duration', duration)
Vue.filter('truncate', truncate)
Vue.filter('stripHtml', stripHtml)
Vue.filter('capitalize', capitalize)

Vue.config.productionTip = false

Vue.prototype.$config = config
Vue.prototype.$trans = trans

router.beforeEach(beforeGuard)
router.afterEach(afterGuard)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
