export default function (value) {
  if (value === 0) {
    return `${value} min`;
  }

  if (!value) {
    return value;
  }

  const days = Math.floor(value / 1440);
  const hours = Math.floor((value - days * 1440) / 60);
  const minutes = Math.round(value % 60);

  if (days > 0) {
    if (hours > 0 || minutes > 0) {
      if (minutes > 0) {
        return `${days} d ${hours} h ${minutes} min`;
      }

      return `${days} d ${hours} h`;
    }

    return `${days} d`;
  }
  if (hours > 0) {
    if (minutes > 0) {
      return `${hours} h ${minutes} min`;
    }

    return `${hours} h`;
  }
  return `${minutes} min`;
}
