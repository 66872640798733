import SubscriptionProductPrice from '@/calendesk/models/DTO/Response/SubscriptionProductPrice'
import Service from '@/calendesk/models/DTO/Response/Service'
import { Expose, Type } from 'class-transformer'

export default class Subscription {
  public id!: number

  @Expose({ name: 'subscription_id' })
  public subscriptionId!: string

  public name!: string

  @Expose({ name: 'success_url' })
  public successUrl!: string | null

  @Expose({ name: 'recurring_interval' })
  public recurringInterval!: string | null

  @Expose({ name: 'interval_count' })
  public intervalCount!: number | null

  @Expose({ name: 'service_limit' })
  public serviceLimit!: number | null

  @Expose({ name: 'wants_invoice' })
  public wantsInvoice!: boolean

  public tax!: string | null

  @Expose({ name: 'created_at' })
  public createdAt!: string

  @Expose({ name: 'updated_at' })
  public updatedAt!: string

  @Type(() => SubscriptionProductPrice)
  public prices!: Array<SubscriptionProductPrice> | null

  @Type(() => Service)
  public services!: Array<Service> | null
}
