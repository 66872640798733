import Permission from '@/calendesk/models/DTO/Response/Permission'
import { Type } from 'class-transformer'

export default class Role {
  public id!: number
  public name!: string

  @Type(() => Permission)
  public permissions!: Permission[]
}
