

































import Vue from 'vue'
import WebsiteElementModel from '@/calendesk/models/WebsiteElementModel'

export default Vue.extend({
  name: 'ElementCard',
  props: {
    element: {
      type: WebsiteElementModel
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selected_: false
    }
  },
  created () {
    this.selected_ = this.selected
  },
  methods: {
    clickHandle () {
      this.selected_ = !this.selected_

      this.$emit('click', this.selected_)
    },
    select () {
      this.selected_ = true
    },
    unselect () {
      this.selected_ = false
    }
  }
})
