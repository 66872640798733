import { GetterTree } from 'vuex'
import { RootState } from '../../types'
import { SimpleStoreState } from './types'
import SimpleStoreProduct from '@/calendesk/models/DTO/Response/SimpleStoreProduct'

export const getters: GetterTree<SimpleStoreState, RootState> = {
  getProducts (state): SimpleStoreProduct[] | null {
    return state.products
  }
}
