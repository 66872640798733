import { Expose } from 'class-transformer'

export default class DefaultImage {
  public id!: number
  public domain!: string

  @Expose({ name: 'draft_uuid' })
  public draftUuid!: string

  @Expose({ name: 'is_valid' })
  public isValid!: boolean
}
