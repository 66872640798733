const getDefaultState = () => ({
  commonDialogLoader: false,
});

const state = getDefaultState();
const getters = {
  getCommonDialogLoader: (state) => state.commonDialogLoader,
};
const actions = {
  setCommonDialogLoader({ commit }, state) {
    commit("SET_COMMON_DIALOG_LOADER", state);
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_COMMON_DIALOG_LOADER(state, value) {
    state.commonDialogLoader = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
