












import Vue from 'vue'
import { WebsiteElementType } from '@/calendesk/models/BuilderTypes'
import WebsiteElementModel from '@/calendesk/models/WebsiteElementModel'
import ElementCard from '@/views/components/creator/ElementCard.vue'

export default Vue.extend({
  name: 'SelectElements',
  props: {
    preselectedElements: {
      type: Array
    }
  },
  components: {
    ElementCard
  },
  data () {
    return {
      elements: {} as Record<string, WebsiteElementModel>,
      selectedElements: new Set<WebsiteElementModel>()
    }
  },
  created () {
    this.elements = this.getElements()

    this.$nextTick(() => {
      this.preselectElements()
    })
  },
  methods: {
    preselectElements () {
      const preselectedElements = this.preselectedElements as Array<WebsiteElementModel>

      preselectedElements.forEach((element: WebsiteElementModel) => {
        for (const key in this.elements) {
          if (this.elements[key].id === element.id) {
            const ref = this.$refs[key] as any

            if (ref) {
              this.selectedElements.add(this.elements[key])
              ref[0].select()
            }
          }
        }
      })
    },
    getElements () {
      return {
        LOGIN: new WebsiteElementModel(WebsiteElementType.LOGIN, require('@/assets/lock.png'), this.$trans('wb_website_element_1'), this.$trans('wb_website_element_tooltip_1')),
        BOOKING: new WebsiteElementModel(WebsiteElementType.BOOKING, require('@/assets/clock.png'), this.$trans('wb_website_element_2'), this.$trans('wb_website_element_tooltip_2')),
        SERVICES: new WebsiteElementModel(WebsiteElementType.SERVICES, require('@/assets/speaker.png'), this.$trans('wb_website_element_3'), this.$trans('wb_website_element_tooltip_3')),
        ABOUT_US: new WebsiteElementModel(WebsiteElementType.ABOUT_US, require('@/assets/heart.png'), this.$trans('wb_website_element_4'), this.$trans('wb_website_element_tooltip_4')),
        TEAM: new WebsiteElementModel(WebsiteElementType.TEAM, require('@/assets/sun.png'), this.$trans('wb_website_element_5'), this.$trans('wb_website_element_tooltip_5')),
        TERMS_PRIVACY_POLICY: new WebsiteElementModel(WebsiteElementType.TERMS_PRIVACY_POLICY, require('@/assets/attachment.png'), this.$trans('wb_website_element_6'), this.$trans('wb_website_element_tooltip_6')),
        PICTURE_GALLERY: new WebsiteElementModel(WebsiteElementType.PICTURE_GALLERY, require('@/assets/camera.png'), this.$trans('wb_website_element_7'), this.$trans('wb_website_element_tooltip_7')),
        NEWSLETTER: new WebsiteElementModel(WebsiteElementType.NEWSLETTER, require('@/assets/bell.png'), this.$trans('wb_website_element_8'), this.$trans('wb_website_element_tooltip_8'))
      }
    },
    clickHandle (element: WebsiteElementModel, checked: boolean) {
      if (checked) {
        this.selectedElements.add(element)
      } else {
        this.selectedElements.delete(element)
      }

      this.$emit('click', Array.from(this.selectedElements))
    }
  }
})
