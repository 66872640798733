import { modeTypes } from "@/lib/calendesk-js-library/components/assets/assetsTypes";
import selectType from "@/lib/calendesk-js-library/components/assets/selectType";

const getDefaultState = () => ({
  mode: modeTypes.NONE,
  selectType: selectType.NONE,
  selected: null,
  confirmSelected: false,
  imageEdit: null,
  reload: null,
  initialSearchText: "happy",
  previewAsset: {
    open: false,
    url: "",
  },
});

const state = getDefaultState();
const getters = {
  getMode(state) {
    return state.mode;
  },
  getSelectType(state) {
    return state.selectType;
  },
  getSelected(state) {
    return state.selected;
  },
  getImageEdit(state) {
    return state.imageEdit;
  },
  getReload(state) {
    return state.reload;
  },
  getConfirmSelected(state) {
    return state.confirmSelected;
  },
  getPreviewAsset(state) {
    return state.previewAsset;
  },
  getInitialTextSearch(state) {
    return state.initialSearchText;
  },
};
const actions = {
  setSelectType({ commit }, type) {
    commit("SET_SELECT_TYPE", type);
  },
  setInitialSearchText({ commit }, text) {
    commit("SET_INITIAL_SEARCH_TEXT", text);
  },
  setMode({ commit }, mode) {
    commit("SET_MODE", mode);
  },
  resetSelected({ commit }) {
    commit("SET_CONFIRM_SELECTED", false);
    commit("SET_SELECTED", null);
    commit("SET_SELECT_TYPE", selectType.NONE);
  },
  openPreviewAsset({ commit }, url) {
    commit("SET_PREVIEW_ASSET", {
      open: true,
      url,
    });
  },
  closePreviewAsset({ commit }) {
    commit("SET_PREVIEW_ASSET", {
      open: false,
      url: "",
    });
  },
};
const mutations = {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_MODE(state, mode) {
    state.mode = mode;
  },
  SET_SELECT_TYPE(state, selectType) {
    state.selectType = selectType;
  },
  SET_IMAGE_EDIT(state, imageEdit) {
    state.imageEdit = imageEdit;
  },
  ADD_SELECTED(state, selected) {
    if (!state.selected) state.selected = [];
    state.selected.push(selected);
  },
  REMOVE_SELECTED(state, id) {
    state.selected = state.selected.filter((item) => item.id !== id);
  },
  SET_SELECTED(state, selected) {
    state.selected = selected;
  },
  SET_RELOAD(state) {
    state.reload = Math.floor(Math.random() * 100000000);
  },
  SET_CONFIRM_SELECTED(state, confirmSelected) {
    state.confirmSelected = confirmSelected;
  },
  SET_PREVIEW_ASSET(state, previewAsset) {
    state.previewAsset = previewAsset;
  },
  SET_INITIAL_SEARCH_TEXT(state, text) {
    state.initialSearchText = text;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
