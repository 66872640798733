export const tokenOperations = (data) => {
  localStorage.setItem("accessToken", data.access_token);
  localStorage.setItem("refreshToken", data.refresh_token);

  const expirationDate = new Date();
  expirationDate.setSeconds(expirationDate.getSeconds() + data.expires_in);
  localStorage.setItem("tokenExpiresAt", expirationDate.getTime());
};

export function getAccessToken() {
  return localStorage.getItem("accessToken");
}
