import { MutationTree } from 'vuex'
import { BuilderState } from './types'
import Draft from '@/calendesk/models/DTO/Response/Draft'
import Page from '@/calendesk/models/DTO/Response/Page'
import Section from '@/calendesk/models/DTO/Response/Section'
import { ChangeStep } from '@/calendesk/models/ChangeStep'
import vuetify from '@/plugins/vuetify'
import { BusinessType, PreviewSizeType } from '@/calendesk/models/BuilderTypes'
import WebsiteStyleModel from '@/calendesk/models/WebsiteStyleModel'

export const mutations: MutationTree<BuilderState> = {
  SET_DRAFT (state, payload: Draft) {
    state.draft = payload

    vuetify.framework.theme.themes.light.wb_color_primary__color__ = payload.configuration.wb_color_primary__color__
    vuetify.framework.theme.themes.light.wb_color_bg__color__ = payload.configuration.wb_color_bg__color__
    vuetify.framework.theme.themes.light.wb_color_text__color__ = payload.configuration.wb_color_text__color__
    vuetify.framework.theme.themes.light.wb_color_bg_2__color__ = payload.configuration.wb_color_bg_2__color__
    vuetify.framework.theme.themes.light.wb_color_text_2__color__ = payload.configuration.wb_color_text_2__color__
  },
  SET_CURRENT_PAGE (state, payload: Page) {
    state.currentPage = payload
  },
  SET_SELECTED_SECTION (state, payload: Section) {
    state.selectedSection = payload
  },
  ADD_TO_STEPS (state, changeStep: ChangeStep) {
    state.steps.splice(state.currentStepIndex, 0, changeStep)
  },
  CLEAR_FUTURE_STEPS (state) {
    state.steps = state.steps.splice(0, state.currentStepIndex)
  },
  SET_CURRENT_STEP_INDEX (state, index: number) {
    state.currentStepIndex = index
  },
  CLEAR_ALL_STEPS (state) {
    state.steps = []
    state.currentStepIndex = 0
  },
  SET_SELECTED_EXTERNAL_CONFIGURATION (state, selectedExternalConfiguration) {
    state.selectedExternalConfiguration = selectedExternalConfiguration
  },
  SET_PREVIEW_TYPE (state, previewType: PreviewSizeType) {
    state.previewType = previewType
  },
  SET_BUSINESS_TYPE (state, businessType: BusinessType) {
    state.businessType = businessType
  },
  SET_WEBSITE_STYLE_MODEL (state, websiteStyleModel: WebsiteStyleModel) {
    state.websiteStyleModel = websiteStyleModel
  }
}
