export enum BuilderSelectType {
  IMAGE_POSITIONS,
  BUTTONS_POSITIONS,
  IMAGE_ROUNDED,
  GALLERY_STYLE,
  BUTTON_SIZE,
  VIEW_ROUNDED,
  PAGE_ID,
  LANGUAGE,
  FORM_POSITIONS
}
