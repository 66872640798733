
























import Vue from 'vue'
import { mapGetters } from 'vuex'
import CalendeskInformationMessage from '@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue'
export default Vue.extend({
  name: 'AdminBanner',
  components: { CalendeskInformationMessage },
  data () {
    return {
      isClosingAdminSession: false
    }
  },
  computed: {
    ...mapGetters({
      cdAdminSessionIssuer: 'auth/getCdAdminSessionIssuer'
    }),
    getCdAdminSessionIssuer (): string | null {
      if (this.cdAdminSessionIssuer) {
        const parts = this.cdAdminSessionIssuer.split('_')
        return parts.length > 1 ? parts[1] : this.cdAdminSessionIssuer
      }

      return null
    }
  },
  methods: {
    closeAdminSession () {
      this.isClosingAdminSession = true
      this.$store.dispatch('auth/logout')
    }
  }
})
