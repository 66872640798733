declare global {
  interface Window {
    $chatwoot: any;
    calendesk: any;
    APP_VERSION: any;
  }
}

export enum SectionType {
  NAVBAR = 'navbar',
  FOOTER = 'footer',
  SECTION = 'section',
  EMPTY = 'empty'
}

export enum SectionCategoryTypeId {
  NAVBAR_1,
  NAVBAR_2,
  EMPLOYEES,
  SERVICES,
  BOOKING,
  GALLERY,
  FEATURE,
  REVIEW,
  FOOTER,
  TEXT,
  STATIC,
  HEADER,
  NEWSLETTER,
  CTA,
  MOST_POPULAR,
  OTHER,
  FORM
}

export enum SidebarType {
  PAGES,
  TOOLS,
  GENERAL,
  STYLE,
  ADD_SECTION,
  REPLACE_NAVBAR,
  REPLACE_FOOTER
}

export enum EmptySectionType {
  EMPTY = 1,
}

export enum FooterSectionType {
  FOOTER1 = 1,
  FOOTER2,
  FOOTER3,
  FOOTER4,
  FOOTER5,
  FOOTER6,
  FOOTER7,
  FOOTER8
}

export enum NavbarSectionType {
  NAVBAR1 = 1,
  NAVBAR2,
  NAVBAR3,
  NAVBAR4,
  NAVBAR5,
  NAVBAR6,
  NAVBAR7,
  NAVBAR8,
  FLEXIBLE_NAVBAR_1
}

export enum SectionsSectionType {
  BOOKING_1 = 1000,
  BOOKING_2 = 1001,
  BOOKING_3 = 1002,
  BOOKING_4 = 1003,
  BOOKING_5 = 1004,
  BOOKING_6 = 1005,
  GALLERY_1 = 2000,
  GALLERY_2 = 2001,
  GALLERY_3 = 2002,
  GALLERY_4 = 2003,
  GALLERY_5 = 2004,
  EMPLOYEES_1 = 3000,
  EMPLOYEES_2 = 3001,
  EMPLOYEES_3 = 3002,
  EMPLOYEES_4 = 3003,
  EMPLOYEES_5 = 3004,
  EMPLOYEES_6 = 3005,
  SERVICES_1 = 4000,
  SERVICES_2 = 4001,
  SERVICES_3 = 4002,
  SERVICES_4 = 4003,
  FEATURE_1 = 5000,
  FEATURE_2 = 5001,
  FEATURE_3 = 5002,
  FEATURE_4 = 5003,
  FEATURE_5 = 5004,
  FEATURE_6 = 5005,
  FEATURE_7 = 5006,
  FEATURE_8 = 5007,
  FEATURE_9 = 5008,
  FEATURE_10 = 5009,
  FEATURE_11 = 5010,
  FEATURE_12 = 5011,
  FEATURE_13 = 5012,
  REVIEW_1 = 6000,
  REVIEW_2 = 6001,
  REVIEW_3 = 6002,
  REVIEW_4 = 6003,
  TEXT_1 = 7000,
  TEXT_2 = 7001,
  TEXT_3 = 7002,
  TEXT_4 = 7003,
  TEXT_5 = 7004,
  TEXT_6 = 7005,
  TEXT_7 = 7006,
  TEXT_8 = 7007,
  TEXT_9 = 7008,
  TEXT_10 = 7009,
  TEXT_11 = 7010,
  TEXT_12 = 7011,
  TEXT_13 = 7012,
  TEXT_14 = 7013,
  STATIC_1 = 8000,
  STATIC_2 = 8001,
  STATIC_3 = 8002,
  STATIC_4 = 8003,
  HEADER_1= 9000,
  HEADER_2= 9001,
  HEADER_3= 9002,
  HEADER_4= 9003,
  HEADER_5= 9004,
  HEADER_6= 9005,
  HEADER_7= 9006,
  HEADER_8= 9007,
  HEADER_9= 9008,
  HEADER_10= 9009,
  HEADER_11= 9010,
  HEADER_12= 9011,
  NEWSLETTER_1= 10000,
  NEWSLETTER_2= 10001,
  NEWSLETTER_3= 10002,
  NEWSLETTER_4= 10003,
  NEWSLETTER_5= 10004,
  NEWSLETTER_6= 10005,
  NEWSLETTER_7= 10006,
  NEWSLETTER_8= 10007,
  CTA_1= 11000,
  CTA_2= 11001,
  CTA_3= 11002,
  OTHER_1= 12000,
  OTHER_2= 12001,
  FLEXIBLE_1= 13000,
  BACKGROUND_1= 14000,
  BACKGROUND_2= 14001,
  FLEXIBLE_GALLERY_1= 15000,
  FLEXIBLE_GALLERY_2= 15001,
  FLEXIBLE_GALLERY_3= 15002,
  FLEXIBLE_GALLERY_4= 15003,
  FLEXIBLE_GALLERY_5= 15004,
  FLEXIBLE_GALLERY_6= 15006,
  FLEXIBLE_GALLERY_7= 15007,
  FLEXIBLE_BOOKING_1= 16000,
  FLEXIBLE_BOOKING_2= 16001,
  FLEXIBLE_BOOKING_3= 16002,
  FLEXIBLE_BOOKING_4= 16003,
  PLAIN_HTML= 17000,
  FLEXIBLE_MAP_1 = 18000,
  FLEXIBLE_REVIEW_1 = 19000,
  FLEXIBLE_REVIEW_2 = 19001,
  FLEXIBLE_FORM_1 = 20000,
  CALENDAR_V2 = 21000
}

export enum PageType {
  MAIN_PAGE = 'main_page',
  PAGE = 'page',
  REDIRECT = 'redirect',
  BLOG_HANDY = 'blog_handy',
}

export enum TenantPlanSlug {
  CD_BASE_LITE= 'cd_base_lite',
  CD_BASE_STANDARD = 'cd_base_standard',
  CD_BASE_PRO = 'cd_base_pro',
}

export enum MoveDirection {
  UP,
  DOWN
}

export enum DraftConfigurationType {
  TEXT = 'text',
  SECTION_FEATURES = 'array_section_features',
  LONG_TEXT = 'long_text',
  HTML_TEXT = 'html_text',
  GDPR_FIELDS = 'gdpr_fields',
  ARRAY_CTA_BUTTONS = 'array_cta_buttons',
  CTA_BUTTON = 'cta_button',
  PAGE_IDS = 'page_ids',
  PAGE_ID = 'page_id',
  EMPLOYEES = 'employees',
  SERVICES = 'services',
  EXTERNAL_CONFIGURATION = 'external_configuration',
  NUMBER = 'number',
  DATE = 'date',
  TIME = 'time',
  STYLE_HEIGHT = 'style_height',
  SELECT = 'select',
  ALERT = 'alert',
  CHECKBOX = 'checkbox',
  COLOR = 'color',
  URL = 'url',
  DYNAMIC_FORM = 'dynamic_form'
}

export enum PreviewSizeType {
  DESKTOP,
  MOBILE
}

export enum BusinessType {
  BUSINESS_1= 1,
  BUSINESS_2,
  BUSINESS_3,
  BUSINESS_4,
  BUSINESS_5,
  BUSINESS_6,
  BUSINESS_7,
  BUSINESS_8,
  BUSINESS_9,
  BUSINESS_10,
  BUSINESS_11,
  BUSINESS_12,
  BUSINESS_13,
  BUSINESS_14,
  BUSINESS_15,
  BUSINESS_16,
  BUSINESS_17,
  BUSINESS_18,
  BUSINESS_19,
  BUSINESS_20,
  BUSINESS_21,
  BUSINESS_22,
  BUSINESS_23,
  BUSINESS_24,
  BUSINESS_25,
  BUSINESS_26,
  BUSINESS_27,
  BUSINESS_28,
  BUSINESS_29,
  BUSINESS_30
}

export enum WebsiteElementType {
  LOGIN = 1,
  BOOKING,
  SERVICES,
  ABOUT_US,
  TEAM,
  TERMS_PRIVACY_POLICY,
  PICTURE_GALLERY,
  NEWSLETTER
}

export enum WebsiteStyleType {
  STYLE_1,
  STYLE_2,
  STYLE_3,
  STYLE_4,
  STYLE_5,
  STYLE_6,
  STYLE_7,
  STYLE_8,
  STYLE_9,
  STYLE_10,
  STYLE_11,
  STYLE_12
}

export enum CtaButtonType {
  REDIRECT = 1,
  BOOKING = 2,
  SIMPLE_STORE_PRODUCT = 3,
  SUBSCRIPTION = 4
}

export enum DynamicFormType {
  GENERAL = 'general',
  BOOKING = 'booking'
}
