import { CategoryStatus } from '@/calendesk/models/DTO/Response/CategoryStatus'
import SectionImage from '@/calendesk/models/DTO/Response/SectionImage'
import Service from '@/calendesk/models/DTO/Response/Service'
import { Expose, Type } from 'class-transformer'

export default class Category {
  public id!: number
  public name!: string
  public status!: CategoryStatus

  @Expose({ name: 'order_position' })
  public orderPosition!: number

  @Expose({ name: 'default_image' })
  public defaultImage!: SectionImage

  @Type(() => Service)
  public services!: Service[] | null
}
