import { mapActions, mapGetters } from "vuex";
import {
  platformTimeZone,
  userTimeZone,
} from "@/lib/calendesk-js-library/tools/helpers";

export default {
  computed: {
    ...mapGetters({
      isPreloading: "setup/isPreloading",
      isSending: "getIsSending",
      getStatsLoaded: "getStatsLoaded",
      getDialog: "dialog/getInstance",
      getConfirmDialog: "confirmDialog/getInstance",

      loggedUser: "auth/getUser",
      loggedUserIsAdmin: "auth/isAdmin",
      loggedUserIsRoot: "auth/isRoot",
      isUserLogged: "auth/isUserLogged",
      isEmployee: "auth/isEmployee",

      commonDialogLoader: "loader/getCommonDialogLoader",
    }),
    getLargeDialogWidth() {
      if (this.$vuetify.breakpoint.width >= 1024) {
        return "80%";
      }

      return "100%";
    },
    userTimeZone() {
      return userTimeZone();
    },
    platformTimeZone() {
      return platformTimeZone();
    },
  },
  methods: {
    ...mapActions({
      setIsSending: "setIsSending",
      clearCache: "cache/clearCache",

      openDialog: "dialog/open",
      closeDialog: "dialog/close",

      openConfirmDialog: "confirmDialog/open",
      closeConfirmDialog: "confirmDialog/close",
      setCommonDialogLoader: "loader/setCommonDialogLoader",
    }),
    async delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    getToday() {
      return this.$moment().format(this.$helpers.dateFormat);
    },
    getTomorrow() {
      return this.$moment().add(1, "day").format(this.$helpers.dateFormat);
    },
    generateRandomString(length) {
      return this.$helpers.generateRandomString(length);
    },
  },
};
