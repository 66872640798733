import { ServiceStatus } from '@/calendesk/models/DTO/Response/ServiceStatus'
import { BookingStatus } from '@/calendesk/models/DTO/Response/BookingStatus'
import Category from '@/calendesk/models/DTO/Response/Category'
import Employee from '@/calendesk/models/DTO/Response/Employee'
import ServiceType from '@/calendesk/models/DTO/Response/ServiceType'
import ServiceRange from '@/calendesk/models/DTO/Response/ServiceRange'
import DefaultImage from '@/calendesk/models/DTO/Response/DefaultImage'
import { Expose, Type } from 'class-transformer'
import store from '@/store'

export default class Service {
  public id!: number

  @Expose({ name: 'category_id' })
  public categoryId!: number

  public name!: string
  public description!: string | null

  @Expose({ name: 'booking_time_before' })
  public bookingTimeBefore!: number

  @Expose({ name: 'cancel_time_before' })
  public cancelTimeBefore!: number

  @Expose({ name: 'max_people' })
  public maxPeople!: number

  public duration!: number

  @Expose({ name: 'slot_every' })
  public slotsEvery!: number

  public price!: number

  @Expose({ name: 'allow_dynamic_duration' })
  public allowDynamicDuration!: number

  @Expose({ name: 'allow_online_payment' })
  public allowOnlinePayment!: boolean

  @Expose({ name: 'payment_required' })
  public paymentRequired!: boolean

  public status!: ServiceStatus

  @Expose({ name: 'order_position' })
  public orderPosition!: number

  @Expose({ name: 'default_booking_status' })
  public defaultBookingStatus!: BookingStatus

  @Expose({ name: 'default_image' })
  @Type(() => DefaultImage)
  public defaultImage!: DefaultImage | null

  @Type(() => Category)
  public category!: Category

  @Type(() => Employee)
  public employees!: Employee[] | null

  @Type(() => ServiceType)
  public types!: Array<ServiceType> | null

  @Expose({ name: 'duration_range' })
  public durationsRange!: ServiceRange

  @Expose({ name: 'service_range' })
  public pricesRange!: ServiceRange

  public getDefaultImageUrl (): string {
    if (this.defaultImage && store.getters['setup/getAppConfiguration']) {
      return store.getters['setup/getAppConfiguration'].imageBaseUrl + this.defaultImage.name
    }

    return require('@/lib/calendesk-js-library/assets/placeholder.jpg')
  }
}
