












import Vue from 'vue'
import BusinessCardModel from '@/calendesk/models/BusinessCardModel'
import BusinessCard from '@/views/components/creator/BusinessCard.vue'

export default Vue.extend({
  name: 'SelectBusiness',
  components: {
    BusinessCard
  },
  data () {
    return {
      businessCards: [] as Array<BusinessCardModel>
    }
  },
  mounted () {
    this.generateCards()
  },
  methods: {
    generateCards () {
      for (let i = 1; i <= 30; i++) {
        this.businessCards.push(
          new BusinessCardModel(i, require('@/assets/business_' + i + '.jpg'), this.$trans('wb_business_' + i))
        )
      }
    }
  }
})
