












































































import Sidebar from '@/calendesk/models/Sidebar'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import sidebarTabs from './sidebarTabs/SidebarTabs'
export default Vue.extend({
  name: 'Sidebar',
  computed: {
    ...mapGetters({
      sidebar: 'sidebar/getSidebar',
      changeSteps: 'builder/getChangeSteps',
      currentStepIndex: 'builder/getCurrentStepIndex',
      sidebarDetails: 'sidebar/getSidebarDetails'
    }),
    drawer: {
      get () {
        return this.sidebar
      },
      set (value: Sidebar) {
        this.openSidebar(value)
      }
    },
    getSidebarTab () {
      const sidebarTab = sidebarTabs.find(_ => _.type === this.sidebar.type)
      if (!sidebarTab) return null
      return sidebarTab.component
    }
  },
  methods: {
    ...mapActions({
      closeSidebarDetails: 'sidebar/closeSidebarDetails',
      openSidebar: 'sidebar/openSidebar',
      closeSidebar: 'sidebar/closeSidebar',
      restoreLastStep: 'builder/restoreLastStep',
      restoreNextStep: 'builder/restoreNextStep'
    })
  }
})
