import store from "../../../store";
import log from "../tools/log";

export const config = (key) => {
  if (
    store &&
    store.state &&
    store.state.setup &&
    store.state.setup.configuration
  ) {
    const config = store.state.setup.configuration[key];

    if (typeof config !== "undefined") {
      return config;
    }

    if (config === undefined) {
      log.warn("Configuration missing!", key);
    }
  }

  return null;
};
