import Section from './Section'
import { PageType } from '@/calendesk/models/BuilderTypes'
import { Expose, Type } from 'class-transformer'

export default class Page {
  public id!: number | null
  public uuid!: string
  public name!: string
  public type!: PageType
  public route!: string | null
  public action!: string | null

  @Expose({ name: 'auth_only' })
  public authOnly!: boolean

  public configuration!: Record<string, any>

  @Type(() => Section)
  public sections!: Array<Section> | null
}
