import Section from './DTO/Response/Section'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'

export default class PreviewSection {
  public section: Section
  public component: Promise<any>
  public sectionConfiguration?: SectionConfiguration

  constructor (section: Section, component: Promise<any>) {
    this.section = section
    this.component = component
  }
}
