import { MutationTree } from 'vuex'
import { SidebarState } from './types'
import Sidebar from '@/calendesk/models/Sidebar'
import SidebarDetails from '@/calendesk/models/SidebarDetails'
import { SidebarDetailsType } from '@/builder/sidebar/sidebarDetails/SidebarDetailsType'

export const mutations: MutationTree<SidebarState> = {
  SET_SIDEBAR (state, payload: Sidebar) {
    state.sidebar = payload
    state.sidebarDetails = new SidebarDetails(false, SidebarDetailsType.NONE, {})
  },
  SET_SIDEBAR_DETAILS (state, payload: SidebarDetails) {
    state.sidebarDetails = payload
  }
}
