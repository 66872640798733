












































import Vue from 'vue'
import WebsiteStyleModel from '@/calendesk/models/WebsiteStyleModel'

export default Vue.extend({
  name: 'StyleCard',
  props: {
    websiteStyle: {
      type: WebsiteStyleModel
    }
  },
  components: {
  },
  data () {
    return {

    }
  },
  created () {
    //
  },
  methods: {

  }
})
