












import Vue from 'vue'
import AppBar from './components/AppBar.vue'
import AppView from './components/AppView.vue'
import AppDialog from '@/lib/calendesk-js-library/components/dialogs/AppDialog.vue'
import ConfirmDialog from '@/lib/calendesk-js-library/components/confirmDialogs/ConfirmDialog.vue'
import Snackbar from '@/lib/calendesk-js-library/components/Snackbar.vue'
import PreviewAsset from '@/lib/calendesk-js-library/components/PreviewAsset.vue'
import DialogList from '@/components/dialogs/DialogList'
import confirmDialogList from '@/components/confirmDialogs/confirmDialogList'
import ReloadSnackbar from '@/lib/calendesk-js-library/components/ReloadSnackbar.vue'
import { mapActions, mapGetters } from 'vuex'
import Dialog from '@/calendesk/models/Dialog'
import sharedDialogTypes from '@/lib/calendesk-js-library/components/dialogs/sharedDialogTypes'
import dialogSize from '@/lib/calendesk-js-library/components/dialogs/dialogSize'

export default Vue.extend({
  name: 'BuilderIndex',
  components: { ReloadSnackbar, AppBar, AppView, AppDialog, Snackbar, PreviewAsset, ConfirmDialog },
  data () {
    return {
      dialogList: DialogList,
      confirmDialogList
    }
  },
  computed: {
    ...mapGetters({
      appConfiguration: 'setup/getAppConfiguration'
    })
  },
  mounted () {
    this.$root.$on('openFeatureNotAvailableDialog', this.openFeatureNotAvailableDialog)
    this.$root.$on('openPlanView', this.openPlanView)
  },
  beforeDestroy () {
    this.$root.$off('openFeatureNotAvailableDialog', this.openFeatureNotAvailableDialog)
    this.$root.$off('openPlanView', this.openPlanView)
  },
  methods: {
    ...mapActions({
      closeDialog: 'dialog/close',
      openDialog: 'dialog/open'
    }),
    openFeatureNotAvailableDialog () {
      this.openDialog(new Dialog(true, sharedDialogTypes.FEATURE_NOT_AVAILABLE_DIALOG, '', dialogSize.LARGE))
    },
    openPlanView () {
      const lang = this.appConfiguration ? this.appConfiguration.language : 'en'
      window.open('https://admin.calendesk.com/' + lang + '/panel/dashboard?plan=1')
    }
  }
})
