












import WebsiteStyleModel from '@/calendesk/models/WebsiteStyleModel'
import StyleCard from '@/views/components/creator/StyleCard.vue'
import { WebsiteStyleType } from '@/calendesk/models/BuilderTypes'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/builder/sections/mixins/DraftElement'

export default mixins(DraftElement).extend({
  name: 'SelectStyle',
  components: {
    StyleCard
  },
  data () {
    return {
      websiteStyles: [] as Array<WebsiteStyleModel>
    }
  },
  mounted () {
    this.generateWebsiteStyles()
  },
  methods: {
    generateWebsiteStyles () {
      const locale = WebsiteStyleModel.getAvailableLocale()

      this.websiteStyles.push(
        new WebsiteStyleModel(WebsiteStyleType.STYLE_1, require(`@/assets/style_1_${locale}.jpeg`), this.$trans('wb_website_style_1'), '#1687A7', '#FFFFFF', '#184C5C', '#D3E0EA', '#184C5C', WebsiteStyleModel.isDark('#FFFFFF')),
        new WebsiteStyleModel(WebsiteStyleType.STYLE_2, require(`@/assets/style_2_${locale}.jpeg`), this.$trans('wb_website_style_2'), '#00ADB5', '#FFFFFF', '#222831', '#EEEEEE', '#222831', WebsiteStyleModel.isDark('#FFFFFF')),
        new WebsiteStyleModel(WebsiteStyleType.STYLE_3, require(`@/assets/style_3_${locale}.jpeg`), this.$trans('wb_website_style_3'), '#B68973', '#FFFFFF', '#1E212D', '#FAF3E0', '#1E212D', WebsiteStyleModel.isDark('#FFFFFF')),
        new WebsiteStyleModel(WebsiteStyleType.STYLE_4, require(`@/assets/style_4_${locale}.jpeg`), this.$trans('wb_website_style_4'), '#D72323', '#FFFFFF', '#35393F', '#EEEEEE', '#35393F', WebsiteStyleModel.isDark('#FFFFFF')),
        new WebsiteStyleModel(WebsiteStyleType.STYLE_5, require(`@/assets/style_5_${locale}.jpeg`), this.$trans('wb_website_style_5'), '#FB743E', '#FFFFFF', '#383E56', '#E4F5EE', '#383E56', WebsiteStyleModel.isDark('#FFFFFF')),
        new WebsiteStyleModel(WebsiteStyleType.STYLE_6, require(`@/assets/style_6_${locale}.jpeg`), this.$trans('wb_website_style_6'), '#CF4647', '#F5D061', '#2A363B', '#2A363B', '#F5D061', WebsiteStyleModel.isDark('#F5D061')),
        new WebsiteStyleModel(WebsiteStyleType.STYLE_7, require(`@/assets/style_7_${locale}.jpeg`), this.$trans('wb_website_style_7'), '#62768F', '#FFFFFF', '#41444B', '#F6F4E6', '#41444B', WebsiteStyleModel.isDark('#FFFFFF')),
        new WebsiteStyleModel(WebsiteStyleType.STYLE_8, require(`@/assets/style_8_${locale}.jpeg`), this.$trans('wb_website_style_8'), '#CF1B1B', '#FFFFFF', '#423144', '#FFEBE0', '#900D0D', WebsiteStyleModel.isDark('#FFFFFF')),
        new WebsiteStyleModel(WebsiteStyleType.STYLE_9, require(`@/assets/style_9_${locale}.jpeg`), this.$trans('wb_website_style_9'), '#006A71', '#FFFFFF', '#006A71', '#FFFFDD', '#006A71', WebsiteStyleModel.isDark('#FFFFFF')),
        new WebsiteStyleModel(WebsiteStyleType.STYLE_10, require(`@/assets/style_10_${locale}.jpeg`), this.$trans('wb_website_style_10'), '#415F9D', '#FFFFFF', '#233B6E', '#EFF0F4', '#233B6E', WebsiteStyleModel.isDark('#FFFFFF')),
        new WebsiteStyleModel(WebsiteStyleType.STYLE_11, require(`@/assets/style_11_${locale}.jpeg`), this.$trans('wb_website_style_11'), '#837ACF', '#FFFFFF', '#655E8A', '#FFF3E2', '#655E8A', WebsiteStyleModel.isDark('#FFFFFF')),
        new WebsiteStyleModel(WebsiteStyleType.STYLE_12, require(`@/assets/style_12_${locale}.jpeg`), this.$trans('wb_website_style_12'), '#FC3A52', '#FFFFFF', '#0E2431', '#F9B248', '#0E2431', WebsiteStyleModel.isDark('#FFFFFF'))
      )
    }
  }
})
