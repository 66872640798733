export default function (v1, v2) {
  function isPositiveInteger(x) {
    // http://stackoverflow.com/a/1019526/11236
    return /^\d+$/.test(x);
  }

  const v1parts = v1.split(".");
  const v2parts = v2.split(".");

  // First, validate both numbers are true version numbers
  function validateParts(parts) {
    for (let i = 0; i < parts.length; ++i) {
      if (!isPositiveInteger(parts[i])) {
        return false;
      }
    }
    return true;
  }

  if (!validateParts(v1parts) || !validateParts(v2parts)) {
    return NaN;
  }

  for (let i = 0; i < v1parts.length; ++i) {
    if (v2parts.length === i) {
      return 1;
    }

    const first = parseInt(v1parts[i]);
    const second = parseInt(v2parts[i]);

    if (first === second) {
      continue;
    }
    if (first > second) {
      return 1;
    }

    return -1;
  }

  if (v1parts.length !== v2parts.length) {
    return -1;
  }

  return 0;
}
