import store from "@/store";
import log from "@/lib/calendesk-js-library/tools/log";

export function getItem(key, parse = true, tenantPrefix = false) {
  let value = null;
  const tenant = store.getters["setup/getTenant"];

  if (tenantPrefix && !tenant) {
    return null;
  }

  if (tenantPrefix) {
    key = `${tenant}_${key}`;
  }

  if (localStorage.getItem(key) !== null) {
    value = localStorage.getItem(key);
  }

  if (parse) {
    try {
      value = JSON.parse(value);
    } catch (error) {
      console.error({
        message: `Can not parse ${key} value from localStorage. Please remove this localStorage entry or make sure that you 'parse' attribute is used intentionally.`,
        error,
      });
    }
  }

  return value;
}

export function setItem(key, value, stringify = true, tenantPrefix = false) {
  if (stringify) {
    value = JSON.stringify(value);
  }

  if (tenantPrefix) {
    const tenant = store.getters["setup/getTenant"];

    if (tenant) {
      key = `${tenant}_${key}`;
      localStorage.setItem(key, value);
    } else {
      // If no tenant is set, skip the update.
      log.warn(
        "setItem with tenantPrefix, but no tenant is set",
        key,
        value,
        stringify
      );
    }
  } else {
    localStorage.setItem(key, value);
  }
}

export function removeItem(key, tenantPrefix = false) {
  const tenant = store.getters["setup/getTenant"];

  if (tenantPrefix && !tenant) {
    return;
  }

  if (tenantPrefix) {
    key = `${tenant}_${key}`;
  }

  localStorage.removeItem(key);
}
