import { GetterTree } from 'vuex'
import { RootState } from '../../types'
import Category from '@/calendesk/models/DTO/Response/Category'
import { CategoryState } from '@/store/modules/category/types'

export const getters: GetterTree<CategoryState, RootState> = {
  categoryWithServices (state): Category[] | null {
    return state.categoryAndServices
  }
}
