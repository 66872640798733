import { Expose } from 'class-transformer'

export default class Address {
  public id!: number
  public name!: string
  public city!: string
  public street!: string

  @Expose({ name: 'vat_number' })
  public vatNumber!: string

  @Expose({ name: 'vat_iso_prefix' })
  public vatIsoPrefix!: string

  @Expose({ name: 'postal_code' })
  public postalCode!: string

  @Expose({ name: 'country_iso_code' })
  public countryIsoCode!: string
}
