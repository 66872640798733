import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { ServiceState } from './types'
import { RootState } from '../../types'

export const state: ServiceState = {
  services: null
}

const namespaced = true

export const service: Module<ServiceState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
