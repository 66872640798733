






import Vue from 'vue'
import Builder from '@/builder/Builder.vue'

export default Vue.extend({
  name: 'Home',
  components: { Builder }
})
