import { BusinessType } from '@/calendesk/models/BuilderTypes'

export default class BusinessCardModel {
  public id: BusinessType
  public imageUrl: string
  public name: string

  public constructor (id: BusinessType, imageUrl: string, name: string) {
    this.id = id
    this.imageUrl = imageUrl
    this.name = name
  }
}
