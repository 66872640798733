<template>
  <v-dialog v-model="previewAsset.open" class="preview-asset" width="500px">
    <v-card class="preview-asset__card" color="white">
      <v-img :src="previewAsset.url" aspect-ratio="1" />
      <v-btn
        icon
        elevation="6"
        height="32"
        width="32"
        @click="closePreviewAsset"
      >
        <v-icon size="18"> $close</v-icon>
      </v-btn>
      <v-text-field
        hide-details
        dense
        color="white"
        background-color="white"
        :value="previewAsset.url"
        readonly
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PreviewAsset",
  data() {
    return {
      previewAsset: this.$store.getters["assets/getPreviewAsset"],
    };
  },
  computed: {
    ...mapGetters({
      getPreviewAsset: "assets/getPreviewAsset",
    }),
  },
  watch: {
    "getPreviewAsset.open": function () {
      this.previewAsset = {
        ...this.getPreviewAsset,
      };
    },
    "previewAsset.open": function () {
      if (this.previewAsset.open === this.getPreviewAsset.open) {
        return;
      }
      this.closePreviewAsset();
    },
  },
  methods: {
    ...mapActions({
      closePreviewAsset: "assets/closePreviewAsset",
    }),
  },
};
</script>

<style lang="scss" scoped>
.preview-asset__card {
  position: relative;
}

.v-btn {
  position: absolute;
  right: 15px;
  top: 15px;
  background: var(--v-background-base);
}
</style>
