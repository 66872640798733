import { MutationTree } from 'vuex'
import { DomainSelect } from './types'
import Domain from '@/calendesk/models/DTO/Response/Domain'

export const mutations: MutationTree<DomainSelect> = {
  SET_DOMAINS (state, payload: Domain[] | null) {
    state.domains = payload
  },
  SET_DEFAULT_DOMAIN (state, payload: Domain | null) {
    state.defaultDomain = payload
  }
}
