import { BusinessType } from '@/calendesk/models/BuilderTypes'
import store from '@/store'

export class CreatorDefaultImage {
  public static images: Record<string, string> = {}

  public static getImage (number: number): string {
    const type = store.getters['builder/getBusinessType'] as BusinessType

    return `/demo/wb/b${type}/img${number}.jpg`
  }
}
