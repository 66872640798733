import SectionCategory from '@/calendesk/models/SectionCategory'
import { SectionType } from '@/calendesk/models/BuilderTypes'
import empty from '@/builder/sections/empty/Components'
import navbars from '@/builder/sections/navbar/Navbars'
import footers from '@/builder/sections/footer/Footers'
import sections from '@/builder/sections/section/Sections'

const sectionComponents: Array<SectionCategory> = [
  {
    type: SectionType.EMPTY,
    components: empty
  },
  {
    type: SectionType.NAVBAR,
    components: navbars
  },
  {
    type: SectionType.FOOTER,
    components: footers
  },
  {
    type: SectionType.SECTION,
    components: sections
  }
]

export default sectionComponents
