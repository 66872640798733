








import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import Sidebar from '@/calendesk/models/Sidebar'
import { SidebarType } from '@/calendesk/models/BuilderTypes'
import { pushEvent } from '@/lib/calendesk-js-library/tools/helpers'
export default Vue.extend({
  name: 'Empty',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      sidebar: 'sidebar/getSidebar'
    })
  },
  methods: {
    ...mapActions({
      openSidebar: 'sidebar/openSidebar'
    }),
    emptySectionClicked () {
      pushEvent('wbOpenSectionCategories')
      if (!this.sidebar.open) {
        this.openSidebar(new Sidebar(true, SidebarType.ADD_SECTION, this.$trans('wb_select_new_section')))
      }
    }
  }
})
