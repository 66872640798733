import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'

export default (): SectionConfiguration => {
  return {
    type: SectionType.SECTION,
    configuration: {
      component_id: SectionsSectionType.STATIC_1,
      present_height: 600,
      wb_position: 0,
      wb_title__text__: trans('wb_dummy_text_regulations')
    },
    images: []
  }
}
