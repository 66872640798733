export enum SidebarDetailsType {
  NONE,
  ADD_SECTION,
  EXTERNAL_CONFIGURATION,
  PAGES_SELECT,
  EMPLOYEES_SELECT,
  SERVICES_SELECT,
  REPLACE_NAVBAR,
  REPLACE_FOOTER
}
