











































































































































































































import { MoveDirection, SectionType, SidebarType } from '@/calendesk/models/BuilderTypes'
import SectionToMove from '@/calendesk/models/SectionToMove'
import Sidebar from '@/calendesk/models/Sidebar'
import { mapActions, mapGetters } from 'vuex'
import sharedConfirmDialogTypes from '@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes'
import { errorNotification, successNotification } from '@/lib/calendesk-js-library/tools/notification'
import Section from '@/calendesk/models/DTO/Response/Section'
import mixins from 'vue-typed-mixins'
import DraftActions from '@/builder/mixins/DraftActions'
import { DraftActionsType } from '@/calendesk/models/DraftActionsType'
import PlanActions from '@/builder/mixins/PlanActions'
import Dialog from '@/calendesk/models/Dialog'
import dialogTypes from '@/components/dialogs/dialogTypes'
import dialogSize from '@/lib/calendesk-js-library/components/dialogs/dialogSize'
import { pushEvent } from '@/lib/calendesk-js-library/tools/helpers'

export default mixins(DraftActions, PlanActions).extend({
  name: 'CSelectedSection',
  props: {
    data: {
      type: Section
    },
    disabledMoveUp: {
      type: Boolean,
      default: false
    },
    disabledMoveDown: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      mode: DraftActionsType.SECTION,
      selected: false,
      disabledSelect: false,
      moveDirection: {
        up: MoveDirection.UP,
        down: MoveDirection.DOWN
      },
      sectionTypes: SectionType
    }
  },
  computed: {
    ...mapGetters({
      selectedSection: 'builder/getSelectedSection',
      draft: 'builder/getDraft'
    }),
    showTapTip (): boolean {
      const size = this.data.configuration.wb_height__style_height__

      if (size && size < 150) {
        return false
      }

      return !this.selected
    },
    showDecreaseButton (): boolean {
      if (!this.data.configuration.wb_height__style_height__) {
        return false
      }

      if (this.data.configuration.wb_min_height) {
        return this.data.configuration.wb_height__style_height__ > this.data.configuration.wb_min_height
      }

      return true
    },
    showIncreaseButton (): boolean {
      if (!this.data.configuration.wb_height__style_height__) {
        return false
      }

      if (this.data.configuration.wb_max_height) {
        return this.data.configuration.wb_height__style_height__ < this.data.configuration.wb_max_height
      }

      return true
    }
  },
  watch: {
    selectedSection () {
      if (!this.selectedSection || this.selectedSection.id !== this.data.id) {
        this.selected = false
      }
    }
  },
  methods: {
    ...mapActions({
      openDialog: 'dialog/open',
      openSidebar: 'sidebar/openSidebar',
      removeSection: 'builder/removeSection',
      moveSection: 'builder/moveSection',
      createEmptySection: 'builder/createEmptySection',
      openConfirmDialog: 'confirmDialog/open',
      closeConfirmDialog: 'confirmDialog/close',
      setCommonDialogLoader: 'loader/setCommonDialogLoader',
      setSelectedSection: 'builder/setSelectedSection'
    }),
    selectElement () {
      if (this.disabledSelect) {
        this.disabledSelect = false
        return
      }
      this.selected = true
      this.setSelectedSection(this.data)
      this.openSidebar(new Sidebar(true, SidebarType.TOOLS, this.$trans('wb_section')))
    },
    increase () {
      pushEvent('wbIncreaseSectionHeight')

      this.disabledSelect = true
      this.setSelectedSection(this.data)
      const difference = this.data.type === SectionType.NAVBAR ? 10 : 25
      const value = String(Number(this.data.configuration.wb_height__style_height__) + difference)
      this.updateConfigurationProperty('wb_height__style_height__', value, true)
    },
    decrease () {
      pushEvent('wbDecreaseSectionHeight')

      this.disabledSelect = true
      this.setSelectedSection(this.data)
      const difference = this.data.type === SectionType.NAVBAR ? 10 : 25
      const value = String(Number(this.data.configuration.wb_height__style_height__) - difference)
      this.updateConfigurationProperty('wb_height__style_height__', value, true)
    },
    duplicate () {
      this.disabledSelect = true

      this.openDialog(new Dialog(
        true,
        dialogTypes.DUPLICATE_SECTION,
        this.$trans('wb_duplicate_section_title'),
        dialogSize.MIDDLE,
        false,
        'center',
        this.data
      ))
    },
    remove () {
      this.disabledSelect = true
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        title: this.$trans('wb_remove_confirm_dialog_title'),
        warning: this.$trans('wb_remove_confirm_dialog_text'),
        hideImportantInformation: true,
        confirmAction: () => {
          this.setCommonDialogLoader(true)
          this.removeSection(this.data).then(() => {
            successNotification('wb_remove_success_text')
          }).catch((error) => {
            errorNotification('error_occurred', error)
          }).finally(() => {
            this.setCommonDialogLoader(false)
            this.closeConfirmDialog()
          })
        }
      })
    },
    move (moveDirection: MoveDirection) {
      this.disabledSelect = true
      let sectionToMove: SectionToMove | null = null
      if (moveDirection === MoveDirection.UP) {
        sectionToMove = new SectionToMove(this.data, MoveDirection.UP)
      } else if (moveDirection === MoveDirection.DOWN) {
        sectionToMove = new SectionToMove(this.data, MoveDirection.DOWN)
      }

      if (sectionToMove) {
        this.moveSection(sectionToMove)
      }
    },
    createSection (moveDirection: string) {
      this.disabledSelect = true
      let sectionToMove: SectionToMove | null = null
      if (moveDirection === 'up') {
        sectionToMove = new SectionToMove(this.data, MoveDirection.UP)
      } else if (moveDirection === 'down') {
        sectionToMove = new SectionToMove(this.data, MoveDirection.DOWN)
      }

      if (sectionToMove) {
        this.openSidebar(new Sidebar(true, SidebarType.ADD_SECTION, this.$trans('wb_select_new_section')))
        this.createEmptySection(sectionToMove)
      }
    },
    replaceDraftSection () {
      pushEvent('wbReplaceSection')

      this.disabledSelect = true
      let sidebarType = SidebarType.REPLACE_NAVBAR
      let trans = this.$trans('wb_replace_navbar')

      if (this.data.type === SectionType.FOOTER) {
        sidebarType = SidebarType.REPLACE_FOOTER
        trans = this.$trans('wb_replace_footer')
      }

      this.openSidebar(new Sidebar(true, sidebarType, trans))
    },
    hideSection (type: SectionType) {
      pushEvent('wbHideSection')

      if (this.hideSectionsAvailable) {
        if (type === SectionType.FOOTER) {
          this.updateGeneralConfiguration('wb_hide_footer__checkbox__', true, true)
        } else if (type === SectionType.NAVBAR) {
          this.updateGeneralConfiguration('wb_hide_header__checkbox__', true, true)
        }

        successNotification('wb_section_hidden_info')
      } else {
        this.openFeatureNotAvailableDialog()
      }
    }
  }
})
