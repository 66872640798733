import { MutationTree } from 'vuex'
import { SetupState } from './types'
import Configuration from '@/calendesk/models/DTO/Response/Configuration'
import AdminConfiguration from '@/calendesk/models/DTO/Response/AdminConfiguration'
import { Moment } from 'moment'
import TenantPlan from '@/calendesk/models/DTO/Response/TenantPlan'

export const mutations: MutationTree<SetupState> = {
  SET_CONFIGURATION (state, payload: object) {
    state.configuration = payload
  },
  SET_APP_CONFIGURATION (state, payload: Configuration) {
    state.appConfiguration = payload
  },
  SET_ADMIN_CONFIGURATION (state, payload: AdminConfiguration) {
    state.adminConfiguration = payload
  },
  SET_TRANSLATIONS (state, payload: object) {
    state.translations = payload
  },
  SET_PRELOADER (state, payload: boolean) {
    state.preloader = payload
  },
  SET_REGULATIONS (state, payload: string) {
    state.regulations = payload
  },
  SET_PRIVACY_POLICY (state, payload: string) {
    state.privacyPolicy = payload
  },
  SET_VERSION_CHECKED_AT (state, date: Moment | null) {
    state.versionCheckedAt = date
  },
  SET_CURRENT_TENANT_PLAN_SLUG (state, slug: string | null) {
    state.currentTenantPlanSlug = slug
  },
  SET_CURRENT_TENANT_PLAN (state, tenantPlan: TenantPlan | null) {
    state.currentTenantPlan = tenantPlan
  },
  SET_REFERRER (state, referrer: string) {
    state.referrer = referrer
  },
  SET_TENANT (state, tenant: string) {
    state.tenant = tenant
  }
}
