import Sidebar from '@/calendesk/models/Sidebar'
import SidebarDetails from '@/calendesk/models/SidebarDetails'
import { GetterTree } from 'vuex'
import { RootState } from '../../types'
import { SidebarState } from './types'

export const getters: GetterTree<SidebarState, RootState> = {
  getSidebar (state): Sidebar {
    const { sidebar } = state
    return sidebar
  },
  isSidebarOpen (state): boolean {
    return state.sidebar.open
  },
  getSidebarDetails (state): SidebarDetails {
    const { sidebarDetails } = state
    return sidebarDetails
  }
}
