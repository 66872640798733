









import Vue from 'vue'
import AppView from './components/AppView.vue'
import AppDialog from '@/lib/calendesk-js-library/components/dialogs/AppDialog.vue'
import ConfirmDialog from '@/lib/calendesk-js-library/components/confirmDialogs/ConfirmDialog.vue'
import Snackbar from '@/lib/calendesk-js-library/components/Snackbar.vue'
import DialogList from '@/components/dialogs/DialogList'
import confirmDialogList from '@/components/confirmDialogs/confirmDialogList'

export default Vue.extend({
  name: 'CreatorIndex',
  components: { AppView, AppDialog, Snackbar, ConfirmDialog },
  data () {
    return {
      dialogList: DialogList,
      confirmDialogList
    }
  }
})
