import { MutationTree } from 'vuex'
import { AuthState } from './types'
import User from '@/calendesk/models/DTO/Response/User'
import { removeItem, setItem } from '@/lib/calendesk-js-library/tools/localStorageManager'

export const mutations: MutationTree<AuthState> = {
  SET_USER (state, user: User) {
    state.user = user
    setItem('user_id', user.id, false, false)
  },
  SET_CD_ADMIN_SESSION_ISSUER (state, value: string) {
    if (!value) {
      removeItem('cd_admin_session_issuer', false)
    } else {
      setItem('cd_admin_session_issuer', value, false, false)
    }
  }
}
