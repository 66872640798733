import { SidebarDetailsType } from './SidebarDetailsType'

const sidebarDetailsList: Array<Record<string, any>> = [
  {
    type: SidebarDetailsType.ADD_SECTION,
    component: () => import('./components/SelectSection.vue')
  },
  {
    type: SidebarDetailsType.REPLACE_NAVBAR,
    component: () => import('./components/SelectSection.vue')
  },
  {
    type: SidebarDetailsType.REPLACE_FOOTER,
    component: () => import('./components/SelectSection.vue')
  },
  {
    type: SidebarDetailsType.EXTERNAL_CONFIGURATION,
    component: () => import('./components/ExternalConfiguration.vue')
  },
  {
    type: SidebarDetailsType.PAGES_SELECT,
    component: () => import('./components/PagesSelect.vue')
  },
  {
    type: SidebarDetailsType.EMPLOYEES_SELECT,
    component: () => import('./components/EmployeesSelect.vue')
  },
  {
    type: SidebarDetailsType.SERVICES_SELECT,
    component: () => import('./components/ServicesSelect.vue')
  }
]

export default sidebarDetailsList
