import Vue from "vue";

const debug = process.env.NODE_ENV !== "production";

const log = {
  debug(message, data) {
    if (!debug) {
      return;
    }
    if (!data) {
      console.log(message);
      return;
    }
    console.log(message, data);
  },
  info(message, data) {
    if (!debug) {
      return;
    }
    if (!data) {
      console.info(`[INFO] ${message}`);
      return;
    }
    console.info(`[INFO] ${message}`, data);
  },
  warn(message, data) {
    if (!debug) {
      return;
    }
    if (!data) {
      console.warn(message);
      return;
    }
    console.warn(message, data);
  },
  error(message, data) {
    if (!data) {
      console.error(message);
      return;
    }
    console.error(message, data);
  },
};

export default log;

Vue.prototype.$log = log;
