import User from '@/calendesk/models/DTO/Response/User'
import SimpleService from '@/calendesk/models/DTO/Response/SimpleService'
import { Expose, Type } from 'class-transformer'
import Day from '@/calendesk/models/DTO/Response/Day'

export default class Employee {
  public id!: number
  public title!: string | null
  public description!: string | null
  public facebook!: string | null
  public instagram!: string | null
  public youtube!: string | null
  public twitter!: string | null
  public color!: string | null

  @Type(() => Day)
  public days!: Day[]

  @Expose({ name: 'is_public' })
  public isPublic!: boolean

  @Type(() => User)
  public user!: User

  @Type(() => SimpleService)
  public services!: Array<SimpleService> | null

  public getFullName (): string {
    return this.user.getFullName()
  }

  public getDefaultImageUrl (): string {
    return this.user.getDefaultImageUrl()
  }
}
