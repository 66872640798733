<template>
  <v-snackbar v-model="show" :color="color" :timeout="timeout" elevation="0">
    <div class="d-flex align-center">
      <v-icon v-if="icon" class="mx-2" :class="getTextColor">{{ icon }}</v-icon>
      <div :class="getTextColor">{{ text }}</div>
      <v-spacer />
      <v-btn v-if="closable" icon :class="getTextColor" @click="close">
        <v-icon>$close</v-icon>
      </v-btn>
    </div>
    <template v-if="redirect && redirectText">
      <v-divider class="my-4" />
      <div class="text-center">
        <v-btn :class="getTextColor" text @click="performRedirect">{{
          redirectText
        }}</v-btn>
      </div>
    </template>
  </v-snackbar>
</template>

<script>
import { setItem } from "@/lib/calendesk-js-library/tools/localStorageManager";

export default {
  name: "Snackbar",
  data() {
    return {
      show: false,
      text: "",
      color: "success",
      timeout: 5000,
      closable: true,
      notificationId: null,
      icon: null,
      redirect: null,
      redirectText: null,
    };
  },
  computed: {
    getTextColor() {
      if (this.color === "success") {
        return "green--text";
      }

      if (this.color === "red-light") {
        return "danger--text";
      }

      if (this.color === "orange") {
        return "white--text";
      }

      return "primary";
    },
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "SET_SNACKBAR") {
        this.text = state.snackbar.text;
        this.color = state.snackbar.color;
        this.timeout = state.snackbar.timeout;
        this.closable = state.snackbar.closable;
        this.notificationId = state.snackbar.notificationId;
        this.icon = state.snackbar.icon;
        this.redirect = state.snackbar.redirect;
        this.redirectText = state.snackbar.redirectText;
        this.show = true;
      }
    });
  },
  methods: {
    close() {
      this.show = false;

      if (this.notificationId) {
        setItem(
          this.notificationId,
          this.$moment().format(this.$helpers.dateTimeFormat),
          false,
          true
        );
      }
    },
    performRedirect() {
      this.close();

      let data = this.redirect;
      if (typeof this.redirect === "string") {
        data = { name: this.redirect };
      }
      this.$router.push(data);
    },
  },
};
</script>
