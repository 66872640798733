import { RouteConfig } from 'vue-router'
import BuilderIndex from '../views/BuilderIndex.vue'
import Home from '../views/Home.vue'
import Creator from '@/views/Creator.vue'
import CreatorIndex from '@/views/CreatorIndex.vue'

const routes: Array<RouteConfig> = [
  {
    path: '/:locale/creator/',
    component: CreatorIndex,
    children: [
      {
        path: '/',
        name: 'creator',
        component: Creator
      }
    ]
  },
  {
    path: '/:locale/builder/',
    component: BuilderIndex,
    children: [
      {
        path: '/',
        name: 'home',
        component: Home
      },
      {
        path: ':draft_id',
        name: 'start',
        component: Home
      }
    ]
  }
]

export default routes
