
















import Vue from 'vue'
import Dialog from '@/calendesk/models/Dialog'
import dialogTypes from '@/components/dialogs/dialogTypes'
import dialogSize from '@/lib/calendesk-js-library/components/dialogs/dialogSize'
import { mapActions, mapGetters } from 'vuex'
import { successNotification } from '@/lib/calendesk-js-library/tools/notification'
import { PageType } from '@/calendesk/models/BuilderTypes'
import { pushEvent } from '@/lib/calendesk-js-library/tools/helpers'
export default Vue.extend({
  name: 'PreviewBar',
  data () {
    return {
      isLoading: false
    }
  },
  created () {
    if (!this.defaultDomain) {
      this.isLoading = true
      this.fetchDomains().finally(() => {
        this.isLoading = false
      })
    }
  },
  computed: {
    ...mapGetters({
      defaultDomain: 'domain/getDefaultDomain',
      currentPage: 'builder/getCurrentPage'
    }),
    subpage (): string {
      if (this.currentPage && this.currentPage.type !== PageType.MAIN_PAGE) {
        return '/' + this.currentPage.route
      }

      return '/'
    }
  },
  methods: {
    ...mapActions({
      openDialog: 'dialog/open',
      fetchDomains: 'domain/fetch'
    }),
    openDomainDialogHandle () {
      this.openDialog(new Dialog(true, dialogTypes.BUILDER_DOMAIN, this.$trans('domain_dialog_title'), dialogSize.LARGE))
    },
    copyDomain () {
      this.$helpers.copyToClipboard('https://' + this.defaultDomain.domain + this.subpage)
      successNotification('copied_to_clipboard')
      pushEvent('wbCopyDomain')
    }
  }
})
