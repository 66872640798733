import { SidebarDetailsType } from '@/builder/sidebar/sidebarDetails/SidebarDetailsType'

export default class SidebarDetails {
  public open: boolean
  public type: SidebarDetailsType
  public data: Record<string, any>

  constructor (open = false, type = 0, data = {}) {
    this.open = open
    this.type = type
    this.data = data
  }
}
