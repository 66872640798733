import { WebsiteElementType } from '@/calendesk/models/BuilderTypes'

export default class WebsiteElementModel {
  public id: WebsiteElementType
  public imageUrl: string
  public name: string
  public tooltip: string

  public constructor (id: WebsiteElementType, imageUrl: string, name: string, tooltip: string) {
    this.id = id
    this.imageUrl = imageUrl
    this.name = name
    this.tooltip = tooltip
  }
}
