import Section from './DTO/Response/Section'
import Draft from '@/calendesk/models/DTO/Response/Draft'

export default class SectionToUpdate {
  public configuration: Record<string, any>;
  public section: Section;
  public draft: Draft

  constructor (section: Section, draft: Draft, configuration: Record<string, any>) {
    this.configuration = configuration
    this.draft = draft
    this.section = section
  }
}
