import { ActionTree } from 'vuex'
import { AuthState } from './types'
import { RootState } from '../../types'
import api from '@/lib/calendesk-js-library/api/api'
import { plainToClass } from 'class-transformer'
import User from '@/calendesk/models/DTO/Response/User'
import { closeApp } from '@/router/guards/guardHelpers'

export const actions: ActionTree<AuthState, RootState> = {
  fetchUser ({ commit }): Promise<User> {
    return new Promise((resolve, reject) => {
      api.usersData().then((response: any) => {
        const user = plainToClass(User, response.data)
        commit('SET_USER', user)
        resolve(user)
      }).catch((error: any) => {
        reject(error)
      })
    })
  },
  logout () {
    return api.logout().finally(() => {
      closeApp()
    })
  }
}
