import log from "@/lib/calendesk-js-library/tools/log";
import store from "@/store/index";

export const trans = (key, attributes) => {
  const translations = { ...store.state.setup.translations };

  let trans = translations[key];

  if (trans) {
    if (attributes) {
      Object.keys(attributes).forEach((value) => {
        trans = trans.replace(`@{${value}}`, attributes[value]);
      });
    }

    return trans;
  }

  log.warn("Translation missing!", key);
  return key;
};
