import { mapActions, mapGetters } from 'vuex'
import SectionImage from '../../calendesk/models/DTO/Response/SectionImage'
import { debounce } from 'ts-debounce'
import store from '@/store'
import { DraftActionsType } from '@/calendesk/models/DraftActionsType'
import { DraftConfiguration } from '@/calendesk/models/DraftConfiguration'
import { DraftConfigurationType } from '@/calendesk/models/BuilderTypes'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/builder/sections/mixins/DraftElement'
import { SectionImageType } from '@/calendesk/models/DTO/Response/SectionImageType'
import SectionToUpdate from '@/calendesk/models/SectionToUpdate'
import { v4 as uuidv4 } from 'uuid'

export default mixins(DraftElement).extend({
  data () {
    return {
      configuration: Array<DraftConfiguration>(),
      tools: Array<DraftConfigurationType>(),
      mode: DraftActionsType.CONFIGURATION,
      elementsRequireLogin: [
        'wb_login_modal__external_configuration__',
        'wb_sign_up_modal__external_configuration__',
        'wb_forgotten_password_modal__external_configuration__',
        'wb_forgotten_password_confirmation_modal__external_configuration__'
      ]
    }
  },
  computed: {
    ...mapGetters({
      draft: 'builder/getDraft',
      selectedSection: 'builder/getSelectedSection',
      selectedExternalConfiguration: 'builder/getSelectedExternalConfiguration'
    }),
    singleImageItems (): Array<SectionImage> {
      return this.selectedSection.images.filter((image: SectionImage) => image.type === SectionImageType.IMAGE)
    },
    portfolioImages (): Array<SectionImage> {
      return this.selectedSection.images.filter((image: SectionImage) => image.type === SectionImageType.PORTFOLIO)
    },
    internalImageItems (): Array<SectionImage> {
      return this.selectedSection.images.filter((image: SectionImage) => image.type === SectionImageType.INTERNAL)
    },
    usesPortfolioImages (): boolean {
      return Boolean(this.selectedSection.configuration.has_portfolio_images)
    },
    hasInternalImages (): boolean {
      return Boolean(this.selectedSection.configuration.has_internal_images)
    },
    hasInternalHtmlContent (): boolean {
      return Boolean(this.selectedSection.configuration.has_internal_html_content)
    },
    hasSectionFeatureSubtitle (): boolean {
      return Boolean(this.selectedSection.configuration.has_section_feature_subtitle)
    },
    hideSectionFeatureImage (): boolean {
      return Boolean(this.selectedSection.configuration.wb_image_hide__checkbox__)
    }
  },
  watch: {
    draft () {
      this.refreshConfiguration(this.tools)
    },
    selectedSection () {
      this.refreshConfiguration(this.tools)
    },
    selectedExternalConfiguration () {
      this.refreshConfiguration(this.tools)
    }
  },
  mounted () {
    this.refreshConfiguration(this.tools)
  },
  methods: {
    ...mapActions({
      openSidebarDetails: 'sidebar/openSidebarDetails'
    }),
    updateGeneralConfiguration (key: string, value: null | string | boolean | Array<string> | Array<Record<string, any>> | Record<string, any>, immediately = false) {
      const result = {
        [key]: value
      }
      immediately ? store.dispatch('builder/updateDraftConfigurationProperty', result) : this.debounceUpdateDraftConfigurationProperty(result)
    },
    updateConfigurationProperty (key: string, value: null | string | boolean | Array<string> | Array<Record<string, any>> | Record<string, any>, immediately = false) {
      const result = {
        [key]: value
      }
      if (this.mode === DraftActionsType.CONFIGURATION) {
        immediately ? store.dispatch('builder/updateDraftConfigurationProperty', result) : this.debounceUpdateDraftConfigurationProperty(result)
      } else if (this.mode === DraftActionsType.EXTERNAL_CONFIGURATION) {
        immediately ? store.dispatch('builder/updateDraftExternalConfigurationProperty', result) : this.debounceUpdateDraftExternalConfigurationProperty(result)
      } else if (this.mode === DraftActionsType.SECTION) {
        const sectionToUpdate = new SectionToUpdate(this.selectedSection, this.draft, result)
        immediately ? store.dispatch('builder/updateSectionConfigurationProperty', sectionToUpdate) : this.debounceUpdateSectionConfigurationProperty(sectionToUpdate)
      }
    },
    updateConfigurationPropertyForSelect (key: string, value: any, selected: any) {
      value.value = selected.value
      this.updateConfigurationProperty(key, value, true)
    },
    debounceUpdateSectionConfigurationProperty: debounce(function (result) {
      store.dispatch('builder/updateSectionConfigurationProperty', result)
    }, 1000),
    debounceUpdateDraftConfigurationProperty: debounce(function (result) {
      store.dispatch('builder/updateDraftConfigurationProperty', result)
    }, 1000),
    debounceUpdateDraftExternalConfigurationProperty: debounce(function (result) {
      store.dispatch('builder/updateDraftExternalConfigurationProperty', result)
    }, 1000),
    refreshConfiguration (tools: Array<DraftConfigurationType>) {
      this.configuration = Array<DraftConfiguration>()
      tools.forEach((tool: DraftConfigurationType) => {
        let configurationRequested = null
        if (this.mode === DraftActionsType.CONFIGURATION) {
          configurationRequested = this.draft.configuration
        } else if (this.mode === DraftActionsType.EXTERNAL_CONFIGURATION) {
          configurationRequested = this.selectedExternalConfiguration
        } else if (this.mode === DraftActionsType.SECTION) {
          configurationRequested = this.selectedSection.configuration
        }

        for (const property in configurationRequested) {
          if (this.isLoginHidden && this.elementsRequireLogin.includes(property)) {
            continue
          }

          if (property.includes(`__${tool}__`)) {
            this.configuration.push({
              type: tool,
              key: property,
              trans: property.split(`__${tool}`)[0],
              value: configurationRequested[property],
              uuid: (this.selectedSection && this.selectedSection.uuid) ? this.selectedSection.uuid : uuidv4()
            })
          }
        }
      })
    }
  }
})
