<template>
  <v-alert
    :dense="dense"
    :color="color"
    :class="getClass"
    :dismissible="dismissible"
  >
    <slot v-if="html">
      <span v-html="html" />
    </slot>
    <slot v-else />
    <template #prepend>
      <v-icon :color="iconColor" class="info-icon">
        {{ icon }}
      </v-icon>
    </template>
  </v-alert>
</template>

<script>
export default {
  name: "CalendeskInformationMessage",
  props: {
    html: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "accent",
    },
    iconColor: {
      type: String,
      default: "blue-light-2",
    },
    icon: {
      type: String,
      default: "$info",
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    additionalClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    getClass() {
      return this.additionalClass;
    },
  },
};
</script>

<style lang="scss" scoped>
.info-icon {
  margin-right: 10px;
  align-self: flex-start;
}
</style>
