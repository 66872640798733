import { WebsiteStyleType } from '@/calendesk/models/BuilderTypes'
import { hexToRgba } from '@/lib/calendesk-js-library/tools/helpers'
import StoreController from '@/calendesk/controllers/StoreController'
import Draft from '@/calendesk/models/DTO/Response/Draft'

export default class WebsiteStyleModel {
  public id: WebsiteStyleType
  public imageUrl: string | null
  public name: string | null
  public primaryColor: string
  public bgColor: string
  public textColor: string
  public bg2Color: string
  public text2Color: string
  public isDarkMode: boolean

  public constructor (
    id: WebsiteStyleType,
    imageUrl: string | null,
    name: string | null,
    primaryColor: string,
    bgColor: string,
    textColor: string,
    bg2Color: string,
    text2Color: string,
    isDarkMode: boolean) {
    this.id = id
    this.imageUrl = imageUrl
    this.name = name
    this.primaryColor = primaryColor
    this.bgColor = bgColor
    this.textColor = textColor
    this.bg2Color = bg2Color
    this.text2Color = text2Color
    this.isDarkMode = isDarkMode
  }

  public static isDark (hexColor: string): boolean {
    const rgb = hexToRgba(hexColor, 1, true)
    const o = Math.round((((rgb[0] as number) * 299) + ((rgb[1] as number) * 587) + ((rgb[2] as number) * 114)) / 1000)
    return (o <= 140)
  }

  public static getAvailableLocale (): string {
    let locale = StoreController.appConfiguration()?.language

    if (locale !== 'pl') {
      locale = 'en'
    }

    return locale
  }

  public static getModelForDraft (draft: Draft) {
    const businessTypeId = draft.configuration.business_type_id as WebsiteStyleType

    return new WebsiteStyleModel(
      businessTypeId,
      null,
      null,
      draft.configuration.wb_color_primary__color__,
      draft.configuration.wb_color_bg__color__,
      draft.configuration.wb_color_text__color__,
      draft.configuration.wb_color_bg_2__color__,
      draft.configuration.wb_color_text_2__color__,
      draft.configuration.wb_dark_mode__checkbox__
    )
  }
}
