const getDefaultState = () => ({
  instance: {
    open: false,
    type: 0,
    data: null,
  },
});

const state = getDefaultState();
const getters = {
  getInstance: (state) => state.instance,
};
const actions = {
  open({ commit }, confirmDialog) {
    commit("SET_CONFIRM_DIALOG", {
      open: true,
      data: null,
      ...confirmDialog,
    });
  },
  close({ commit }, data) {
    commit("SET_CONFIRM_DIALOG", {
      open: false,
      data,
    });
  },
};
const mutations = {
  SET_CONFIRM_DIALOG(state, instance) {
    state.instance = instance;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
