import DummySectionImage from '@/calendesk/models/DummySectionImage'
import { Expose, plainToClass } from 'class-transformer'
import { SectionImageType } from '@/calendesk/models/DTO/Response/SectionImageType'
import store from '@/store'

export default class SectionImage {
  public id!: number | null
  public slug!: string
  public type!: SectionImageType

  @Expose({ name: 'section_id' })
  public sectionId!: number | null

  @Expose({ name: 'user_image_id' })
  public userImageId!: number | null

  public name!: string | null

  public static createFromDummyImages (images: Array<DummySectionImage>): Array<SectionImage> {
    const result = Array<SectionImage>()

    images.forEach((dummyImage) => {
      result.push(SectionImage.createFromDummyImage(dummyImage))
    })

    return result
  }

  public static createFromDummyImage (dummyImage: DummySectionImage): SectionImage {
    return plainToClass(SectionImage, {
      id: null,
      slug: dummyImage.slug,
      type: dummyImage.type,
      section_id: null,
      user_image_id: null,
      name: dummyImage.name
    })
  }

  public getUrl (): string | null {
    if (this.name && store.getters['setup/getAppConfiguration']) {
      return store.getters['setup/getAppConfiguration'].imageBaseUrl + this.name
    }

    return null
  }
}
