import { trans } from "@/lib/calendesk-js-library/prototypes/trans";

export const notificationTypes = {
  email: 1,
  sms: 2,
  push: 3,

  inputValues() {
    return [
      {
        text: trans("notification_type_1"),
        value: notificationTypes.email,
      },
      {
        text: trans("notification_type_2"),
        value: notificationTypes.sms,
      },
      {
        text: trans("notification_type_3"),
        value: notificationTypes.push,
      },
    ];
  },
};
