import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from './types'
import { auth } from './modules/auth/index'
import { cache } from './modules/cache/index'
import { setup } from './modules/setup/index'
import { builder } from './modules/builder/index'
import { sidebar } from './modules/sidebar/index'
import { employee } from './modules/employee/index'
import { simpleStore } from './modules/simpleStore/index'
import { subscription } from './modules/subscription/index'
import { service } from './modules/service/index'
import { category } from './modules/category/index'
import { domain } from './modules/domain/index'
import { getters } from './modules/common/getters'
import { mutations } from './modules/common/mutations'
import { actions } from './modules/common/actions'
import assets from '@/lib/calendesk-js-library/store/assets'
import dialog from '@/lib/calendesk-js-library/store/dialog'
import confirmDialog from '@/lib/calendesk-js-library/store/confirmDialog'
import loader from '@/lib/calendesk-js-library/store/loader'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state: new RootState(),
  getters,
  mutations,
  actions,
  modules: {
    setup,
    auth,
    cache,
    builder,
    sidebar,
    assets,
    dialog,
    confirmDialog,
    employee,
    simpleStore,
    subscription,
    loader,
    service,
    category,
    domain
  }
}

export default new Vuex.Store<RootState>(store)
