import Snackbar from '@/calendesk/models/Snackbar'
import { RootState } from '@/store/types'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<RootState> = {
  SET_SNACKBAR (state, payload: Snackbar) {
    state.snackbar = payload
  },
  SET_IS_SENDING (state, payload: boolean) {
    state.isSending = payload
  }
}
