import { SidebarType } from '@/calendesk/models/BuilderTypes'

export default class Sidebar {
  public open: boolean
  public type: SidebarType
  public title: string

  constructor (open = false, type = 0, title = '') {
    this.open = open
    this.type = type
    this.title = title
  }
}
