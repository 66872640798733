<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="100px"
  >
    <template #activator="{ on, attrs }">
      <template v-if="icon">
        <v-tooltip bottom>
          <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon v-bind="tooltipAttrs" v-on="tooltipOn">
                {{ icon }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ tooltipLabel || $trans("color") }}</span>
        </v-tooltip>
      </template>
      <template v-else>
        <div v-if="hideInput" class="no-input">
          <div class="no-input__title mb-2">
            <span class="text-subtitle-1">{{ label }}</span>
          </div>
          <div class="no-input__body d-flex">
            <div
              class="no-input__body--color-box mr-4"
              :style="{ background: color }"
              v-on="on"
            />
            <div
              v-if="error"
              class="no-input__body--alert d-flex flex-column justify-center pl-2"
              :style="{ background: $helpers.hexToRgba('#DC143C', 0.1) }"
            >
              <p class="mb-0">
                {{ $trans("cp_alert_p1") }}
              </p>
              <p class="mb-0">
                {{ $trans("cp_alert_p2") }}
              </p>
            </div>
          </div>
        </div>
        <v-text-field
          v-else
          :label="label"
          readonly
          outlined
          hide-details
          v-on="on"
        >
          <template #prepend-inner>
            <v-icon :color="color" v-on="on"> $dot</v-icon>
          </template>
          <template #append>
            <v-icon v-on="on"> $palette</v-icon>
          </template>
        </v-text-field>
      </template>
    </template>
    <v-card>
      <v-color-picker v-model="color" class="elevation-0" :mode="mode" />
      <v-card-actions class="fill-width flex-row justify-end">
        <v-btn text @click="handleCancelButton">
          {{ $trans("cancel") }}
        </v-btn>
        <v-btn color="primary" text @click="menu = false">
          {{ $trans("select") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "CColorPicker",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: "#000000",
    },
    mode: {
      type: String,
      default: "hexa",
    },
    label: {
      type: String,
      default: "Color",
    },
    hideInput: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    tooltipLabel: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      menu: false,
      color: this.value,
      error: false,
    };
  },
  watch: {
    value() {
      this.color = this.value;
    },
    menu() {
      if (!this.menu) this.$emit("input", this.color);
    },
  },
  methods: {
    handleCancelButton() {
      this.color = this.value;
      this.menu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.fill-width {
  width: 100%;
}

.no-input {
  width: 100%;
}

.no-input__body {
  width: 100%;
  height: 70px;
}

.no-input__body--color-box {
  min-width: 70px;
  min-height: 70px;
  cursor: pointer;
  border: 1px solid #d0d0d0;
}

.no-input__body--alert {
  width: 100%;
  height: 100%;
  border-left: 3px solid #dc143c;
  color: #dc143c;
}
</style>
