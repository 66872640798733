import sharedConfirmDialogTypes from '@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes'

const confirmDialogList: Array<Record<string, any>> = [
  {
    type: sharedConfirmDialogTypes.COMMON,
    content: () => import('@/lib/calendesk-js-library/components/confirmDialogs/components/CommonDialog.vue')
  },
  {
    type: sharedConfirmDialogTypes.REMOVE_ASSET,
    content: () => import('@/lib/calendesk-js-library/components/confirmDialogs/components/RemoveAssetDialog.vue')
  },
  {
    type: sharedConfirmDialogTypes.CHANGE_NAME_ASSET,
    content: () => import('@/lib/calendesk-js-library/components/confirmDialogs/components/ChangeNameAsset.vue')
  }
]

export default confirmDialogList
