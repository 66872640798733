import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { SetupState } from './types'
import { RootState } from '../../types'

export const state: SetupState = {

  // configuration and appConfiguration are duplicated, configuration is required by legacy $config method used in lib.
  // We should use appConfiguration everywhere as it's declared Configuration class object.
  configuration: {}, // IMPORTANT: don't use it if not necessary. Use appConfiguration.
  appConfiguration: null,
  adminConfiguration: null,
  translations: null,
  preloader: true,
  regulations: null,
  privacyPolicy: null,
  versionCheckedAt: null,
  currentTenantPlanSlug: null,
  currentTenantPlan: null,
  referrer: process.env.VUE_APP_DEFAULT_REFERRER,
  tenant: null
}

const namespaced = true

export const setup: Module<SetupState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
