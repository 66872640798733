



























import Section from '@/calendesk/models/DTO/Response/Section'
import PreviewSection from '@/calendesk/models/PreviewSection'
import { mapActions, mapGetters } from 'vuex'
import sectionComponents from '@/builder/sections/SectionComponents'
import Page from '@/calendesk/models/DTO/Response/Page'
import SectionComponent from '@/calendesk/models/SectionComponent'
import cloneClassObject from '@/calendesk/tools/cloneClassObject'
import CSelectedSection from '@/components/CSelectedSection.vue'
import { MoveDirection, SectionType, SidebarType } from '@/calendesk/models/BuilderTypes'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/builder/sections/mixins/DraftElement'
import SectionCategory from '@/calendesk/models/SectionCategory'
import Empty from '@/builder/sections/empty/Empty.vue'
import Sidebar from '@/calendesk/models/Sidebar'
import SectionToMove from '@/calendesk/models/SectionToMove'

export default mixins(DraftElement).extend({
  name: 'PreviewContent',
  components: { Empty, CSelectedSection },
  data () {
    return {
      sectionTypes: SectionType
    }
  },
  computed: {
    ...mapGetters({
      currentPage: 'builder/getCurrentPage',
      navbar: 'builder/getNavbar',
      footer: 'builder/getFooter',
      sidebar: 'sidebar/getSidebar'
    }),
    previewSections () {
      const previewSections: Array<PreviewSection> = []

      if (this.navbar && !this.isHeaderHidden) {
        const navbarPreview = this.createPreviewSection(this.navbar)
        if (navbarPreview) {
          previewSections.push(navbarPreview)
        }
      }

      if (this.currentPage && Array.isArray(this.currentPage.sections)) {
        const currentPage: Page = cloneClassObject(this.currentPage)

        if (currentPage.sections) {
          currentPage.sections.sort((sectionA: Section, sectionB: Section) => sectionA.configuration.wb_position - sectionB.configuration.wb_position)

          currentPage.sections.forEach((section: Section) => {
            if (this.isLoginHidden && this.sectionsRequiredLogin.includes(section.configuration.component_id)) {
              return
            }

            const previewSection = this.createPreviewSection(section)

            if (previewSection) {
              previewSections.push(previewSection)
            }
          })
        }
      }

      if (this.footer && !this.isFooterHidden) {
        const footerPreview = this.createPreviewSection(this.footer)
        if (footerPreview) {
          previewSections.push(footerPreview)
        }
      }

      return previewSections
    }
  },
  watch: {
    previewSections () {
      if (this.previewSections && this.previewSections.length === 0 && this.currentPage) {
        const emptySectionData = Section.createEmptySection(this.currentPage.id, 0)
        const sectionToMove = new SectionToMove(emptySectionData, MoveDirection.DOWN)
        this.createEmptySection(sectionToMove)

        this.openSidebar(new Sidebar(true, SidebarType.ADD_SECTION, this.$trans('wb_select_new_section')))
      }
    }
  },
  methods: {
    ...mapActions({
      openSidebar: 'sidebar/openSidebar',
      createEmptySection: 'builder/createEmptySection'
    }),
    createPreviewSection (section: Section): PreviewSection | null {
      const sectionType = sectionComponents.find((_: SectionCategory) => _.type === section.type)

      if (sectionType) {
        const type = sectionType.components.find((_: SectionComponent) => _.componentId === section.configuration.component_id)
        if (type) {
          return new PreviewSection(section, type.component)
        }
      }

      return null
    }
  }
})
