import { GetterTree } from 'vuex'
import { RootState } from '../../types'
import { DomainSelect } from './types'
import Domain from '@/calendesk/models/DTO/Response/Domain'

export const getters: GetterTree<DomainSelect, RootState> = {
  getDomains (state): Domain[] | null {
    return state.domains
  },
  getDefaultDomain (state): Domain | null {
    return state.defaultDomain
  }
}
