







import Vue from 'vue'
import AdminBanner from '@/views/components/AdminBanner.vue'
export default Vue.extend({
  name: 'AppView',
  components: { AdminBanner }
})
