import { GetterTree } from 'vuex'
import { RootState } from '../../types'
import { ServiceState } from './types'
import Service from '@/calendesk/models/DTO/Response/Service'

export const getters: GetterTree<ServiceState, RootState> = {
  getServices (state): Service[] | null {
    return state.services
  }
}
