
























































































































































import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { PageType, SidebarType } from '@/calendesk/models/BuilderTypes'
import Dialog from '@/calendesk/models/Dialog'
import Page from '@/calendesk/models/DTO/Response/Page'
import Sidebar from '@/calendesk/models/Sidebar'
import dialogSize from '@/lib/calendesk-js-library/components/dialogs/dialogSize'
import dialogTypes from '@/components/dialogs/dialogTypes'
import sharedConfirmDialogTypes from '@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes'
import { errorNotification, successNotification } from '@/lib/calendesk-js-library/tools/notification'
import ChangePreviewSize from '@/components/ChangePreviewSize.vue'
import { closeApp } from '@/router/guards/guardHelpers'
import api from '@/lib/calendesk-js-library/api/api'
import { pushEvent } from '@/lib/calendesk-js-library/tools/helpers'

export default Vue.extend({
  name: 'AppBar',
  components: { ChangePreviewSize },
  data () {
    return {
      isLoggingOut: false
    }
  },
  computed: {
    ...mapGetters({
      draftPages: 'builder/getDraftPages',
      currentPage: 'builder/getCurrentPage'
    }),
    menu (): Array<Record<string, string | number>> {
      return [
        {
          type: SidebarType.GENERAL,
          title: this.$trans('wb_general'),
          icon: '$cog'
        },
        {
          type: SidebarType.PAGES,
          title: this.$trans('wb_pages'),
          icon: '$bookmarks'
        },
        {
          type: SidebarType.STYLE,
          title: this.$trans('wb_style'),
          icon: '$palette'
        }
      ]
    },
    filteredPages (): Array<Page> {
      if (!this.draftPages) return []

      return this.draftPages
        .filter((page: Page) => page.type === 'page' || page.type === PageType.MAIN_PAGE)
        .sort((a: Page, b: Page) => {
          // Ensure the main page comes first
          if (a.type === PageType.MAIN_PAGE) return -1
          if (b.type === PageType.MAIN_PAGE) return 1

          // Then sort by configuration.wb_position numerically
          const positionA = a.configuration?.wb_position ?? Number.MAX_VALUE
          const positionB = b.configuration?.wb_position ?? Number.MAX_VALUE

          return positionA - positionB
        })
    },
    page: {
      get (): Page {
        return this.currentPage
      },
      set (value: Page): void {
        this.setCurrentPage(value)
        this.closeSidebar()
        this.closeSidebarDetails()
      }
    }
  },
  methods: {
    ...mapMutations({
      setCurrentPage: 'builder/SET_CURRENT_PAGE'
    }),
    ...mapActions({
      openSidebar: 'sidebar/openSidebar',
      openDialog: 'dialog/open',
      closeSidebar: 'sidebar/closeSidebar',
      closeSidebarDetails: 'sidebar/closeSidebarDetails',
      openConfirmDialog: 'confirmDialog/open',
      closeConfirmDialog: 'confirmDialog/close',
      setCommonDialogLoader: 'loader/setCommonDialogLoader',
      publishDraft: 'builder/publishDraft',
      adminConfiguration: 'setup/getAdminConfiguration'
    }),
    openMenu (button: any): void {
      this.openSidebar(new Sidebar(true, button.type, button.title))
    },
    addNewPageClicked () {
      this.openDialog(new Dialog(true, dialogTypes.BUILDER_ADD_PAGE, this.$trans('wb_add_new_page'), dialogSize.LARGE))
    },
    showIntegrationsClicked () {
      this.openDialog(new Dialog(true, dialogTypes.ADD_CODE_TO_WEBSITE, null, dialogSize.LARGE))
    },
    logout () {
      this.isLoggingOut = true

      pushEvent('wbLogout')
      api.logout().finally(() => {
        closeApp()
      })
    },
    closeApp,
    publishDraftAction () {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        title: this.$trans('wb_publish_confirm_dialog_title'),
        warning: this.$trans('wb_publish_confirm_dialog_text'),
        importantInformation: null,
        confirmationText: this.$trans('publish'),
        confirmAction: () => {
          this.setCommonDialogLoader(true)
          this.publishDraft().then(() => {
            successNotification('wb_publish_success_text')
            this.setCommonDialogLoader(false)
            this.closeConfirmDialog()
          }).catch((error) => {
            errorNotification('error_occurred', error)
            this.setCommonDialogLoader(false)
            this.closeConfirmDialog()
          })
        }
      })
    }
  }
})
