import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { SidebarState } from './types'
import { RootState } from '../../types'
import Sidebar from '@/calendesk/models/Sidebar'
import SidebarDetails from '@/calendesk/models/SidebarDetails'

export const state: SidebarState = {
  sidebar: new Sidebar(false),
  sidebarDetails: new SidebarDetails()
}

const namespaced = true

export const sidebar: Module<SidebarState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
