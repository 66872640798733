import SectionImage from './SectionImage'
import { SectionType } from '@/calendesk/models/BuilderTypes'
import { Expose, plainToClass, Type } from 'class-transformer'
import { v4 as uuidv4 } from 'uuid'

export default class Section {
  public id!: number | null
  public uuid!: string | null
  public type!: SectionType

  @Expose({ name: 'page_id' })
  public pageId!: number | null

  public configuration!: Record<string, any>

  @Type(() => SectionImage)
  public images!: Array<SectionImage>

  public static createEmptySection (pageId: number | null, position: number) {
    return plainToClass(Section, {
      id: 0,
      type: SectionType.EMPTY,
      pageId: pageId,
      configuration: { component_id: 1, wb_position: position },
      images: [],
      uuid: uuidv4()
    })
  }

  public constructor (
    type: SectionType,
    configuration: Record<string, any>,
    images: Array<SectionImage>,
    id: number | null = null,
    uuid: string | null = null) {
    this.type = type
    this.configuration = configuration
    this.images = images
    this.id = id
    this.uuid = uuid
  }
}
