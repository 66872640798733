import Vue from "vue";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import momentDurationFormat from "moment-duration-format";

momentDurationFormat(moment);

Vue.use(VueMoment, {
  moment,
});

export default moment;
