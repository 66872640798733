<template>
  <v-snackbar
    v-model="isVisible"
    color="accent"
    multi-line
    timeout="-1"
    vertical
  >
    <div class="mt-4">
      <v-icon class="mr-2" color="blue-light-2"> $info</v-icon>
      <span class="black--text">{{ $trans("new_content_available") }}</span>
      <v-btn block text color="primary" class="mt-2" @click="clickAction">
        {{ $trans("refresh_button_title") }}
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import api from "@/lib/calendesk-js-library/api/api";
import compareVersionNumbers from "@/lib/calendesk-js-library/tools/compareVersionNumbers";

export default {
  name: "ReloadSnackbar",
  props: {
    versionType: {
      type: String,
      default: "admin_version",
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      versionCheckedAt: "setup/getVersionCheckedAt",
    }),
  },
  created() {
    // Schedule version check every 30 minutes
    setInterval(this.fire, 1800000);
    this.fire();
  },
  methods: {
    ...mapActions({
      setVersionCheckedAt: "setup/setVersionCheckedAt",
    }),
    fire() {
      const now = this.$moment();
      const checkedAt = this.versionCheckedAt
        ? this.$moment(this.versionCheckedAt)
        : now;
      if (
        !this.versionCheckedAt ||
        checkedAt.add(10, "minutes").isBefore(now)
      ) {
        api.healthyCentral().then(({ data }) => {
          const fromHtml = compareVersionNumbers(
            data[this.versionType],
            window.APP_VERSION
          );
          const fromJs = compareVersionNumbers(
            data[this.versionType],
            window.calendesk.version
          );
          if (fromHtml > 0 || fromJs > 0) {
            this.isVisible = true;
          }

          this.setVersionCheckedAt(this.$moment());
        });
      }
    },
    clickAction() {
      location.reload();
    },
  },
};
</script>
