import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { DomainSelect } from './types'
import { RootState } from '../../types'

export const state: DomainSelect = {
  domains: null,
  defaultDomain: null
}

const namespaced = true

export const domain: Module<DomainSelect, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
