import { Module } from 'vuex'
import { getters } from './getters'
import { actions } from './actions'
import { mutations } from './mutations'
import { BuilderState } from './types'
import { RootState } from '../../types'
import { ChangeStep } from '@/calendesk/models/ChangeStep'
import { PreviewSizeType } from '@/calendesk/models/BuilderTypes'

export const state: BuilderState = {
  draft: null,
  currentPage: null,
  selectedSection: null,
  steps: Array<ChangeStep>(),
  currentStepIndex: 0,
  selectedExternalConfiguration: null,
  previewType: PreviewSizeType.DESKTOP,
  businessType: null,
  websiteStyleModel: null
}

const namespaced = true

export const builder: Module<BuilderState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
