import { MoveDirection } from './BuilderTypes'
import Section from './DTO/Response/Section'

export default class SectionToMove {
  public moveDirection: MoveDirection;
  public section: Section;

  constructor (section: Section, moveDirection: MoveDirection) {
    this.moveDirection = moveDirection
    this.section = section
  }
}
