import { EmptySectionType } from '@/calendesk/models/BuilderTypes'
import SectionComponent from '@/calendesk/models/SectionComponent'

const components: Array<SectionComponent> = [
  {
    componentId: EmptySectionType.EMPTY,
    component: () => import('./Empty.vue')
  }
]

export default components
