import BusinessCardModel from '@/calendesk/models/BusinessCardModel'
import WebsiteElementModel from '@/calendesk/models/WebsiteElementModel'
import WebsiteStyleModel from '@/calendesk/models/WebsiteStyleModel'
import DraftStateController, { DRAFT_VERSION } from '@/calendesk/controllers/DraftStateController'
import { PageType, SectionType, WebsiteElementType } from '@/calendesk/models/BuilderTypes'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'
import Page from '@/calendesk/models/DTO/Response/Page'
import Configuration from '@/calendesk/models/DTO/Response/Configuration'
import CreateDraftRequestData from '@/calendesk/models/DTO/Request/CreateDraftRequestData'
import store from '@/store/index'
import api from '@/lib/calendesk-js-library/api/api'
import { errorNotification } from '@/lib/calendesk-js-library/tools/notification'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import RegulationsConfiguration from '@/builder/sections/section/static/regulations/RegulationsConfiguration'
import PrivacyPolicyConfiguration from '@/builder/sections/section/static/privacyPolicy/PrivacyPolicyConfiguration'
import { plainToClass } from 'class-transformer'
import Draft from '@/calendesk/models/DTO/Response/Draft'
import Newsletter3Configuration from '@/builder/sections/section/newsletter/newsletter3/Newsletter3Configuration'
import Flexible1Configuration1 from '@/builder/sections/section/flexible/Flexible1Configuration1'
import Header10Configuration from '@/builder/sections/section/header/header10/Header10Configuration'
import FlexibleGallery1Configuration1 from '@/builder/sections/section/flexibleGallery/FlexibleGallery1Configuration1'
import FlexibleNavbar1Configuration1 from '@/builder/sections/navbar/flexibleNavbar/FlexibleNavbar1Configuration1'
import Footer8Configuration from '@/builder/sections/footer/footer8/Footer8Configuration'
import Footer4Configuration from '@/builder/sections/footer/footer4/Footer4Configuration'
import { BuilderSelectType } from '@/calendesk/models/BuilderSelectType'
import Flexible1Configuration5 from '@/builder/sections/section/flexible/Flexible1Configuration5'
import Other1Configuration from '@/builder/sections/section/other/other1/Other1Configuration'
import CalendarV2Configuration from '@/builder/sections/section/calendars/CalendarV2Configuration'
import Flexible1Configuration6 from '@/builder/sections/section/flexible/Flexible1Configuration6'
import FlexibleReviews2Configuration3 from '@/builder/sections/section/flexibleReview/FlexibleReviews2Configuration3'
import FlexibleForm1Configuration1 from '@/builder/sections/section/flexibleForm/FlexibleForm1Configuration1'
import FlexibleGallery1Configuration6 from '@/builder/sections/section/flexibleGallery/FlexibleGallery1Configuration6'
import FlexibleGallery1Configuration7 from '@/builder/sections/section/flexibleGallery/FlexibleGallery1Configuration7'

export default class DraftCreatorController {
  public businessCardModel: BusinessCardModel
  public websiteElementModels: WebsiteElementModel[]
  public websiteStyleModel: WebsiteStyleModel
  public draftName: string

  private regulationsPage: Page | null = null
  private privacyPolicyPage: Page | null = null
  private draft: Draft | null = null
  private appConfiguration: Configuration

  public constructor (
    businessCardModel: BusinessCardModel,
    websiteElementModels: WebsiteElementModel[],
    websiteStyleModel: WebsiteStyleModel,
    draftName: string,
    appConfiguration: Configuration) {
    this.businessCardModel = businessCardModel
    this.websiteElementModels = websiteElementModels
    this.websiteStyleModel = websiteStyleModel
    this.draftName = draftName
    this.appConfiguration = appConfiguration
  }

  public async createDraft () {
    await store.dispatch('builder/setBusinessType', this.businessCardModel.id)
    await store.dispatch('builder/setWebsiteStyleModel', this.websiteStyleModel)

    const requestData = new CreateDraftRequestData(
      1,
      this.draftName,
      await this.getTemplateData()
    )

    try {
      const response = await api.createWb2Draft(requestData)
      const draft = plainToClass(Draft, response.data)
      localStorage.setItem('draft_id', String(draft.id))
      await this.updateConfigurations(draft)

      return true
    } catch (error) {
      errorNotification(null, error)
    }

    return false
  }

  private async updateConfigurations (draft: Draft) {
    this.draft = draft

    if (this.hasElementType(WebsiteElementType.TERMS_PRIVACY_POLICY)) {
      this.regulationsPage = this.draft.pages.find((page: Page) => page.route === trans('wb_regulations_page_url')) || null
      this.privacyPolicyPage = this.draft.pages.find((page: Page) => page.route === trans('wb_privacy_policy_page_url')) || null
    }

    this.draft.navbar.configuration = this.getNavbarConfiguration()
    this.draft.footer.configuration = this.getFooterConfiguration()
    this.draft.configuration = this.getDraftConfiguration()

    await DraftStateController.getInstance().updateDraft(
      this.draft
    )

    await DraftStateController.getInstance().updateSection(
      this.draft,
      this.draft.navbar
    )

    await DraftStateController.getInstance().updateSection(
      this.draft,
      this.draft.footer
    )

    return Promise.resolve()
  }

  private async getTemplateData (): Promise<Record<string, any>> {
    return Promise.resolve({
      configuration: this.getDraftConfiguration(),
      navbar: this.getNavbarInitialSetup(),
      footer: this.getFooterInitialSetup(),
      pages: await this.getPagesInitialSetup()
    })
  }

  private hasElementType (type: WebsiteElementType): boolean {
    return !!this.websiteElementModels.find((element) => element.id === type)
  }

  private getCreatedPageIds (): Array<string> {
    return this.draft ? this.draft?.pages.map(page => page.uuid) : []
  }

  private getDraftConfiguration (): Record<string, any> {
    return {
      version: DRAFT_VERSION,
      business_type_id: this.businessCardModel.id,
      wb_dark_mode__checkbox__: this.websiteStyleModel.isDarkMode,
      wb_color_primary__color__: this.websiteStyleModel.primaryColor,
      wb_color_bg__color__: this.websiteStyleModel.bgColor,
      wb_color_text__color__: this.websiteStyleModel.textColor,
      wb_color_bg_2__color__: this.websiteStyleModel.bg2Color,
      wb_color_text_2__color__: this.websiteStyleModel.text2Color,
      wb_website_description__long_text__: null,
      wb_custom_code__long_text__: null,
      wb_google_tag_id__text__: null,
      wb_hide_login__checkbox__: !this.hasElementType(WebsiteElementType.LOGIN),
      wb_hide_signup__checkbox__: !this.hasElementType(WebsiteElementType.LOGIN),
      wb_og_image_url__text__: null,
      wb_favicon_url__text__: null,
      wb_hide_booking_employees__checkbox__: false,
      wb_hide_header__checkbox__: false,
      wb_hide_footer__checkbox__: false,
      wb_enable_discounts__checkbox__: false,
      wb_hide_time_zone__checkbox__: false,
      wb_hide_booking_time_to__checkbox__: false,
      wb_newsletter_success_url__url__: null,
      wb_account_activation_success_url__url__: null,
      wb_primary_language__select__: {
        type: BuilderSelectType.LANGUAGE,
        value: this.appConfiguration.language
      },
      login_modal_configuration: {
        id: 1,
        wb_login_subtitle__text__: trans('wb_default_login_text')
      },
      signup_modal_configuration: {
        id: 2,
        wb_show_billing_data__checkbox__: true,
        wb_sign_up_terms_info__checkbox__: true,
        wb_regulations_page__page_id__: this.regulationsPage ? this.regulationsPage.uuid : null,
        wb_privacy_policy_page__page_id__: this.privacyPolicyPage ? this.privacyPolicyPage.uuid : null
      },
      forgotten_password_modal_configuration: {
        id: 3,
        wb_forgotten_password_title__text__: trans('wb_default_forgotten_password_title'),
        wb_forgotten_password_subtitle__long_text__: trans('wb_default_forgotten_password_subtitle')
      },
      forgotten_password_modal_confirmation_configuration: {
        id: 4,
        wb_forgotten_password_modal_confirmation_title__text__: trans('wb_default_forgotten_password_modal_confirmation_title'),
        wb_forgotten_password_modal_confirmation_subtitle__long_text__: trans('wb_default_forgotten_password_modal_confirmation_subtitle')
      }
    }
  }

  private getNavbarInitialSetup (): Record<string, any> {
    return {
      type: SectionType.NAVBAR,
      page_id: null,
      configuration: this.getNavbarConfiguration(),
      images: FlexibleNavbar1Configuration1().images
    }
  }

  private getFooterInitialSetup (): Record<string, any> {
    return {
      type: SectionType.FOOTER,
      configuration: this.getFooterConfiguration(),
      images: []
    }
  }

  private getNavbarConfiguration (): Record<string, any> {
    const navbar = FlexibleNavbar1Configuration1()
    navbar.configuration.wb_page_list__page_ids__ = this.getCreatedPageIds().slice(0, 3)

    return navbar.configuration
  }

  private getFooterConfiguration (): Record<string, any> {
    if (this.hasElementType(WebsiteElementType.TERMS_PRIVACY_POLICY)) {
      const footer = Footer4Configuration()
      const pages = this.getCreatedPageIds()
      footer.configuration.wb_page_list__page_ids__ = pages ? pages.slice(0, 6) : []

      return footer.configuration
    }

    return Footer8Configuration().configuration
  }

  private async getPagesInitialSetup (): Promise<Array<Record<string, any>>> {
    const pages = [
      this.getMainPageInitialSetup()
    ]

    if (this.hasElementType(WebsiteElementType.SERVICES)) {
      pages.push(await this.getServicesPageInitialSetup())
    }

    if (this.hasElementType(WebsiteElementType.ABOUT_US)) {
      pages.push(await this.getAboutUsPageInitialSetup())
    }

    if (this.hasElementType(WebsiteElementType.TEAM)) {
      pages.push(await this.getTeamPageInitialSetup())
    }

    if (this.hasElementType(WebsiteElementType.TERMS_PRIVACY_POLICY)) {
      pages.push(this.getRegulationsPageInitialSetup())
      pages.push(this.getPrivacyPolicyPageInitialSetup())
    }

    return Promise.resolve(pages)
  }

  private async getAboutUsPageInitialSetup () {
    const sections: Array<Record<string, any>> = []

    sections.push(this.getSection(FlexibleReviews2Configuration3(), 1))
    sections.push(this.getSection(Other1Configuration(), 2))
    sections.push(this.getSection(FlexibleForm1Configuration1(), 3))

    if (this.hasElementType(WebsiteElementType.NEWSLETTER)) {
      sections.push(this.getSection(Other1Configuration(), 4))
      sections.push(this.getSection(Newsletter3Configuration(), 5))
    }

    const result = {
      name: trans('wb_employees_page_name'),
      type: PageType.PAGE,
      route: trans('wb_employees_page_url'),
      action: null,
      configuration: {
        wb_position: 65535 * 2
      },
      sections: sections
    }

    return Promise.resolve(result)
  }

  private async getTeamPageInitialSetup () {
    const sections: Array<Record<string, any>> = []

    sections.push(this.getSection(Header10Configuration(), 1))
    sections.push(this.getSection(Other1Configuration(), 2))
    sections.push(this.getSection(Flexible1Configuration6(), 3))
    sections.push(this.getSection(Other1Configuration(), 4))
    sections.push(this.getSection(Flexible1Configuration5(), 5))

    if (this.hasElementType(WebsiteElementType.NEWSLETTER)) {
      sections.push(this.getSection(Other1Configuration(), 6))
      sections.push(this.getSection(Newsletter3Configuration(), 7))
    }

    const result = {
      name: trans('wb_team_page_name'),
      type: PageType.PAGE,
      route: trans('wb_employees_page_url'),
      action: null,
      configuration: {
        wb_position: 65535 * 2
      },
      sections: sections
    }

    return Promise.resolve(result)
  }

  private async getServicesPageInitialSetup () {
    const sections: Array<Record<string, any>> = []

    sections.push(this.getSection(Header10Configuration(), 1))
    sections.push(this.getSection(Other1Configuration(), 2))
    sections.push(this.getSection(FlexibleGallery1Configuration6(), 3))
    sections.push(this.getSection(Other1Configuration(), 4))
    sections.push(this.getSection(FlexibleGallery1Configuration7(), 5))

    if (this.hasElementType(WebsiteElementType.NEWSLETTER)) {
      sections.push(this.getSection(Other1Configuration(), 6))
      sections.push(this.getSection(Newsletter3Configuration(), 7))
    }

    const result = {
      name: trans('wb_services_page_name_2'),
      type: PageType.PAGE,
      route: trans('wb_services_page_url_2'),
      action: null,
      configuration: {
        wb_position: 65535 * 3
      },
      sections: sections
    }

    return Promise.resolve(result)
  }

  private getRegulationsPageInitialSetup () {
    const sections: Array<Record<string, any>> = []

    sections.push(this.getSection(RegulationsConfiguration(), 1))

    return {
      name: trans('wb_regulations_page_name'),
      type: PageType.PAGE,
      route: trans('wb_regulations_page_url'),
      action: null,
      configuration: {
        wb_position: 65535 * 5
      },
      sections: sections
    }
  }

  private getPrivacyPolicyPageInitialSetup () {
    const sections: Array<Record<string, any>> = []

    sections.push(this.getSection(PrivacyPolicyConfiguration(), 1))

    return {
      name: trans('wb_privacy_policy_page_name'),
      type: PageType.PAGE,
      route: trans('wb_privacy_policy_page_url'),
      action: null,
      configuration: {
        wb_position: 65535 * 6
      },
      sections: sections
    }
  }

  private getMainPageInitialSetup () {
    const sections: Array<Record<string, any>> = []

    sections.push(this.getSection(Header10Configuration(), 1))
    sections.push(this.getSection(Other1Configuration(), 2))

    if (this.hasElementType(WebsiteElementType.BOOKING)) {
      sections.push(this.getSection(CalendarV2Configuration(), 3))
    } else {
      sections.push(this.getSection(FlexibleGallery1Configuration1(), 4))
      sections.push(this.getSection(Other1Configuration(), 5))
      sections.push(this.getSection(Flexible1Configuration1(), 6))
    }

    if (this.hasElementType(WebsiteElementType.NEWSLETTER)) {
      sections.push(this.getSection(Other1Configuration(), 7))
      sections.push(this.getSection(Newsletter3Configuration(), 8))
    }

    return {
      name: trans('wb_main_page_name'),
      type: PageType.MAIN_PAGE,
      route: trans('wb_main_page_url'),
      action: null,
      configuration: {
        wb_position: 65535
      },
      sections: sections
    }
  }

  private getSection (sectionConfiguration: SectionConfiguration, position = 1): Record<string, any> {
    sectionConfiguration.configuration.wb_position = 65535 * position

    return {
      type: SectionType.SECTION,
      configuration: sectionConfiguration.configuration,
      images: sectionConfiguration.images
    }
  }
}
