import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import log from "@/lib/calendesk-js-library/tools/log";
import {
  getAccessToken,
  tokenOperations,
} from "@/lib/calendesk-js-library/tools/token";
import { EventBus } from "@/lib/calendesk-js-library/plugins/eventBus";
import store from "@/store";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";

/**
 * Resets the app, clears local storage, and redirects to the locale page.
 */
const resetApp = (message) => {
  log.error(message);
  const locale = localStorage.locale ?? "en";
  localStorage.clear();
  window.location.replace(`/${locale}`);
};

/**
 * Creates and configures the HTTP client.
 */
const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 120000,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
  },
});

/**
 * Logic to refresh authorization tokens when they expire.
 */
export const refreshAuthLogic = (failedRequest) =>
  refreshToken().then((tokenRefreshResponse) => {
    tokenOperations(tokenRefreshResponse.data);
    failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.data.access_token}`;
    return Promise.resolve();
  });

httpClient.interceptors.request.use((request) => {
  request.headers.Authorization = `Bearer ${getAccessToken()}`;
  return request;
});

createAuthRefreshInterceptor(httpClient, refreshAuthLogic);

/**
 * Sends a request to refresh the authentication token.
 */
function refreshToken() {
  const refreshToken = localStorage.getItem("refreshToken");
  const data = { refresh_token: refreshToken };
  return sendRequest("POST", "auth/token/refresh", data, false);
}

/**
 * Retrieves the CAPTCHA token using Google reCAPTCHA.
 */
async function getCaptchaToken(action) {
  return new Promise((resolve) => {
    try {
      if (typeof grecaptcha === "object") {
        // eslint-disable-next-line no-undef
        grecaptcha.enterprise
          .execute(process.env.VUE_APP_RECAPTCHA_CLIENT_KEY, { action })
          .then((token) => {
            resolve(token);
          });
      } else {
        resolve(null);
      }
    } catch (e) {
      resolve(null);
    }
  });
}

/**
 * Helper to verify the email using EventBus.
 */
async function verifyEmail(emailToVerify, headers) {
  try {
    const verificationToken = await new Promise((resolve, reject) => {
      EventBus.$emit(
        "EMAIL_EXTRA_VERIFICATION",
        resolve,
        reject,
        emailToVerify,
      );
    });

    if (!verificationToken) {
      throw new Error("EXTRA_EMAIL_VERIFICATION_FAILED");
    }

    return {
      ...headers,
      "X-Verification-Token": verificationToken,
    };
  } catch (e) {
    throw new Error("EXTRA_EMAIL_VERIFICATION_FAILED");
  }
}

/**
 * Handles global errors such as required permissions and capabilities.
 */
function handleGlobalError(error, url, method, data) {
  const errorCode = error?.response?.data?.code;

  if (!errorCode) return;

  const eventBusEvents = {
    REQUIRED_CAPABILITY: "REQUIRED_CAPABILITY",
    REQUIRED_PERMISSION: "REQUIRED_PERMISSION",
    NOTIFICATION_USAGE_NOT_AVAILABLE: "NOTIFICATION_USAGE_NOT_AVAILABLE",
    EMPLOYEE_LIMIT: "EMPLOYEE_LIMIT",
  };

  switch (errorCode) {
    case eventBusEvents.EMPLOYEE_LIMIT:
      EventBus.$emit(eventBusEvents.EMPLOYEE_LIMIT, data);
      break;
    case eventBusEvents.REQUIRED_CAPABILITY:
      if (url === "admin/employees" && method === "POST") {
        EventBus.$emit(eventBusEvents.EMPLOYEE_LIMIT, data);
      } else {
        EventBus.$emit(eventBusEvents.REQUIRED_CAPABILITY, data);
      }
      break;
    case eventBusEvents.REQUIRED_PERMISSION:
      EventBus.$emit(eventBusEvents.REQUIRED_PERMISSION, data);
      break;
    case eventBusEvents.NOTIFICATION_USAGE_NOT_AVAILABLE:
      EventBus.$emit(eventBusEvents.NOTIFICATION_USAGE_NOT_AVAILABLE, data);
      break;
    default:
      break;
  }
}

/**
 * Retry the request after CAPTCHA validation failure.
 */
async function captchaFailedHandle(
  error,
  method,
  url,
  data,
  sendWithAuth,
  headers,
  otherOptions,
  blobResponseType,
) {
  // When captcha fails,
  // we can validate user's email and get the stronger captcha code that proofs we deal with a human.
  log.info("Attempting email verification due to CAPTCHA failure.");

  let emailToVerify = null;

  if (url === "admin/auth/login") {
    emailToVerify = data.email;
  }

  try {
    return sendRequest(
      method,
      url,
      data,
      sendWithAuth,
      headers,
      otherOptions,
      false,
      blobResponseType,
      emailToVerify,
    );
  } catch (e) {
    throw new Error("EXTRA_EMAIL_VERIFICATION_FAILED");
  }
}

/**
 * Main request function to send API requests.
 */
export async function sendRequest(
  method,
  url,
  data = null,
  sendWithAuth = false,
  headers = {},
  otherOptions = {},
  captchaRequired = false,
  blobResponseType = false,
  emailToVerify = null,
  captchaToken = null,
) {
  if (sendWithAuth && !store.getters["setup/getTenant"]) {
    resetApp("TENANT_UNKNOWN");
    throw new Error("TENANT_UNKNOWN");
  }

  // Handle email verification
  if (emailToVerify) {
    headers = await verifyEmail(emailToVerify, headers);
  }

  // Handle CAPTCHA
  if (captchaRequired && !captchaToken) {
    captchaToken = await getCaptchaToken(url);
  }

  const tenant = store.getters["setup/getTenant"];

  // Set request options
  const options = {
    method,
    url,
    params: method === "GET" ? data : null,
    data: method !== "GET" ? data : null,
    headers: {
      "X-Frontend-URL": window.location.href,
      ...headers,
    },
    ...otherOptions,
  };

  if (localStorage.getItem("locale")) {
    options.headers["Accept-Language"] = localStorage
      .getItem("locale")
      .toLowerCase();
  }

  if (tenant) {
    options.headers["X-Tenant"] = tenant;
  }

  if (sendWithAuth) {
    options.headers.Authorization = `Bearer ${getAccessToken()}`;
  }

  if (captchaToken) {
    options.headers["X-Captcha-Token"] = captchaToken;
  }

  if (blobResponseType) {
    options.responseType = "blob";
  }

  log.info("API sendRequest request: ", [
    store.getters["setup/getTenant"],
    options,
  ]);

  try {
    const response = await httpClient(options);
    log.info("API sendRequest response: ", [
      store.getters["setup/getTenant"],
      method,
      url,
      data,
      response,
    ]);
    return response;
  } catch (error) {
    const errorData = error?.response?.data
      ? JSON.stringify(error.response.data)
      : error;
    log.error("API sendRequest error: ", [url, errorData]);

    handleGlobalError(error, url, method, data);

    if (error?.response?.data?.code === "CAPTCHA_INVALID") {
      return captchaFailedHandle(
        error,
        method,
        url,
        data,
        sendWithAuth,
        headers,
        otherOptions,
        blobResponseType,
      );
    }

    // Handle 429 Too Many Requests
    if (error?.response?.data?.code === "TOO_MANY_REQUESTS") {
      errorNotification("too_many_requests", error, false);
    }

    if (url === "auth/token/refresh") {
      resetApp("REFRESH_TOKEN_FAILED");
    }

    throw error;
  }
}
