import { BusinessType } from '@/calendesk/models/BuilderTypes'

export const assetsSearchKey: Record<string, string> = {
  [BusinessType.BUSINESS_1]: 'hair',
  [BusinessType.BUSINESS_2]: 'spa',
  [BusinessType.BUSINESS_3]: 'beauty',
  [BusinessType.BUSINESS_4]: 'diet',
  [BusinessType.BUSINESS_5]: 'learning',
  [BusinessType.BUSINESS_6]: 'children',
  [BusinessType.BUSINESS_7]: 'business events',
  [BusinessType.BUSINESS_8]: 'city hall',
  [BusinessType.BUSINESS_9]: 'financial services',
  [BusinessType.BUSINESS_10]: 'question',
  [BusinessType.BUSINESS_11]: 'lawyer',
  [BusinessType.BUSINESS_12]: 'medicine',
  [BusinessType.BUSINESS_13]: 'driving lesson',
  [BusinessType.BUSINESS_14]: 'photographer',
  [BusinessType.BUSINESS_15]: 'psychology',
  [BusinessType.BUSINESS_16]: 'consulting',
  [BusinessType.BUSINESS_17]: 'coaching',
  [BusinessType.BUSINESS_18]: 'animals',
  [BusinessType.BUSINESS_19]: 'architect',
  [BusinessType.BUSINESS_20]: 'cleaning',
  [BusinessType.BUSINESS_21]: 'sport',
  [BusinessType.BUSINESS_22]: 'personal trainer',
  [BusinessType.BUSINESS_23]: 'gym',
  [BusinessType.BUSINESS_24]: 'fitness',
  [BusinessType.BUSINESS_25]: 'yoga',
  [BusinessType.BUSINESS_26]: 'tennis',
  [BusinessType.BUSINESS_27]: 'coworking',
  [BusinessType.BUSINESS_28]: 'restaurant',
  [BusinessType.BUSINESS_29]: 'physical therapy',
  [BusinessType.BUSINESS_30]: 'idea'
}
