import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'
import { SectionImageType } from '@/calendesk/models/DTO/Response/SectionImageType'
import { CreatorDefaultImage } from '@/calendesk/models/Creator/CreatorDefaultImage'
import { AlignType } from '@/calendesk/models/AlignType'
import { BuilderSelectType } from '@/calendesk/models/BuilderSelectType'
import { RoundType } from '@/calendesk/models/RoundType'
import { BookingDynamicFormTypes } from '@/calendesk/models/BookingDynamicFormTypes'

export default (): SectionConfiguration => {
  return {
    type: SectionType.SECTION,
    configuration: {
      component_id: SectionsSectionType.FLEXIBLE_FORM_1,
      wb_position: 0,
      present_height: 550,
      wb_height__style_height__: 550,
      wb_min_height: 50,
      wb_email_subject__text__: trans('wb_dummy_subject_title'),
      wb_email_form_success_url__url__: null,
      wb_email_sent__html_text__: `<h6 class="text-h6" style="text-align: center">${trans('wb_dummy_form_sent_success_title')}</h6><p style="text-align: center">${trans('wb_dummy_form_sent_success_description')}</p>`,
      wb_section_text__html_text__: `<h4 class="text-h4">${trans('wb_dummy_text_title')}</h4><br><br><p>${trans('wb_dummy_text_1')} ${trans('wb_dummy_text_1')}</p>`,
      wb_form_position__select__: {
        type: BuilderSelectType.FORM_POSITIONS,
        value: AlignType.TOP_RIGHT
      },
      wb_image_position__select__: {
        type: BuilderSelectType.IMAGE_POSITIONS,
        value: AlignType.TOP_LEFT
      },
      wb_image_width__number__: 450,
      wb_image_height__number__: 300,
      wb_image_rounded__select__: {
        type: BuilderSelectType.IMAGE_ROUNDED,
        value: RoundType.XL
      },
      wb_image_hide__checkbox__: false,
      wb_text_hide__checkbox__: false,
      wb_image_hide_on_mobile__checkbox__: false,
      wb_image_contain__checkbox__: false,
      wb_container_fluid__checkbox__: false,
      wb_invert_colors__checkbox__: false,
      wb_form__dynamic_form__: [
        {
          type: BookingDynamicFormTypes.TEXT_FIELD,
          label: trans('name_and_surname'),
          description: trans('wb_dummy_text_5'),
          required: true,
          equal: null
        },
        {
          type: BookingDynamicFormTypes.TEXT_AREA,
          label: trans('wb_booking_note'),
          description: trans('wb_dummy_text_4'),
          required: true,
          equal: null
        },
        {
          type: BookingDynamicFormTypes.CHECKBOX,
          label: null,
          description: trans('wb_dummy_gdpr_field'),
          required: true,
          equal: null
        }
      ],
      wb_button__cta_button__: {
        title: trans('send'),
        page_id: null,
        outlined: true,
        rounded: false,
        size: 'x-large',
        elevation: 0,
        is_functional: true
      }
    },
    images: [
      {
        slug: 'img_1',
        type: SectionImageType.IMAGE,
        name: CreatorDefaultImage.getImage(1)
      }
    ]
  }
}
