import { MoveDirection, SectionType } from '../models/BuilderTypes'
import Section from '../models/DTO/Response/Section'
import SectionToMove from '@/calendesk/models/SectionToMove'

export function calculateMovingSectionPosition (pageSections: Section[], sectionToMove: SectionToMove): number {
  const sortedPageSections = pageSections.sort((first: Section, second: Section) => first.configuration.wb_position - second.configuration.wb_position)
  const selectedSectionIndex = sortedPageSections.findIndex((section: Section) => section.id === sectionToMove.section.id)

  if (sectionToMove.moveDirection === MoveDirection.UP) {
    if (selectedSectionIndex === 0) {
      return Number(sortedPageSections[selectedSectionIndex].configuration.wb_position)
    } else if (selectedSectionIndex === 1) {
      return Number(sortedPageSections[0].configuration.wb_position / 2)
    } else {
      const before = sortedPageSections[selectedSectionIndex - 2].configuration.wb_position
      const after = sortedPageSections[selectedSectionIndex - 1].configuration.wb_position

      return Number((before + after) / 2)
    }
  } else {
    if (sortedPageSections[selectedSectionIndex + 2]) {
      const before = sortedPageSections[selectedSectionIndex + 2].configuration.wb_position
      const after = sortedPageSections[selectedSectionIndex + 1].configuration.wb_position

      return Number((before + after) / 2)
    } else if (sortedPageSections[selectedSectionIndex + 1]) {
      const after = sortedPageSections[selectedSectionIndex + 1].configuration.wb_position
      return Number(after * 2)
    } else {
      return Number(sortedPageSections[selectedSectionIndex].configuration.wb_position)
    }
  }
}

export function calculatePositionForEmptySection (pageSections: Section[], sectionToMove: SectionToMove): number {
  if (pageSections.length > 0) {
    const sortedPageSections = pageSections.sort((first: Section, second: Section) => first.configuration.wb_position - second.configuration.wb_position)
    const selectedSectionIndex = sortedPageSections.findIndex((section: Section) => section.id === sectionToMove.section.id)

    if (sectionToMove.section.type === SectionType.NAVBAR) {
      // Below navbar
      return Number(sortedPageSections[0].configuration.wb_position / 2)
    } else if (sectionToMove.section.type === SectionType.FOOTER) {
      // Above footer
      return Number(sortedPageSections[sortedPageSections.length - 1].configuration.wb_position * 2)
    } else {
      // Between sections
      if (sectionToMove.moveDirection === MoveDirection.UP) {
        if (selectedSectionIndex === 0) {
          return Number(sortedPageSections[0].configuration.wb_position / 2)
        } else {
          const before = sortedPageSections[selectedSectionIndex - 1].configuration.wb_position
          const after = sortedPageSections[selectedSectionIndex].configuration.wb_position
          return Number((before + after) / 2)
        }
      } else {
        if (selectedSectionIndex === 0 && sortedPageSections.length === 1) {
          return Number(sortedPageSections[0].configuration.wb_position * 2)
        } else {
          const before = sortedPageSections[selectedSectionIndex].configuration.wb_position

          if (sortedPageSections[selectedSectionIndex + 1]) {
            const after = sortedPageSections[selectedSectionIndex + 1].configuration.wb_position
            return Number((before + after) / 2)
          }

          return Number(before * 2)
        }
      }
    }
  }

  // No other sections, return default number.
  return 65535
}
