import Vue from 'vue'
import Vuetify from 'vuetify'
import colors from '@/lib/calendesk-js-library/styles/colors'
import icons from '@/styles/icons'
import { pl, en, de, fr, es, ru } from 'vuetify/src/locale'

Vue.use(Vuetify)

const theme = {
  ...colors,
  primary: '#3B6BE7',
  secondary: '#0B99B8',
  home_background: '#EEF0FC',
  wb_color_primary__color__: '#FFFFFF',
  wb_color_bg__color__: '#FFFFFF',
  wb_color_text__color__: '#FFFFFF',
  wb_color_bg_2__color__: '#FFFFFF',
  wb_color_text_2__color__: '#FFFFFF'
}

export default new Vuetify({
  lang: {
    locales: { pl, en, de, fr, es, ru },
    current: 'en'
  },
  icons: {
    iconfont: 'mdiSvg',
    values: icons
  },
  theme: {
    dark: false,
    themes: {
      light: theme
    },
    options: {
      customProperties: true
    }
  }
})
