import { GetterTree } from 'vuex'
import { RootState } from '../../types'
import { SubscriptionState } from './types'
import Subscription from '@/calendesk/models/DTO/Response/Subscription'

export const getters: GetterTree<SubscriptionState, RootState> = {
  getSubscriptions (state): Subscription[] | null {
    return state.subscriptions
  }
}
