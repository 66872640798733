import Vue from 'vue'
import { mapGetters } from 'vuex'
import SectionImage from '@/calendesk/models/DTO/Response/SectionImage'
import Section from '@/calendesk/models/DTO/Response/Section'
import { BusinessType, PreviewSizeType } from '@/calendesk/models/BuilderTypes'
import StoreController from '@/calendesk/controllers/StoreController'
import { ButtonSizeType } from '@/calendesk/models/ButtonSizeType'
import { BuilderSelectType } from '@/calendesk/models/BuilderSelectType'

export default Vue.extend({
  data () {
    return {
      previewSizeType: PreviewSizeType,
      buttonSizeType: ButtonSizeType,
      builderSelectType: BuilderSelectType,
      sectionsRequiredLogin: [] as Array<number>
    }
  },
  computed: {
    ...mapGetters({
      getReferrer: 'setup/getReferrer',
      appConfiguration: 'setup/getAppConfiguration',
      draftConfiguration: 'builder/getDraftConfiguration',
      isSidebarOpen: 'sidebar/isSidebarOpen',
      previewType: 'builder/getPreviewType',
      categoryAndServices: 'category/categoryWithServices'
    }),
    placeholderImageUrl (): any {
      return require('@/lib/calendesk-js-library/assets/placeholder.jpg')
    },
    isMobile (): boolean {
      return this.previewType === PreviewSizeType.MOBILE
    },
    isDesktop (): boolean {
      return this.previewType === PreviewSizeType.DESKTOP
    },
    isLoginHidden (): boolean {
      return !!this.draftConfiguration.wb_hide_login__checkbox__
    },
    isSignupHidden (): boolean {
      return !!this.draftConfiguration.wb_hide_signup__checkbox__
    },
    isHeaderHidden (): boolean {
      return !!this.draftConfiguration.wb_hide_header__checkbox__
    },
    isFooterHidden (): boolean {
      return !!this.draftConfiguration.wb_hide_footer__checkbox__
    },
    businessTypeId (): BusinessType {
      return this.draftConfiguration.business_type_id
    },
    isDarkMode (): boolean {
      return StoreController.isDarkMode()
    },
    isTimeZoneHidden (): boolean {
      return !!this.draftConfiguration.wb_hide_time_zone__checkbox__
    },
    hideBookingEndTime (): boolean {
      return !!this.draftConfiguration.wb_hide_booking_time_to__checkbox__
    }
  },
  methods: {
    getImageUrlFromSection (data: Section, slug: string): string | null {
      const img: SectionImage | undefined = data.images.find((_: SectionImage) => _.slug === slug)
      return img ? `${this.appConfiguration.imageBaseUrl}${img.name}` : null
    },
    openChatAction () {
      if (window.$chatwoot) {
        window.$chatwoot.toggle()
      }
    },
    openEmployees () {
      window.open(this.getReferrer + this.appConfiguration.language + '/panel/employees')
    },
    openServices () {
      window.open(this.getReferrer + this.appConfiguration.language + '/panel/offer/services')
    }
  }
})
