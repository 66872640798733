import { Expose } from 'class-transformer'

export default class Day {
  public day!: string

  @Expose({ name: 'end_time' })
  public endTime!: string

  @Expose({ name: 'start_time' })
  public startTime!: string
}
