function resolve(object, rules) {
  let resolved = null;

  for (const rule in rules) {
    resolved = rules[rule](object);

    if (resolved !== false) {
      break;
    }
  }

  return resolved;
}

export function resolveUsername(user) {
  const rules = {
    isUserNull: (user) => (user === null ? null : false),
    userHasNameAndSurname: (user) =>
      user.name !== null && user.surname !== null
        ? `${user.name} ${user.surname}`
        : false,
    userHasName: (user) => (user.name !== null ? `${user.name}` : false),
    userHasSurname: (user) =>
      user.surname !== null ? `${user.surname}` : false,
    userHasOnlyEmail: (user) => (user.email !== null ? user.email : false),
    userHasOnlyPhone: (user) =>
      user.default_phone && user.default_phone.e164
        ? user.default_phone.e164
        : false,
    isEmpty: (user) =>
      !user.name && !user.surname && !user.email ? "" : false,
  };

  return resolve(user, rules);
}
