import { GetterTree } from 'vuex'
import { BuilderState } from './types'
import { RootState } from '../../types'
import Draft from '@/calendesk/models/DTO/Response/Draft'
import Page from '@/calendesk/models/DTO/Response/Page'
import Section from '@/calendesk/models/DTO/Response/Section'
import { ChangeStep } from '@/calendesk/models/ChangeStep'
import { BusinessType, PreviewSizeType } from '@/calendesk/models/BuilderTypes'
import WebsiteStyleModel from '@/calendesk/models/WebsiteStyleModel'

export const getters: GetterTree<BuilderState, RootState> = {
  getDraft (state): Draft | null {
    return state.draft
  },
  getDraftConfiguration (state): Record<string, any> | null {
    return state.draft ? state.draft.configuration : null
  },
  getDraftPages (state): Page[] | null {
    return state.draft ? state.draft.pages : null
  },
  getNavbar (state): Section | null {
    return state.draft ? state.draft.navbar : null
  },
  getFooter (state): Section | null {
    return state.draft ? state.draft.footer : null
  },
  getCurrentPage (state): Page | null {
    return state.currentPage
  },
  getSelectedSection (state): Section | null {
    return state.selectedSection
  },
  getChangeSteps (state): ChangeStep[] {
    return state.steps
  },
  getCurrentStepIndex (state): number {
    return state.currentStepIndex
  },
  getSelectedExternalConfiguration (state): Record<string, any> | null {
    return state.selectedExternalConfiguration
  },
  getPreviewType (state): PreviewSizeType {
    return state.previewType
  },
  getBusinessType (state): BusinessType {
    return state.businessType ? state.businessType : BusinessType.BUSINESS_1
  },
  getWebsiteStyleModel (state): WebsiteStyleModel | null {
    return state.websiteStyleModel
  }
}
