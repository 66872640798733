export enum WebsiteLanguageType {
  CS = 'cs',
  DE = 'de',
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  HI = 'hi',
  NL = 'nl',
  PL = 'pl',
  PT = 'pt',
  RU = 'ru',
  SK = 'sk',
  UK = 'uk',
  BG = 'bg',
  EL = 'el',
  ET = 'et',
  FI = 'fi',
  GA = 'ga',
  HR = 'hr',
  HU = 'hu',
  LT = 'lt',
  LV = 'lv',
  MT = 'mt',
  SL = 'sl',
  SQ = 'sq',
  SV = 'sv',
}
