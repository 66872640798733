import { ChangeStep } from '@/calendesk/models/ChangeStep'

export enum RestoreType {
  UNDO,
  REDO
}

export class RestoredStep {
  public changeStep: ChangeStep
  public type: RestoreType;

  public constructor (changeStep: ChangeStep, type: RestoreType) {
    this.changeStep = changeStep
    this.type = type
  }
}
