import Sidebar from '@/calendesk/models/Sidebar'
import SidebarDetails from '@/calendesk/models/SidebarDetails'
import { ActionTree } from 'vuex'
import { RootState } from '../../types'
import { SidebarState } from './types'
import { SidebarType } from '@/calendesk/models/BuilderTypes'

export const actions: ActionTree<SidebarState, RootState> = {
  openSidebar ({ commit, state, dispatch }, sidebar: Sidebar): void {
    if (state.sidebar.open && state.sidebar.type === SidebarType.ADD_SECTION) {
      dispatch('builder/deleteEmptySection', null, { root: true })
    }
    commit('SET_SIDEBAR', sidebar)
  },
  closeSidebar ({ state, commit, dispatch }): void {
    if (state.sidebar.type === SidebarType.ADD_SECTION) {
      dispatch('builder/deleteEmptySection', null, { root: true })
    }
    commit('SET_SIDEBAR', new Sidebar(false))
  },
  openSidebarDetails ({ commit }, sidebarDetails: SidebarDetails): void {
    commit('SET_SIDEBAR_DETAILS', sidebarDetails)
  },
  closeSidebarDetails ({ commit }): void {
    commit('SET_SIDEBAR_DETAILS', new SidebarDetails())
  }
}
