








import Vue from 'vue'
import Preview from './preview/Preview.vue'
import Sidebar from './sidebar/Sidebar.vue'
import SectionDetails from './sidebar/sidebarDetails/SectionDetails.vue'
export default Vue.extend({
  name: 'Builder',
  components: { Preview, Sidebar, SectionDetails }
})
