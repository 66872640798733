





















import Vue from 'vue'
import BusinessCardModel from '@/calendesk/models/BusinessCardModel'

export default Vue.extend({
  name: 'BusinessCard',
  props: {
    card: {
      type: BusinessCardModel
    }
  },
  components: {
  },
  data () {
    return {

    }
  },
  created () {
    //
  },
  methods: {

  }
})
