import Bugsnag from "@bugsnag/js";
import store from "@/store";
import log from "@/lib/calendesk-js-library/tools/log";
import { trans } from "@/lib/calendesk-js-library/prototypes/trans";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";

export function successNotification(message) {
  store.commit("SET_SNACKBAR", {
    color: "success",
    text: trans(message),
    icon: "$info",
  });
}

export function warningNotification(
  message,
  notificationId,
  translationParams = {},
  redirect = null,
  redirectText = null
) {
  store.commit("SET_SNACKBAR", {
    color: "orange",
    text: trans(message, translationParams),
    timeout: "-1",
    closable: true,
    notificationId: notificationId,
    icon: "$alert",
    redirect,
    redirectText,
  });
}

export function errorNotification(
  message,
  details,
  report = true,
  translationParams = {},
  redirect = null,
  redirectText = null
) {
  if (!message) {
    message = "error_occurred";
  }
  store.commit("SET_SNACKBAR", {
    color: "red-light",
    text: trans(message, translationParams),
    timeout: 7000,
    closable: true,
    icon: "$alert",
    redirect,
    redirectText,
  });

  pushEvent("errorNotification", {
    message: message,
    details: details,
    report: report,
  });

  if (
    details &&
    details.response &&
    details.response.data &&
    details.response.data.code === "NOT_FOUND"
  ) {
    report = false;
  }

  reportError(message, details, report);
}

export function reportError(message, details, report = true) {
  if (details && details.response && details.response.data) {
    details = details.response.data;
  }

  log.error("errorNotification", [message, details]);

  if (report && process.env.NODE_ENV === "production") {
    Bugsnag.notify(
      new Error(
        JSON.stringify([
          message,
          details,
          store.getters["setup/getTenant"],
          store.getters["setup/getLocale"],
        ])
      )
    );
  }
}
