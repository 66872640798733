import { CreatorDefaultImage } from '@/calendesk/models/Creator/CreatorDefaultImage'
import StoreController from '@/calendesk/controllers/StoreController'

export class CreatorDefaultLogo extends CreatorDefaultImage {
  public static getDark (): string {
    return '/demo/c0-logo-1.png'
  }

  public static getLight (): string {
    return '/demo/c0-logo-2.png'
  }

  public static getForBg1 (): string {
    if (StoreController.isBackground1Dark()) {
      return CreatorDefaultLogo.getLight()
    }

    return CreatorDefaultLogo.getDark()
  }

  public static getForBg2 (): string {
    if (StoreController.isBackground2Dark()) {
      return CreatorDefaultLogo.getLight()
    }

    return CreatorDefaultLogo.getDark()
  }
}
