export default {
  primary: "#7F0CCD",
  secondary: "#0B99B8",
  accent: "#D8EFFF",
  error: "#E52121",
  danger: "#E52121",
  info: "#2022FF",
  success: "#DCFFDB",
  warning: "#ffb706",
  light: "#979797",
  dark: "#263238",
  blue: "#3B6BE7",
  "blue-light": "#E0F4FF",
  "blue-light-2": "#4FB8FF",
  pink: "#F4E3FF",
  "yellow-light": "#FFF5DB",
  "green-light": "#DCFFDB",
  green: "#1ED91D",
  "red-light": "#F8C8C8",
  background: "#FFFFFF",
  snow_gray: "#F1F6FA",
  darken_gray: "#E1EAF1",
  accent_light: "#E74C3C",
  grey_light: "#DCDEDF",
  editor_navbar_background: "#000",
  editor_navbar_color: "#cacaca",
  editor_tool_background: "#fff",
  editor_tool_color: "#263238",
  editor_preview_color: "#222",
  editor_image_menu_color: "#888",
  black: "#000000",
  editor_undo_redo: "#888888",
  mobile_editor_background: "#2E333D",
  mobile_editor_button: "#E0E0E0",
  cd_color_1: "#0C96B8",
  cd_color_2: "#EEF0FC",
  cd_color_3: "#F9F9F9",
  cd_color_4: "#E2E2E2",
  cd_color_5: "#a7a8ad",
  cd_color_6: "#797979",
  cd_color_7: "#E96E4C",
  cd_color_8: "#E6A935",
  cd_color_9: "#85C33D",
  cd_color_10: "#555259",
  cd_color_11: "#33CDFF",

  cd_color_13: "#DEE1FC",
  cd_color_14: "#F0F0F0",

  cd_color_15: "#dddddd",
  cd_color_16: "#eeeeee",

  c_dark: "#12022F",
  c_dark_2: "#594D6D",
  c_dark_3: "#4650C2",
  c_dark_4: "#23262F",
  c_dark_5: "#353945",
  c_dark_6: "#2E333D",
  c_gray: "#E6E8EC",
  c_gray_1: "#979797",
  c_gray_2: "#777E90",
  c_gray_3: "#888097",
  c_gray_4: "#ACA6B6",
  c_gray_5: "#E7E5EA",
  c_white_1: "#FCFCFD",
  c_purple: "#DEE1FC",
  c_dark_gray: "#C4C4C4",
  c_orange: "#FF6A01",
  c_green: "#1ED91D",
};
