


























import Vue from 'vue'
import PreviewBar from './PreviewBar.vue'
import PreviewContent from './PreviewContent.vue'
import { mapGetters } from 'vuex'
import { PreviewSizeType } from '@/calendesk/models/BuilderTypes'
import CalendeskInformationMessage from '@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue'
import { WebsiteLanguageType } from '@/calendesk/models/WebsiteLanguageType'
import getLanguageName from '@/calendesk/tools/getLanguageName'

export default Vue.extend({
  name: 'Preview',
  components: {
    CalendeskInformationMessage,
    PreviewBar,
    PreviewContent
  },
  computed: {
    ...mapGetters({
      previewType: 'builder/getPreviewType',
      draftConfiguration: 'builder/getDraftConfiguration'
    }),
    getClass (): Record<string, boolean> {
      return {
        desktop: this.previewType === PreviewSizeType.DESKTOP,
        mobile: this.previewType === PreviewSizeType.MOBILE
      }
    },
    getWidth (): string {
      if (this.previewType === PreviewSizeType.DESKTOP) {
        return '100%'
      }

      return '600px'
    },
    showLanguageWarning () {
      if (this.draftConfiguration && this.draftConfiguration.wb_primary_language__select__) {
        const supportedLanguages = [
          WebsiteLanguageType.PL,
          WebsiteLanguageType.EN,
          WebsiteLanguageType.ES,
          WebsiteLanguageType.DE
        ]

        const currentLanguage = this.draftConfiguration.wb_primary_language__select__.value

        return !supportedLanguages.includes(currentLanguage)
      }

      return false
    }
  },
  methods: {
    getLanguageName
  }
})
