<template>
  <v-dialog
    v-model="confirmDialog.open"
    :fullscreen="
      $vuetify.breakpoint.smAndDown ||
      (confirmDialog && confirmDialog.fullscreen)
    "
    :max-width="confirmDialog.width || 400"
    content-class="confirm-dialog white"
    :persistent="confirmDialog.persistent"
    @click:outside="close"
  >
    <v-progress-linear v-if="dialogLoading" indeterminate color="primary" />
    <component
      :is="getComponent.content"
      v-if="confirmDialog.open && getComponent"
      @loading="dialogLoading = $event"
      @close="close"
      @cancel="closeConfirmDialog"
    />
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "ConfirmDialog",
  mixins: [sharedActions],
  props: {
    confirmDialogList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogLoading: false,
      confirmDialog: null,
    };
  },
  computed: {
    ...mapGetters({
      updateConfirmDialog: "confirmDialog/getInstance",
    }),
    getComponent() {
      const component = this.confirmDialogList.find(
        (dialog) => dialog.type === this.confirmDialog.type
      );
      return component || null;
    },
  },
  watch: {
    "updateConfirmDialog.open": function () {
      this.confirmDialog = {
        ...this.updateConfirmDialog,
      };
    },
    "updateConfirmDialog.type": function () {
      this.confirmDialog = {
        ...this.updateConfirmDialog,
      };
    },
    "confirmDialog.open": function () {
      if (this.confirmDialog.open === this.updateConfirmDialog.open) {
        return;
      }
      this.closeConfirmDialog();
    },
  },
  created() {
    this.confirmDialog = this.getConfirmDialog;
  },
  methods: {
    close(data) {
      if (
        this.confirmDialog.close &&
        typeof this.confirmDialog.close === "function"
      ) {
        this.confirmDialog.close(data);
      }

      this.closeConfirmDialog();
    },
  },
};
</script>

<style lang="css">
.confirm-dialog .v-card .v-card__text {
  padding: 0 24px 0;
}
</style>
