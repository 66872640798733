import { Expose } from 'class-transformer'

export default class TenantPlan {
  @Expose({ name: 'on_trial' })
  public onTrial!: boolean

  public slug!: string
  public name!: string
  public period!: string
}
