import { trans } from "@/lib/calendesk-js-library/prototypes/trans";
import { parseTime } from "@/lib/calendesk-js-library/tools/helpers";
import getCountry from "./countries";

export const required = (value) =>
  !!value || value === 0 || trans("field_is_required");

export const email = (value) =>
  !value ||
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    value
  ) ||
  trans("email_is_incorrect");

export const minChars = (min) => (value) => {
  if (!value || (min !== null && `${value}`.trim().length >= min)) {
    return true;
  }
  return trans("min_n_characters", { min });
};

export const maxChars = (max) => (value) => {
  if (!value || (max !== null && `${value}`.length <= max)) {
    return true;
  }
  return trans("max_n_characters", { max });
};

export const requiredArray = (value) =>
  !value || value.length >= 1 || trans("field_is_required");

export const timeNotGreaterThan = (to) => (from) => {
  if (!from || (to !== null && parseTime(from) < parseTime(to))) {
    return true;
  }
  return trans("from_cannot_be_greater_than_to");
};

export const timeNotLighterThan = (from) => (to) => {
  if (!from || parseTime(to) > parseTime(from)) {
    return true;
  }
  return trans("from_cannot_be_greater_than_to");
};

export const time = (time) => {
  const isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(
    time
  );

  if (isValid) {
    return true;
  }

  return trans("invalid_time");
};

export const alphanumericSMSSender = (senderId) => {
  const isValid = /^(?=.*[a-zA-Z])(?=.*[a-zA-Z0-9])([a-zA-Z0-9 ]{1,11})$/m.test(
    senderId
  );

  if (isValid) {
    return true;
  }

  return trans("settings_alphanumeric_sms_sender_validation_error");
};

export const isValidIsoPrefix = (code) => {
  if (typeof code !== "string") {
    return false;
  }
  const countries = getCountry();
  const codes = countries.map((el) => el.iso2);
  return codes.includes(code.toLowerCase());
};

export const url = (value) => {
  const pattern = new RegExp(/^(http|https):\/\/[^ "]+$/);
  return pattern.test(value) ? true : trans("value_is_no_url");
};

export const domain = (value) => {
  const pattern = new RegExp(
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w-]+)+[\w\-_~:/?#[\]@!&',;=.]+$/gm
  );
  return pattern.test(value) ? true : trans("value_is_no_url");
};

export const maxNumber = (value, number = 2147483647) => {
  if (value <= number) {
    return true;
  }
  return trans("value_is_too_high", { number });
};

export const minNumber = (value, number) => {
  if (value >= number) {
    return true;
  }
  return trans("value_is_too_low", { number });
};

export const maxMbSize = (file, maxSize = 10) => {
  const size = file.size / 1024 / 1024;
  return size <= maxSize;
};

export const uniqueStringValue = (value, values = [], errorMsg = null) => {
  return !values.includes(value)
    ? true
    : errorMsg || trans("value_must_be_unique");
};

export const isBooleanInteger = (value) => value === 1 || value === 0;
export const hasNumber = (value) =>
  /\d/.test(value) || `${trans("no_numeric_character")}`;
export const isInteger = (value) =>
  /^\d+$/.test(value) || `${trans("no_integer")}`;
export const hasLowercase = (value) =>
  /[a-z]/.test(value) || `${trans("no_lowercase_character")}`;
export const hasUppercase = (value) =>
  /[A-Z]/.test(value) || `${trans("no_uppercase_character")}`;
export const hasSpecialChar = (value) =>
  /\W/.test(value) || `${trans("no_special_character")}`;

export const facebookUrl = (value) =>
  /(?:(?:http|https):\/\/)(?:www.)?facebook.com\/?/.test(value) ||
  `${trans("value_is_no_url_to_facebook")}`;
export const twitterUrl = (value) =>
  /(?:(?:http|https):\/\/)(?:www.)?twitter.com\/?/.test(value) ||
  `${trans("value_is_no_url_to_twitter")}`;
export const instagramUrl = (value) =>
  /(?:(?:http|https):\/\/)(?:www.)?instagram.com\/?/.test(value) ||
  `${trans("value_is_no_url_to_instagram")}`;
export const youtubeUrl = (value) =>
  /(?:(?:http|https):\/\/)(?:www.)?youtube.com\/?/.test(value) ||
  `${trans("value_is_no_url_to_youtube")}`;
