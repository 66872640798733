import {
  mdiCalendar,
  mdiCalendarRange,
  mdiLock,
  mdiFilterVariant,
  mdiAccountCircle,
  mdiArrowRight,
  mdiArrowLeft,
  mdiDotsVertical,
  mdiAccountMultiple,
  mdiEmail,
  mdiHome,
  mdiInformationOutline,
  mdiEye,
  mdiEyeOff,
  mdiAlertCircle,
  mdiCheck,
  mdiChevronLeft,
  mdiChevronRight,
  mdiPlus,
  mdiPlusCircle,
  mdiDesktopMac,
  mdiTabletIpad,
  mdiCellphone,
  mdiUndo,
  mdiRedo,
  mdiMagnifyPlusOutline,
  mdiMagnifyMinusOutline,
  mdiBrush,
  mdiPencil,
  mdiFileImage,
  mdiImage,
  mdiSync,
  mdiCrop,
  mdiFlipHorizontal,
  mdiReplay,
  mdiCropFree,
  mdiCropLandscape,
  mdiCropSquare,
  mdiRotateLeft,
  mdiRotateRight,
  mdiFlipVertical,
  mdiClock,
  mdiDragHorizontalVariant,
  mdiDeleteCircle,
  mdiSwapVertical,
  mdiMagnify,
  mdiClose,
  mdiCloseCircle,
  mdiCheckCircle,
  mdiCircle,
  mdiPalette,
  mdiDelete,
  mdiChevronUp,
  mdiChevronDown,
  mdiChevronDoubleRight,
  mdiChevronDoubleLeft,
  mdiMenuDown,
  mdiGenderMaleFemale,
  mdiHeart,
  mdiAccountSettings,
  mdiPlusCircleOutline,
  mdiStore,
  mdiTrendingNeutral,
  mdiBlockHelper,
  mdiViewDashboard,
  mdiClipboardAccount,
  mdiPuzzle,
  mdiImageMultiple,
  mdiCog,
  mdiTuneVertical,
  mdiTwitter,
  mdiFacebook,
  mdiCloudDownload,
  mdiContentCut,
  mdiCalendarMonth,
  mdiChairSchool,
  mdiPhone,
  mdiLogout,
  mdiNavigation,
  mdiCellphoneMessage,
  mdiFire,
  mdiCloseBoxOutline,
  mdiCheckBoxOutline,
  mdiInfinity,
  mdiRocketLaunch,
  mdiMenu,
  mdiContentSave,
  mdiMinus,
  mdiAccountPlus,
  mdiMapMarker,
  mdiArrowDownBold,
  mdiInstagram,
  mdiYoutube,
  mdiLink,
  mdiSizeS,
  mdiSizeM,
  mdiSizeL,
  mdiSizeXl,
  mdiCamera,
  mdiFormatBold,
  mdiFormatItalic,
  mdiFormatUnderline,
  mdiFormatStrikethrough,
  mdiFormatParagraph,
  mdiFormatHeader1,
  mdiFormatHeader2,
  mdiFormatHeader3,
  mdiFormatHeader4,
  mdiFormatHeader5,
  mdiFormatHeader6,
  mdiFormatListBulleted,
  mdiOrderNumericAscending,
  mdiCodeTags,
  mdiFormatQuoteOpen,
  mdiFormatColorHighlight,
  mdiFormatAlignLeft,
  mdiFormatAlignRight,
  mdiFormatAlignCenter,
  mdiFormatAlignJustify,
  mdiFormatPageBreak,
  mdiFormatColorText,
  mdiFormatColorMarkerCancel,
  mdiSquare,
  mdiLinkOff,
  mdiFormatSize,
  mdiCart,
  mdiCreditCardOutline,
  mdiMonitor,
  mdiFile,
  mdiXml,
  mdiGestureTap,
  mdiShoppingOutline,
  mdiShopping,
  mdiContentPaste,
  mdiEarth,
  mdiBookmarkMultipleOutline,
  mdiContentCopy,
  mdiVideoAccount
} from '@mdi/js'

const materialDesignIcons = {
  video: mdiVideoAccount,
  copy: mdiContentCopy,
  bookmarks: mdiBookmarkMultipleOutline,
  earth: mdiEarth,
  paste: mdiContentPaste,
  'shopping-outline': mdiShoppingOutline,
  shopping: mdiShopping,
  tap: mdiGestureTap,
  calendar: mdiCalendar,
  'calendar-range': mdiCalendarRange,
  password: mdiLock,
  filter: mdiFilterVariant,
  account: mdiAccountCircle,
  'arrow-right': mdiArrowRight,
  'arrow-left': mdiArrowLeft,
  'dots-vertical': mdiDotsVertical,
  'account-multiple': mdiAccountMultiple,
  email: mdiEmail,
  home: mdiHome,
  info: mdiInformationOutline,
  'eye-on': mdiEye,
  'eye-off': mdiEyeOff,
  alert: mdiAlertCircle,
  check: mdiCheck,
  chevron_left: mdiChevronLeft,
  chevron_right: mdiChevronRight,
  plus: mdiPlus,
  circle: mdiPlusCircle,
  'circle-outline': mdiPlusCircleOutline,
  desktop: mdiDesktopMac,
  tablet: mdiTabletIpad,
  smartphone: mdiCellphone,
  undo: mdiUndo,
  redo: mdiRedo,
  zoom_plus: mdiMagnifyPlusOutline,
  zoom_minus: mdiMagnifyMinusOutline,
  brush: mdiBrush,
  pencil: mdiPencil,
  file: mdiFileImage,
  image: mdiImage,
  reset: mdiSync,
  crop: mdiCrop,
  rotate: mdiReplay,
  'crop-free': mdiCropFree,
  'crop-landscape': mdiCropLandscape,
  'crop-square': mdiCropSquare,
  'rotate-left': mdiRotateLeft,
  'rotate-right': mdiRotateRight,
  'flip-vertical': mdiFlipVertical,
  'flip-horizontal': mdiFlipHorizontal,
  clock: mdiClock,
  drag: mdiDragHorizontalVariant,
  trash: mdiDeleteCircle,
  'trash-default': mdiDelete,
  swap: mdiSwapVertical,
  magnify: mdiMagnify,
  close: mdiClose,
  'close-circle': mdiCloseCircle,
  'check-circle': mdiCheckCircle,
  dot: mdiCircle,
  palette: mdiPalette,
  'chevron-up': mdiChevronUp,
  'chevron-down': mdiChevronDown,
  'chevron-left': mdiChevronLeft,
  'chevron-right': mdiChevronRight,
  'chevron-double-right': mdiChevronDoubleRight,
  'chevron-double-left': mdiChevronDoubleLeft,
  'menu-down': mdiMenuDown,
  'gender-male-female': mdiGenderMaleFemale,
  heart: mdiHeart,
  settings: mdiAccountSettings,
  store: mdiStore,
  trending: mdiTrendingNeutral,
  'block-helper': mdiBlockHelper,
  dashboard: mdiViewDashboard,
  'clipboard-account': mdiClipboardAccount,
  plugins: mdiPuzzle,
  'image-multiple': mdiImageMultiple,
  cog: mdiCog,
  'tune-vertical': mdiTuneVertical,
  twitter: mdiTwitter,
  facebook: mdiFacebook,
  download: mdiCloudDownload,
  'content-cut': mdiContentCut,
  'calendar-month': mdiCalendarMonth,
  'chair-school': mdiChairSchool,
  phone: mdiPhone,
  logout: mdiLogout,
  navigation: mdiNavigation,
  'cell-phone-message': mdiCellphoneMessage,
  fire: mdiFire,
  'close-box-outline': mdiCloseBoxOutline,
  'check-box-outline': mdiCheckBoxOutline,
  infinity: mdiInfinity,
  rocket: mdiRocketLaunch,
  menu: mdiMenu,
  'size-s': mdiSizeS,
  'size-m': mdiSizeM,
  'size-l': mdiSizeL,
  'size-xl': mdiSizeXl,
  minus: mdiMinus,
  chevron_down: mdiChevronDown,
  chevron_up: mdiChevronUp,
  save: mdiContentSave,
  dotsVertical: mdiDotsVertical,
  addUser: mdiAccountPlus,
  menu_down: mdiMenuDown,
  marker: mdiMapMarker,
  mail: mdiEmail,
  arrow_down: mdiArrowDownBold,
  fb: mdiFacebook,
  yt: mdiYoutube,
  instagram: mdiInstagram,
  mobile: mdiCellphone,
  link: mdiLink,
  'link-off': mdiLinkOff,
  camera: mdiCamera,
  'format-bold': mdiFormatBold,
  'format-italic': mdiFormatItalic,
  'format-strikethrough': mdiFormatStrikethrough,
  'format-paragraph': mdiFormatParagraph,
  'format-header-1': mdiFormatHeader1,
  'format-header-2': mdiFormatHeader2,
  'format-header-3': mdiFormatHeader3,
  'format-header-4': mdiFormatHeader4,
  'format-header-5': mdiFormatHeader5,
  'format-header-6': mdiFormatHeader6,
  'format-list-bulleted': mdiFormatListBulleted,
  'order-numeric-ascending': mdiOrderNumericAscending,
  'code-tags': mdiCodeTags,
  'format-quote-open': mdiFormatQuoteOpen,
  'format-color-highlight': mdiFormatColorHighlight,
  'format-color-highlight-cancel': mdiFormatColorMarkerCancel,
  'format-align-left': mdiFormatAlignLeft,
  'format-align-right': mdiFormatAlignRight,
  'format-align-center': mdiFormatAlignCenter,
  'format-align-justify': mdiFormatAlignJustify,
  'format-underline': mdiFormatUnderline,
  'format-page-break': mdiFormatPageBreak,
  'format-color-text': mdiFormatColorText,
  square: mdiSquare,
  'format-size': mdiFormatSize,
  cart: mdiCart,
  card: mdiCreditCardOutline,
  monitor: mdiMonitor,
  'file-2': mdiFile,
  xml: mdiXml
}

export default {
  ...materialDesignIcons
}
