import sharedDialogTypes from '@/lib/calendesk-js-library/components/dialogs/sharedDialogTypes'
import dialogTypes from '@/components/dialogs/dialogTypes'

const DialogList: Array<Record<string, any>> = [
  // Shared
  {
    type: sharedDialogTypes.ASSETS_DIALOG,
    content: () => import('@/lib/calendesk-js-library/components/dialogs/components/AssetsDialog/AssetsDialog.vue')
  },
  {
    type: sharedDialogTypes.EDITOR_IMAGE_DIALOG,
    content: () => import('@/lib/calendesk-js-library/components/dialogs/components/EditorImageDialog/EditorImageDialog.vue')
  },
  {
    type: sharedDialogTypes.FEATURE_NOT_AVAILABLE_DIALOG,
    content: () => import('@/lib/calendesk-js-library/components/dialogs/components/FeatureNotAvailableDialog/FeatureNotAvailableDialog.vue')
  },

  // Local
  {
    type: dialogTypes.BUILDER_ADD_PAGE,
    content: () => import('./components/CreatePageDialog.vue')
  },
  {
    type: dialogTypes.BUILDER_DOMAIN,
    content: () => import('./components/domains/DomainDialog.vue')
  },
  {
    type: dialogTypes.BUILDER_UPDATE_DOMAIN,
    content: () => import('./components/domains/UpdateSubdomainDialog.vue')
  },
  {
    type: dialogTypes.BUILDER_ADD_AND_UPDATE_YOUR_DOMAIN,
    content: () => import('./components/domains/AddAndUpdateYourDomain.vue')
  },
  {
    type: dialogTypes.ADD_CODE_TO_WEBSITE,
    content: () => import('./components/AddCodeToWebsite.vue')
  },
  {
    type: dialogTypes.DUPLICATE_SECTION,
    content: () => import('./components/DuplicateSectionDialog.vue')
  }
]

export default DialogList
