<template>
  <v-dialog
    v-model="dialog.open"
    :width="dialog.size === dialogSize.FULL_SCREEN ? null : dialog.size"
    :fullscreen="
      !$vuetify.breakpoint.mdAndUp || dialog.size === dialogSize.FULL_SCREEN
    "
    scrollable
    :persistent="dialog.persistent"
  >
    <v-card
      :loading="dialogLoading ? $vuetify.theme.themes.light.primary : false"
    >
      <v-toolbar flat max-height="56px" fixed>
        <v-btn
          fab
          x-small
          :disabled="
            ctaButtonLoading || closeDialogButtonDisabled || dialogLoading
          "
          @click="closeDialog"
        >
          <v-icon> $close </v-icon>
        </v-btn>
        <v-toolbar-title class="ml-4 text-wrap">{{
          dialog.title
        }}</v-toolbar-title>
        <v-spacer />

        <v-btn
          v-if="dialog.ctaButtonTitle"
          :color="dialog.ctaButtonColor"
          depressed
          outlined
          :loading="ctaButtonLoading"
          :disabled="ctaButtonLoading || ctaButtonDisabled || dialogLoading"
          @click="handleCtaButtonAction"
        >
          <v-icon v-if="dialog.ctaButtonIcon" left>{{
            dialog.ctaButtonIcon
          }}</v-icon>
          <span v-if="dialog.ctaButtonTitle">{{ dialog.ctaButtonTitle }}</span>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mx-0 white pa-4 pb-16 pb-md-8">
        <component
          :is="getComponent.content"
          v-if="dialog.open && getComponent"
          ref="component"
          @loading="dialogLoading = $event"
          @ctaButtonLoading="ctaButtonLoading = $event"
          @ctaButtonDisabled="ctaButtonDisabled = $event"
          @closeDialogButtonDisabled="closeDialogButtonDisabled = $event"
          @close="closeDialog"
        />
      </v-card-text>
      <template v-if="dialog.showCloseDialogFooter">
        <v-card-actions class="d-flex justify-center">
          <v-btn
            :disabled="
              dialogLoading || closeDialogButtonDisabled || ctaButtonLoading
            "
            color="primary"
            text
            @click="closeDialog"
          >
            {{ $trans("close") }}
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "AppDialog",
  mixins: [sharedActions],
  props: {
    componentList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogLoading: false,
      dialog: this.$store.getters["dialog/getInstance"],
      dialogSize,
      ctaButtonLoading: false,
      ctaButtonDisabled: false,
      closeDialogButtonDisabled: false,
    };
  },
  computed: {
    ...mapGetters({
      updateDialog: "dialog/getInstance",
    }),
    getComponent() {
      const component = this.componentList.find(
        (dialog) => dialog.type === this.dialog.type
      );
      return component || null;
    },
  },
  watch: {
    "updateDialog.open": function () {
      this.dialog = {
        ...this.updateDialog,
      };
    },
    "updateDialog.type": function () {
      this.dialog = {
        ...this.updateDialog,
      };
      this.loading = false;
      this.ctaButtonLoading = false;
    },
    "dialog.open": function () {
      if (this.dialog.open === this.updateDialog.open) {
        return;
      }
      this.closeDialog();
    },
  },
  methods: {
    handleCtaButtonAction() {
      if (
        this.dialog.ctaButtonAction &&
        typeof this.$refs.component[this.dialog.ctaButtonAction] === "function"
      ) {
        this.$refs.component[this.dialog.ctaButtonAction]();
      }

      return null;
    },
  },
};
</script>
