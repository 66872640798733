import log from '@/lib/calendesk-js-library/tools/log'
import store from '@/store'

export function haveRequiredParams (): boolean {
  let tenant = store.getters['setup/getTenant']

  if (!tenant && localStorage.tenant &&
    localStorage.tenant !== '' &&
    typeof localStorage.tenant !== 'undefined') {
    tenant = localStorage.tenant
  }

  const token = localStorage.getItem('accessToken')

  return !!(tenant && token)
}

export function haveStartParams (): boolean {
  const draftId = localStorage.getItem('draft_id')
  return !!(haveRequiredParams() && draftId)
}

export function closeApp (error: Error | null = null): void {
  if (error) {
    log.error('closeAppError', error)
  }

  if (process.env.NODE_ENV === 'production') {
    const referrer = store.getters['setup/getReferrer']
    const appConfiguration = store.getters['setup/getAppConfiguration']

    localStorage.clear()

    if (appConfiguration && referrer) {
      window.location.replace(referrer + appConfiguration.language + '/panel/tools/websites')
    } else {
      window.location.replace(process.env.VUE_APP_DEFAULT_REFERRER)
    }
  }
}
