import store from '@/store/index'
import Configuration from '@/calendesk/models/DTO/Response/Configuration'
import { BusinessType } from '@/calendesk/models/BuilderTypes'
import WebsiteStyleModel from '@/calendesk/models/WebsiteStyleModel'

export default class StoreController {
  public static appConfiguration (): Configuration | null {
    return store.getters['setup/getAppConfiguration']
  }

  public static businessType (): BusinessType | null {
    return store.getters['builder/getBusinessType']
  }

  public static websiteStyleModel (): WebsiteStyleModel | null {
    return store.getters['builder/getWebsiteStyleModel']
  }

  public static isDarkMode (): boolean {
    const websiteStyleMode: WebsiteStyleModel | null = store.getters['builder/getWebsiteStyleModel']

    if (websiteStyleMode) {
      return websiteStyleMode.isDarkMode
    }

    return false
  }

  public static isBackground1Dark (): boolean {
    const websiteStyleModel = StoreController.websiteStyleModel()

    if (websiteStyleModel) {
      return WebsiteStyleModel.isDark(websiteStyleModel.bgColor)
    }

    return false
  }

  public static isBackground2Dark (): boolean {
    const websiteStyleModel = StoreController.websiteStyleModel()

    if (websiteStyleModel) {
      return WebsiteStyleModel.isDark(websiteStyleModel.bg2Color)
    }

    return false
  }

  public static isText1Dark (): boolean {
    const websiteStyleModel = StoreController.websiteStyleModel()

    if (websiteStyleModel) {
      return WebsiteStyleModel.isDark(websiteStyleModel.textColor)
    }

    return false
  }

  public static isText2Dark (): boolean {
    const websiteStyleModel = StoreController.websiteStyleModel()

    if (websiteStyleModel) {
      return WebsiteStyleModel.isDark(websiteStyleModel.text2Color)
    }

    return false
  }
}
