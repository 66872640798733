


















































































































import Typed from 'typed.js'
import SelectBusiness from '@/views/components/creator/SelectBusiness.vue'
import BusinessCardModel from '@/calendesk/models/BusinessCardModel'
import SelectElements from '@/views/components/creator/SelectElements.vue'
import WebsiteElementModel from '@/calendesk/models/WebsiteElementModel'
import WebsiteStyleModel from '@/calendesk/models/WebsiteStyleModel'
import SelectStyle from '@/views/components/creator/SelectStyle.vue'
import { maxChars, required } from '@/lib/calendesk-js-library/forms/validators'
import DraftCreatorController from '@/calendesk/controllers/DraftCreatorController'
import mixins from 'vue-typed-mixins'
import DraftElement from '@/builder/sections/mixins/DraftElement'

export default mixins(DraftElement).extend({
  name: 'Creator',
  components: {
    SelectStyle,
    SelectElements,
    SelectBusiness
  },
  data () {
    return {
      step: 1,
      chatEnded: false,
      title: null as string | null,
      currentChat: null as Typed | null,
      selectedBusiness: null as BusinessCardModel | null,
      selectedElements: Array<WebsiteElementModel>(),
      selectedStyle: null as WebsiteStyleModel | null,
      draftName: null as string | null,
      chat1: null as Typed | null,
      chat2: null as Typed | null,
      chat3: null as Typed | null,
      chat4: null as Typed | null,
      chat5: null as Typed | null,
      chat6: null as Typed | null,
      rules: {
        required,
        maxChars
      }
    }
  },
  created () {
    this.runStep1()
  },
  computed: {
    disabledNextButton (): boolean {
      if (this.step === 3) {
        return this.selectedElements.length === 0
      } else if (this.step === 4) {
        return !this.selectedStyle
      } else if (this.step === 5) {
        return !this.draftName || (!!this.draftName && this.draftName.length > 255)
      }

      return false
    }
  },
  methods: {
    scrollUp () {
      this.$vuetify.goTo(0, { })
    },
    runStep1 () {
      this.scrollUp()
      this.step = 1
      this.chatEnded = false
      if (!this.chat1) {
        this.$nextTick(() => {
          this.chat1 = this.runChat('#chat1', [this.$trans('wb_creator_chat_1')], false, (self: any) => {
            self.cursor.remove()
            this.chatEnded = true
            this.runStep2()
          })
        })
      } else {
        this.runStep2()
      }
    },
    runStep2 () {
      this.scrollUp()
      this.step = 2
      this.chatEnded = false
      this.title = null

      if (!this.chat2) {
        this.$nextTick(() => {
          this.chat2 = this.runChat('#chat2', [this.$trans('wb_creator_chat_2')], false, (self: any) => {
            self.cursor.remove()
            this.chatEnded = true
            this.title = this.$trans('wb_creator_chat_2')
          })
        })
      } else {
        this.chatEnded = true
        this.title = this.$trans('wb_creator_chat_2')
      }
    },
    runStep3 () {
      this.scrollUp()
      this.step = 3
      this.chatEnded = false

      if (!this.chat3) {
        this.$nextTick(() => {
          this.chat3 = this.runChat('#chat3', [this.$trans('wb_creator_chat_3')], false, (self: any) => {
            self.cursor.remove()
            this.chatEnded = true
          })
        })
      } else {
        this.chatEnded = true
      }
    },
    runStep4 () {
      this.scrollUp()
      this.step = 4
      this.chatEnded = false

      if (!this.chat4) {
        this.$nextTick(() => {
          this.chat4 = this.runChat('#chat4', [this.$trans('wb_creator_chat_4')], false, (self: any) => {
            self.cursor.remove()
            this.chatEnded = true
          })
        })
      } else {
        this.chatEnded = true
      }
    },
    runStep5 () {
      this.scrollUp()
      this.step = 5
      this.title = null
      this.chatEnded = false

      if (!this.draftName) {
        this.draftName = this.$trans('wb_draft_name_example', { template: this.selectedBusiness?.name })
      }

      if (!this.chat5) {
        this.$nextTick(() => {
          this.chat5 = this.runChat('#chat5', [this.$trans('wb_creator_chat_5')], false, (self: any) => {
            self.cursor.remove()
            this.chatEnded = true
            this.title = this.$trans('wb_select_draft_name')
          })
        })
      } else {
        this.chatEnded = true
        this.title = this.$trans('wb_select_draft_name')
      }
    },
    runStep6 () {
      this.scrollUp()
      this.step = 6
      this.chatEnded = false
      this.createDraft()

      if (!this.chat6) {
        this.$nextTick(() => {
          this.chat6 = this.runChat('#chat6', [this.$trans('wb_creator_chat_6'), this.$trans('wb_creator_chat_6a'), this.$trans('wb_creator_chat_6b'), this.$trans('wb_creator_chat_6c')], true, (self: any) => {
            self.cursor.remove()
            this.chatEnded = true
          })
        })
      } else {
        this.chatEnded = true
      }
    },
    runChat (chatElement: string, strings: Array<string>, loop: boolean, onComplete: any): Typed {
      const options = {
        strings,
        typeSpeed: 30,
        loop,
        onComplete
      }

      return new Typed(chatElement, options)
    },
    handleNextButton () {
      if (this.step === 3) {
        this.runStep4()
      } else if (this.step === 4) {
        this.runStep5()
      } else if (this.step === 5) {
        this.runStep6()
      }
    },
    handlePreviousButton () {
      if (this.step === 3) {
        this.runStep2()
      } else if (this.step === 4) {
        this.runStep3()
      } else if (this.step === 5) {
        this.runStep4()
      }
    },
    handleSelectedBusiness (businessCardModel: BusinessCardModel | null) {
      this.selectedBusiness = businessCardModel

      if (this.selectedBusiness) {
        this.runStep3()
      }
    },
    handleSelectedElements (elements: WebsiteElementModel[]) {
      this.selectedElements = elements
    },
    handleSelectedStyle (websiteStyleModel: WebsiteStyleModel | null) {
      this.selectedStyle = websiteStyleModel

      if (this.selectedStyle) {
        this.runStep5()
      }
    },
    handleSelectedDraftName (draftName: string) {
      this.draftName = draftName
    },
    async createDraft () {
      const draftCreatorController = new DraftCreatorController(
        this.selectedBusiness as BusinessCardModel,
        this.selectedElements,
        this.selectedStyle as WebsiteStyleModel,
        this.draftName as string,
        this.appConfiguration
      )

      const result = await draftCreatorController.createDraft()

      if (result) {
        this.$router.push({ name: 'home' })
      }
    }
  }
})
