import { SectionsSectionType } from '@/calendesk/models/BuilderTypes'
import SectionComponent from '@/calendesk/models/SectionComponent'

const sections: Array<SectionComponent> = [
  {
    componentId: SectionsSectionType.BOOKING_1,
    component: () => import('./booking/booking1/Booking1.vue')
  },
  {
    componentId: SectionsSectionType.BOOKING_2,
    component: () => import('./booking/booking2/Booking2.vue')
  },
  {
    componentId: SectionsSectionType.BOOKING_3,
    component: () => import('./booking/booking3/Booking3.vue')
  },
  {
    componentId: SectionsSectionType.BOOKING_4,
    component: () => import('./booking/booking4/Booking4.vue')
  },
  {
    componentId: SectionsSectionType.BOOKING_5,
    component: () => import('./booking/booking5/Booking5.vue')
  },
  {
    componentId: SectionsSectionType.BOOKING_6,
    component: () => import('./booking/booking6/Booking6.vue')
  },
  {
    componentId: SectionsSectionType.GALLERY_1,
    component: () => import('./gallery/gallery1/Gallery1.vue')
  },
  {
    componentId: SectionsSectionType.GALLERY_2,
    component: () => import('./gallery/gallery2/Gallery2.vue')
  },
  {
    componentId: SectionsSectionType.GALLERY_3,
    component: () => import('./gallery/gallery3/Gallery3.vue')
  },
  {
    componentId: SectionsSectionType.GALLERY_4,
    component: () => import('./gallery/gallery4/Gallery4.vue')
  },
  {
    componentId: SectionsSectionType.GALLERY_5,
    component: () => import('./gallery/gallery5/Gallery5.vue')
  },
  {
    componentId: SectionsSectionType.EMPLOYEES_1,
    component: () => import('./employees/employees1/Employees1.vue')
  },
  {
    componentId: SectionsSectionType.EMPLOYEES_2,
    component: () => import('./employees/employees2/Employees2.vue')
  },
  {
    componentId: SectionsSectionType.EMPLOYEES_3,
    component: () => import('./employees/employees3/Employees3.vue')
  },
  {
    componentId: SectionsSectionType.EMPLOYEES_4,
    component: () => import('./employees/employees4/Employees4.vue')
  },
  {
    componentId: SectionsSectionType.EMPLOYEES_5,
    component: () => import('./employees/employees5/Employees5.vue')
  },
  {
    componentId: SectionsSectionType.EMPLOYEES_6,
    component: () => import('./employees/employees6/Employees6.vue')
  },
  {
    componentId: SectionsSectionType.SERVICES_1,
    component: () => import('./services/services1/Services1.vue')
  },
  {
    componentId: SectionsSectionType.SERVICES_2,
    component: () => import('./services/services2/Services2.vue')
  },
  {
    componentId: SectionsSectionType.SERVICES_3,
    component: () => import('./services/services3/Services3.vue')
  },
  {
    componentId: SectionsSectionType.SERVICES_4,
    component: () => import('./services/services4/Services4.vue')
  },
  {
    componentId: SectionsSectionType.FEATURE_1,
    component: () => import('./features/feature1/Feature1.vue')
  },
  {
    componentId: SectionsSectionType.FEATURE_2,
    component: () => import('./features/feature2/Feature2.vue')
  },
  {
    componentId: SectionsSectionType.FEATURE_3,
    component: () => import('./features/feature3/Feature3.vue')
  },
  {
    componentId: SectionsSectionType.FEATURE_4,
    component: () => import('./features/feature4/Feature4.vue')
  },
  {
    componentId: SectionsSectionType.FEATURE_5,
    component: () => import('./features/feature5/Feature5.vue')
  },
  {
    componentId: SectionsSectionType.FEATURE_6,
    component: () => import('./features/feature6/Feature6.vue')
  },
  {
    componentId: SectionsSectionType.FEATURE_7,
    component: () => import('./features/feature7/Feature7.vue')
  },
  {
    componentId: SectionsSectionType.FEATURE_8,
    component: () => import('./features/feature8/Feature8.vue')
  },
  {
    componentId: SectionsSectionType.FEATURE_9,
    component: () => import('./features/feature9/Feature9.vue')
  },
  {
    componentId: SectionsSectionType.FEATURE_10,
    component: () => import('./features/feature10/Feature10.vue')
  },
  {
    componentId: SectionsSectionType.FEATURE_11,
    component: () => import('./features/feature11/Feature11.vue')
  },
  {
    componentId: SectionsSectionType.FEATURE_12,
    component: () => import('./shared/HtmlText3.vue')
  },
  {
    componentId: SectionsSectionType.FEATURE_13,
    component: () => import('./shared/HtmlText3.vue')
  },
  {
    componentId: SectionsSectionType.REVIEW_1,
    component: () => import('./reviews/reviews1/Reviews1.vue')
  },
  {
    componentId: SectionsSectionType.REVIEW_2,
    component: () => import('./reviews/reviews2/Reviews2.vue')
  },
  {
    componentId: SectionsSectionType.REVIEW_3,
    component: () => import('./reviews/reviews3/Reviews3.vue')
  },
  {
    componentId: SectionsSectionType.REVIEW_4,
    component: () => import('./reviews/reviews4/Reviews4.vue')
  },
  {
    componentId: SectionsSectionType.TEXT_1,
    component: () => import('./text/text1/Text1.vue')
  },
  {
    componentId: SectionsSectionType.TEXT_2,
    component: () => import('./text/text2/Text2.vue')
  },
  {
    componentId: SectionsSectionType.TEXT_3,
    component: () => import('./text/text3/Text3.vue')
  },
  {
    componentId: SectionsSectionType.TEXT_4,
    component: () => import('./text/text4/Text4.vue')
  },
  {
    componentId: SectionsSectionType.TEXT_5,
    component: () => import('./text/text5/Text5.vue')
  },
  {
    componentId: SectionsSectionType.TEXT_6,
    component: () => import('./text/text6/Text6.vue')
  },
  {
    componentId: SectionsSectionType.TEXT_7,
    component: () => import('./text/text7/Text7.vue')
  },
  {
    componentId: SectionsSectionType.TEXT_8,
    component: () => import('./text/text8/Text8.vue')
  },
  {
    componentId: SectionsSectionType.TEXT_9,
    component: () => import('./text/text9/Text9.vue')
  },
  {
    componentId: SectionsSectionType.TEXT_10,
    component: () => import('./text/text10/Text10.vue')
  },
  {
    componentId: SectionsSectionType.TEXT_11,
    component: () => import('./text/text11/Text11.vue')
  },
  {
    componentId: SectionsSectionType.TEXT_12,
    component: () => import('./text/text12/Text12.vue')
  },
  {
    componentId: SectionsSectionType.TEXT_13,
    component: () => import('./shared/HtmlText1.vue')
  },
  {
    componentId: SectionsSectionType.TEXT_14,
    component: () => import('./shared/HtmlText2.vue')
  },
  {
    componentId: SectionsSectionType.STATIC_1,
    component: () => import('./static/regulations/Regulations.vue')
  },
  {
    componentId: SectionsSectionType.STATIC_2,
    component: () => import('./static/privacyPolicy/PrivacyPolicy.vue')
  },
  {
    componentId: SectionsSectionType.STATIC_3,
    component: () => import('./static/login/Login.vue')
  },
  {
    componentId: SectionsSectionType.STATIC_4,
    component: () => import('./static/signup/Signup.vue')
  },
  {
    componentId: SectionsSectionType.HEADER_1,
    component: () => import('./header/header1/Header1.vue')
  },
  {
    componentId: SectionsSectionType.HEADER_2,
    component: () => import('./header/header2/Header2.vue')
  },
  {
    componentId: SectionsSectionType.HEADER_3,
    component: () => import('./header/header3/Header3.vue')
  },
  {
    componentId: SectionsSectionType.HEADER_4,
    component: () => import('./header/header4/Header4.vue')
  },
  {
    componentId: SectionsSectionType.HEADER_5,
    component: () => import('./header/header5/Header5.vue')
  },
  {
    componentId: SectionsSectionType.HEADER_6,
    component: () => import('./header/header6/Header6.vue')
  },
  {
    componentId: SectionsSectionType.HEADER_7,
    component: () => import('./header/header7/Header7.vue')
  },
  {
    componentId: SectionsSectionType.HEADER_8,
    component: () => import('./header/header8/Header8.vue')
  },
  {
    componentId: SectionsSectionType.HEADER_9,
    component: () => import('./shared/HtmlText1.vue')
  },
  {
    componentId: SectionsSectionType.HEADER_10,
    component: () => import('./shared/HtmlText1.vue')
  },
  {
    componentId: SectionsSectionType.HEADER_11,
    component: () => import('./shared/HtmlText1.vue')
  },
  {
    componentId: SectionsSectionType.HEADER_12,
    component: () => import('./shared/HtmlText1.vue')
  },
  {
    componentId: SectionsSectionType.NEWSLETTER_1,
    component: () => import('./newsletter/newsletter1/Newsletter1.vue')
  },
  {
    componentId: SectionsSectionType.NEWSLETTER_2,
    component: () => import('./newsletter/newsletter2/Newsletter2.vue')
  },
  {
    componentId: SectionsSectionType.NEWSLETTER_3,
    component: () => import('./newsletter/newsletter3/Newsletter3.vue')
  },
  {
    componentId: SectionsSectionType.NEWSLETTER_4,
    component: () => import('./newsletter/newsletter4/Newsletter4.vue')
  },
  {
    componentId: SectionsSectionType.NEWSLETTER_5,
    component: () => import('./newsletter/newsletter5/Newsletter5.vue')
  },
  {
    componentId: SectionsSectionType.NEWSLETTER_6,
    component: () => import('./newsletter/newsletter6/Newsletter6.vue')
  },
  {
    componentId: SectionsSectionType.NEWSLETTER_7,
    component: () => import('./newsletter/newsletter7/Newsletter7.vue')
  },
  {
    componentId: SectionsSectionType.NEWSLETTER_8,
    component: () => import('./newsletter/newsletter8/Newsletter8.vue')
  },
  {
    componentId: SectionsSectionType.CTA_1,
    component: () => import('./cta/cta1/Cta1.vue')
  },
  {
    componentId: SectionsSectionType.CTA_2,
    component: () => import('./cta/cta2/Cta2.vue')
  },
  {
    componentId: SectionsSectionType.CTA_3,
    component: () => import('./cta/cta3/Cta3.vue')
  },
  {
    componentId: SectionsSectionType.OTHER_1,
    component: () => import('./other/other1/Other1.vue')
  },
  {
    componentId: SectionsSectionType.OTHER_2,
    component: () => import('./other/other2/Other2.vue')
  },
  {
    componentId: SectionsSectionType.FLEXIBLE_1,
    component: () => import('./shared/Flexible1.vue')
  },
  {
    componentId: SectionsSectionType.BACKGROUND_1,
    component: () => import('./shared/Background1.vue')
  },
  {
    componentId: SectionsSectionType.BACKGROUND_2,
    component: () => import('./shared/Background2.vue')
  },
  {
    componentId: SectionsSectionType.FLEXIBLE_GALLERY_1,
    component: () => import('./shared/FlexibleGallery1.vue')
  },
  {
    componentId: SectionsSectionType.FLEXIBLE_GALLERY_2,
    component: () => import('./shared/FlexibleGallery1.vue')
  },
  {
    componentId: SectionsSectionType.FLEXIBLE_GALLERY_3,
    component: () => import('./shared/FlexibleGallery1.vue')
  },
  {
    componentId: SectionsSectionType.FLEXIBLE_GALLERY_4,
    component: () => import('./shared/FlexibleGallery1.vue')
  },
  {
    componentId: SectionsSectionType.FLEXIBLE_GALLERY_5,
    component: () => import('./shared/FlexibleGallery1.vue')
  },
  {
    componentId: SectionsSectionType.FLEXIBLE_GALLERY_6,
    component: () => import('./shared/FlexibleGallery1.vue')
  },
  {
    componentId: SectionsSectionType.FLEXIBLE_GALLERY_7,
    component: () => import('./shared/FlexibleGallery2.vue')
  },
  {
    componentId: SectionsSectionType.FLEXIBLE_BOOKING_1,
    component: () => import('./shared/FlexibleBooking1.vue')
  },
  {
    componentId: SectionsSectionType.FLEXIBLE_BOOKING_2,
    component: () => import('./shared/FlexibleBooking2.vue')
  },
  {
    componentId: SectionsSectionType.FLEXIBLE_BOOKING_3,
    component: () => import('./shared/FlexibleBooking3.vue')
  },
  {
    componentId: SectionsSectionType.FLEXIBLE_BOOKING_4,
    component: () => import('./shared/FlexibleBooking4.vue')
  },
  {
    componentId: SectionsSectionType.PLAIN_HTML,
    component: () => import('./shared/PlainHtml1.vue')
  },
  {
    componentId: SectionsSectionType.FLEXIBLE_MAP_1,
    component: () => import('./shared/FlexibleMap1.vue')
  },
  {
    componentId: SectionsSectionType.FLEXIBLE_REVIEW_1,
    component: () => import('./shared/FlexibleReviews1.vue')
  },
  {
    componentId: SectionsSectionType.FLEXIBLE_REVIEW_2,
    component: () => import('./shared/FlexibleReviews2.vue')
  },
  {
    componentId: SectionsSectionType.FLEXIBLE_FORM_1,
    component: () => import('./shared/FlexibleForm1.vue')
  },
  {
    componentId: SectionsSectionType.CALENDAR_V2,
    component: () => import('./calendars/CalendarV2.vue')
  }
]

export default sections
