var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"app":"","color":"white","height":"70"}},[_c('v-img',{staticClass:"shrink mr-2 pointer",attrs:{"alt":"Website Builder","contain":"","src":require('@/lib/calendesk-js-library/assets/calendesk-logo-square.jpg'),"width":"40"},on:{"click":function($event){return _vm.closeApp()}}}),(_vm.$vuetify.breakpoint.lgAndUp)?_vm._l((_vm.menu),function(button,index){return _c('v-btn',{key:("menu_button_" + index),staticClass:"hidden-sm-and-down",attrs:{"text":""},on:{"click":function($event){return _vm.openMenu(button)}}},[_vm._v(" "+_vm._s(button.title)+" ")])}):_vm._l((_vm.menu),function(button,index){return _c('v-tooltip',{key:("menu_button_" + index),staticClass:"hidden-sm-and-down",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"hidden-sm-and-down",attrs:{"icon":""},on:{"click":function($event){return _vm.openMenu(button)}}},on),[_c('v-icon',[_vm._v(_vm._s(button.icon))])],1)]}}],null,true)},[_vm._v(" "+_vm._s(button.title)+" ")])}),(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-btn',{staticClass:"hidden-sm-and-down mr-2",attrs:{"text":""},on:{"click":_vm.addNewPageClicked}},[_vm._v(" "+_vm._s(_vm.$trans('wb_add_new_page'))+" ")]):_c('v-tooltip',{staticClass:"hidden-sm-and-down",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"hidden-sm-and-down",attrs:{"icon":""},on:{"click":_vm.addNewPageClicked}},on),[_c('v-icon',[_vm._v("$plus")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$trans('wb_add_new_page'))+" ")]),_c('v-spacer',{staticClass:"hidden-md-and-up"}),_c('v-select',{staticClass:"page-select",class:{ 'mr-4': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"label":_vm.$trans('wb_pages'),"items":_vm.filteredPages,"item-text":"name","item-value":"id","return-object":"","hide-details":"","outlined":""},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('ChangePreviewSize',{staticClass:"hidden-sm-and-down"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-btn',_vm._g(_vm._b({staticClass:"hidden-sm-and-down mx-2",attrs:{"small":"","color":"primary"},on:{"click":_vm.showIntegrationsClicked}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("$xml")]),_vm._v(" "+_vm._s(_vm.$trans('add_to_your_website'))+" ")],1):_c('v-btn',_vm._g(_vm._b({staticClass:"hidden-sm-and-down ml-2",attrs:{"icon":""},on:{"click":_vm.showIntegrationsClicked}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$xml")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$trans('add_to_your_website'))+" ")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"hidden-md-and-up",attrs:{"depressed":"","icon":"","color":"black"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$menu")])],1)]}}])},[_c('v-list',[_vm._l((_vm.menu),function(button,index){return _c('v-list-item',{key:("button_" + index),attrs:{"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(button.icon))])],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(button.title)},on:{"click":function($event){return _vm.openMenu(button)}}})],1)}),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("$plus")])],1),_c('v-list-item-title',{on:{"click":_vm.addNewPageClicked}},[_vm._v(_vm._s(_vm.$trans('wb_add_new_page')))])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("$xml")])],1),_c('v-list-item-title',{on:{"click":_vm.showIntegrationsClicked}},[_vm._v(_vm._s(_vm.$trans('add_to_your_website')))])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":_vm.publishDraftAction}},[_vm._v(_vm._s(_vm.$trans('publish')))])],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":_vm.logout}},[_vm._v(_vm._s(_vm.$trans('close')))])],1)],2)],1),_c('v-btn',{staticClass:"hidden-sm-and-down",attrs:{"color":"blue","dark":"","depressed":""},on:{"click":_vm.publishDraftAction}},[_vm._v(" "+_vm._s(_vm.$trans('publish'))+" ")]),_c('v-btn',{staticClass:"hidden-sm-and-down ml-2",attrs:{"text":"","outlined":"","loading":_vm.isLoggingOut},on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.$trans('close'))+" ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }