import Draft from '@/calendesk/models/DTO/Response/Draft'
import Page from '@/calendesk/models/DTO/Response/Page'
import Section from '@/calendesk/models/DTO/Response/Section'

export enum ChangeStepType {
  UPDATE_DRAFT,
  UPDATE_SECTION,
  ADD_SECTION,
  ADD_PAGE,
  UPDATE_PAGE
}

export class ChangeStep {
  public newDraft: Draft
  public newObject: Page | Section | null;
  public oldObject: Page | Section | null;
  public oldDraft: Draft;
  public type: ChangeStepType

  public constructor (newDraft: Draft, oldDraft: Draft, type: ChangeStepType, newObject: Page | Section | null, oldObject: Page | Section | null) {
    this.newDraft = newDraft
    this.oldDraft = oldDraft
    this.type = type
    this.newObject = newObject
    this.oldObject = oldObject
  }
}
