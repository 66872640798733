export default class CreateDraftRequestData {
  public template_id: number
  public description: string
  public template_data: Record<string, any>

  public constructor (templateId: number, description: string, templateData: Record<string, any>) {
    this.template_id = templateId
    this.description = description
    this.template_data = templateData
  }
}
