import { UserStatus } from '@/calendesk/models/DTO/Response/UserStatus'
import DefaultImage from '@/calendesk/models/DTO/Response/DefaultImage'
import { Expose, Type } from 'class-transformer'
import Address from '@/calendesk/models/DTO/Response/Address'
import Phone from '@/calendesk/models/DTO/Response/Phone'
import Permission from '@/calendesk/models/DTO/Response/Permission'
import Role from '@/calendesk/models/DTO/Response/Role'
import store from '@/store'
import Employee from '@/calendesk/models/DTO/Response/Employee'

export default class User {
  public id!: number
  public name!: string | null
  public surname!: string | null
  public status!: UserStatus

  @Type(() => Permission)
  public permissions!: Permission[]

  @Type(() => Role)
  public roles!: Role[]

  @Expose({ name: 'default_image' })
  @Type(() => DefaultImage)
  public defaultImage!: DefaultImage

  @Expose({ name: 'default_address' })
  @Type(() => Address)
  public defaultAddress!: Address | null

  @Expose({ name: 'default_phone' })
  @Type(() => Phone)
  public defaultPhone!: Phone | null

  @Type(() => Employee)
  public employee?: Employee

  public getFullName (): string {
    return this.name + ' ' + this.surname
  }

  public getDefaultImageUrl (): string {
    if (this.defaultImage && store.getters['setup/getAppConfiguration']) {
      return store.getters['setup/getAppConfiguration'].imageBaseUrl + this.defaultImage.name
    }

    return require('@/lib/calendesk-js-library/assets/placeholder.jpg')
  }
}
