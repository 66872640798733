import { GetterTree } from 'vuex'
import { AuthState } from './types'
import { RootState } from '../../types'
import User from '@/calendesk/models/DTO/Response/User'
import { getItem } from '@/lib/calendesk-js-library/tools/localStorageManager'

export const getters: GetterTree<AuthState, RootState> = {
  getUser (state): User | null {
    return state.user
  },
  isUserLogged (state): boolean {
    return !!state.user
  },
  isAdmin (state): boolean {
    if (state.user) {
      const filteredRoles = state.user.roles.map((role) => role.name)

      return filteredRoles.includes('root') || filteredRoles.includes('admin')
    }

    return false
  },
  isRoot (state): boolean {
    if (state.user) {
      const filteredRoles = state.user.roles.map((role) => role.name)

      return filteredRoles.includes('root')
    }

    return false
  },
  isEmployee (state): boolean {
    if (state.user) {
      return !!state.user.employee
    }

    return false
  },
  getCdAdminSessionIssuer (): string | null | undefined {
    return (
      getItem('cd_admin_session_issuer', false, false)
    )
  }
}
