














import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { PreviewSizeType } from '@/calendesk/models/BuilderTypes'

export default Vue.extend({
  name: 'ChangePreviewSize',
  data () {
    return {
      availableSizes: [
        {
          type: PreviewSizeType.DESKTOP,
          icon: '$desktop'
        },
        {
          type: PreviewSizeType.MOBILE,
          icon: '$mobile'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      previewType: 'builder/getPreviewType'
    })
  },
  methods: {
    ...mapActions({
      setPreviewType: 'builder/setPreviewType'
    })
  }
})
