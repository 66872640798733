import { WebsiteLanguageType } from '@/calendesk/models/WebsiteLanguageType'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'

export default (type: WebsiteLanguageType): string => {
  switch (type) {
    case WebsiteLanguageType.CS:
      return trans('c_language_select_czech')
    case WebsiteLanguageType.DE:
      return trans('c_language_select_german')
    case WebsiteLanguageType.EN:
      return trans('c_language_select_english')
    case WebsiteLanguageType.ES:
      return trans('c_language_select_spanish')
    case WebsiteLanguageType.FR:
      return trans('c_language_select_french')
    case WebsiteLanguageType.HI:
      return trans('c_language_select_hindi')
    case WebsiteLanguageType.NL:
      return trans('c_language_select_dutch')
    case WebsiteLanguageType.PL:
      return trans('c_language_select_polish')
    case WebsiteLanguageType.PT:
      return trans('c_language_select_portuguese')
    case WebsiteLanguageType.RU:
      return trans('c_language_select_russian')
    case WebsiteLanguageType.SK:
      return trans('c_language_select_slovak')
    case WebsiteLanguageType.UK:
      return trans('c_language_select_ukrainian')
    case WebsiteLanguageType.BG:
      return trans('c_language_select_bulgarian')
    case WebsiteLanguageType.EL:
      return trans('c_language_select_greek')
    case WebsiteLanguageType.ET:
      return trans('c_language_select_estonian')
    case WebsiteLanguageType.FI:
      return trans('c_language_select_finnish')
    case WebsiteLanguageType.GA:
      return trans('c_language_select_irish')
    case WebsiteLanguageType.HR:
      return trans('c_language_select_croatian')
    case WebsiteLanguageType.HU:
      return trans('c_language_select_hungarian')
    case WebsiteLanguageType.LT:
      return trans('c_language_select_lithuanian')
    case WebsiteLanguageType.LV:
      return trans('c_language_select_latvian')
    case WebsiteLanguageType.MT:
      return trans('c_language_select_maltese')
    case WebsiteLanguageType.SL:
      return trans('c_language_select_slovenian')
    case WebsiteLanguageType.SQ:
      return trans('c_language_select_albanian')
    case WebsiteLanguageType.SV:
      return trans('c_language_select_swedish')
  }
}
