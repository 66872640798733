import { SidebarType } from '@/calendesk/models/BuilderTypes'
import SidebarTab from '@/calendesk/models/SidebarTab'

const sidebarTabs: Array<SidebarTab> = [
  {
    type: SidebarType.GENERAL,
    component: () => import('./General.vue')
  },
  {
    type: SidebarType.STYLE,
    component: () => import('./Style.vue')
  },
  {
    type: SidebarType.TOOLS,
    component: () => import('./Tools.vue')
  },
  {
    type: SidebarType.PAGES,
    component: () => import('./Pages.vue')
  },
  {
    type: SidebarType.ADD_SECTION,
    component: () => import('./SectionCategories.vue')
  },
  {
    type: SidebarType.REPLACE_NAVBAR,
    component: () => import('./SectionCategories.vue')
  },
  {
    type: SidebarType.REPLACE_FOOTER,
    component: () => import('./SectionCategories.vue')
  }
]

export default sidebarTabs
