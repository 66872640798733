














































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import sidebarDetailsList from './sidebarDetailsList'
import Style from '@/builder/sidebar/sidebarTabs/Style.vue'
export default Vue.extend({
  name: 'SectionDetails',
  components: { Style },
  data () {
    return {
      drawer: {
        open: false
      }
    }
  },
  computed: {
    ...mapGetters({
      sidebarDetails: 'sidebar/getSidebarDetails'
    }),
    sectionDetailsContent (): Record<string, any> {
      const content: Record<string, any> | undefined = sidebarDetailsList.find((_: Record<string, any>) => _.type === this.sidebarDetails.type)
      return content || {}
    }
  },
  watch: {
    'sidebarDetails.open' () {
      this.drawer = this.sidebarDetails
    },
    'sidebarDetails.data' () {
      this.drawer = this.sidebarDetails
    }
  },
  methods: {
    ...mapActions({
      closeSidebarDetails: 'sidebar/closeSidebarDetails'
    })
  }
})
